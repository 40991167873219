import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 6",
    id: "WB5-V2-U6-P44-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page44/E1/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [{ color: 'black', num: '1', title: 'Read the text in the Student Book. Write.' }],
    titleImage: "",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
            <img src="img/FriendsPlus/Page44/E1/1.jpg">
            <div style='flex: 2;'>
              <div>1&ensp;Thi gets up early <u style='color: gray;'>&ensp;every day&ensp;</u>.</div>
              <div>2&ensp;#, she walks down the hills to school.</div>
              <div>3&ensp;#, she has her lessons.</div>
              <div>4&ensp;#, she walks home and helps her parents on the farm.</div>
              <div>5&ensp;#, she does her homework.</div>
            </div>
          `,
        answer: ["First", "Then", "Next", 'Finally'],
      },
    ],
  },
  2: { // Exercise num
    unit: "Unit 6",
    id: "WB5-V2-U6-P44-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page44/E2/Key/answerKey.png",
    inputSize: 170,
    titleQuestion: [{ color: 'black', num: '2', title: 'Read the story. Choose a word from the box and write.' }],
    titleImage: "",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
            <div>
              <div>My name’s Vinh. Today is my first day in a new school. I get up early. I don’t want to be</div>
              <div>late! I pick up my <u style='color: gray;'>&ensp;books&ensp;</u> and go to the kitchen to have my #. I leave</div>
              <div>home with my dad. He catches the #, and I walk to school. I feel very</div>
              <div>#! When I get to school everyone is friendly. My new # is</div>
              <div>called Miss Thi and she’s very nice. I like my new school already!</div>
            </div>
            <img src="img/FriendsPlus/Page44/E2/1.jpg">
          `,
        answer: ["breakfast", "bus", "excited", 'teacher'],
      },
    ],
  },
}

export default json;