import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P81-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page81/E1/Key/answerKey.png",
    inputSize: 120,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Write <i>There was</i> or <i>There were</i>.' }],
    // titleImage: "img/FriendsPlus/Page81/E2/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page81/E1/1.jpg">
          <div style='margin-left:50px;'>
            <div>My name is Nga. Last Saturday my family went to the park. It was windy and hot. My</div>
            <div>sister played in the fountain! She made my dad wet. He wasn’t happy. Mom was hungry</div>
            <div>so she had a sandwich. My brother and I played on the swings. I had a really good time at</div>
            <div>the park!</div>
            <div><b>1</b> Nga and her family <u style='color:gray;'>&ensp;weren’t&ensp;</u> at the beach.</div>
            <div><b>2</b> The weather # hot.</div>
            <div><b>3</b> Mom # hungry.</div>
            <div><b>4</b> The children # sad.</div>
            <div><b>5</b> It # rainy.</div>
            <div><b>6</b> Dad # happy. He # wet!</div>
          </div>
        `,
        answer: ['was', 'was', "weren't", "wasn't", "wasn't", 'was'],
      },
    ]
  },
  2: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P81-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page81/E2/Key/answerKey.png",
    inputSize: 200,
    titleQuestion: [{ color: 'black', num: '2', title: 'Complete the sentence with <i>He had</i> or <i>He didn’t have.</i>' }],
    // titleImage: "img/FriendsPlus/Page81/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page81/E2/1.jpg">
          <div>
            <div><b>1</b><u style='color: gray;'>&ensp;He didn’t have&ensp;</u> breakfast in the living room.</div>
            <div><b>2</b># cereal for breakfast.</div>
            <div><b>3</b># a music lesson.</div>
            <div><b>4</b># sandwiches and an apple for lunch.</div>
            <div><b>5</b># dinner with his family.</div>
          </div>
        `,
        answer: ["He had", "He didn't have", "He had", "He had"],
      },
    ]
  },
}
export default json;