import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P80-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page80/E1/Key/answerKey.png",
    inputSize: 150,
    titleQuestion: [{ color: 'black', num: '1', title: 'Order the letters and write the word.' }],
    // titleImage: "img/FriendsPlus/Page80/E2/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;flex-direction: column;align-items: center;'>
            <div style='display: flex;margin-bottom: 10px;'>
              <div style='text-align: center;'>
                <img src="img/FriendsPlus/Page80/E1/1.jpg">
                <div><u style='color: gray;'>&ensp;tall&ensp;</u></div>
              </div>
              <div style='text-align: center;margin: 0 10px 0 20px;'>
                <img src="img/FriendsPlus/Page80/E1/2.jpg">
                <div>#</div>
              </div>
              <div style='text-align: center;margin: 0 20px 0 10px;'>
                <img src="img/FriendsPlus/Page80/E1/3.jpg">
                <div>#</div>
              </div>
              <div style='text-align: center;'>
                <img src="img/FriendsPlus/Page80/E1/4.jpg">
                <div>#</div>
              </div>
            </div>
            <div style='display: flex;margin-top: 10px;'>
              <div style='text-align: center;'>
                <img src="img/FriendsPlus/Page80/E1/5.jpg">
                <div>#</div>
              </div>
              <div style='text-align: center;margin: 0 20px;'>
                <img src="img/FriendsPlus/Page80/E1/6.jpg">
                <div>#</div>
              </div>
              <div style='text-align: center;'>
                <img src="img/FriendsPlus/Page80/E1/7.jpg">
                <div>#</div>
              </div>
            </div>
          </div>
        `,
        answer: ['shy', 'pretty', 'young', 'short', 'handsome', 'friendly'],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'WB4-U12-P80-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page80/E2/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page80/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page80/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page80/E2/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page80/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page80/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page80/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page80/E2/7.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page80/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page80/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page80/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page80/E2/11.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page80/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page80/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page80/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page80/E2/15.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page80/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page80/E2/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page80/E2/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page80/E2/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page80/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page80/E2/21.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page80/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page80/E2/23.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page80/E2/24.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page80/E2/25.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page80/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page80/E2/27.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page80/E2/28.jpg' },
        { url: 'img/FriendsPlus/Page80/E2/29.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page80/E2/30.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page80/E2/31.jpg' },
      ],
    ]
  },
}
export default json;