import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'WB4-U6-P40-E1',
    audio: 'img/FriendsPlus/Page40/Audio/audio-e1-p40.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page40/E1/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tieude-e1-p40.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page40/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/8.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/10.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page40/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page40/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page40/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/14.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page40/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page40/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page40/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/22.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page40/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/24.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E1/25.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: "Unit 6",
    id: "WB5-V2-U6-40-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page40/E2/Key/answerKey.png",
    inputSize: 420,
    titleQuestion: [{ color: 'black', num: '2', title: 'Write.'}],
    titleImage: "",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row;margin-bottom: 10px;'>
            <div style='flex: 1;margin-right: 10px;'>
              <img src="img/FriendsPlus/Page40/E2/1.jpg">
              <div><u style='color: gray;'>&ensp;Every morning I get up.&ensp;</u></div>
            </div>
            <div style='flex: 1;margin-left: 10px;'>
              <img src="img/FriendsPlus/Page40/E2/2.jpg">
              <div>#</div>
            </div>
          </div>
          <div style='display: flex; flex-direction: row;margin-top: 10px;'>
            <div style='flex: 1;margin-right: 10px;'>
              <img src="img/FriendsPlus/Page40/E2/3.jpg">
              <div>#</div>
            </div>
            <div style='flex: 1;margin-left: 10px;'>
              <img src="img/FriendsPlus/Page40/E2/4.jpg">
              <div>#</div>
            </div>
          </div>
        `,
        answer: ["Every morning I brush my teeth.", "Every morning I have breakfast.", "Every morning I catch the bus."],
      },
    ],
  },
}

export default json;