import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'WB4-U11-P76-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page76/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Circle the correct words.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white', margin: 11, fontWeight: 'bold' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          'twenty_years_ago / one_hundred_years_ago.', //0
          'twenty_years_ago / one_hundred_years_ago.', //1
          'twenty_years_ago / one_hundred_years_ago.', //2
          'twenty_years_ago / one_hundred_years_ago.', //3
        ],
        answers: ['0-0', '1-0', '2-4', '3-0'],
        initialValue: [],
      },
      Layout: `
      
        <img src="img/FriendsPlus/Page76/E1/1.jpg" />
        <div>
          <div><b>1</b> There weren’t any planes <b>twenty years ago / <span style='border: 2px solid gray; border-radius: 50%; margin: 11px;'> one hundred years ago.</span></b></div>
          <div><b>2</b> There were motorcycles <input id='0' type='Circle' /></div>
          <div><b>3</b> There were cars <input id='1' type='Circle' /></div>
          <div><b>4</b> There weren’t any buses <input id='2' type='Circle' /></div>
          <div><b>5</b> There were bicycles <input id='3' type='Circle' /></div>
        </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'WB4-U11-P76-E2',
    audio: 'img/FriendsPlus/Page76/Audio/audio-e2-p76.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page76/E2/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page76/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page76/Audio/tieude-e2-p76.mp3' },
        { url: 'img/FriendsPlus/Page76/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page76/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page76/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page76/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page76/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page76/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/10.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page76/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page76/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page76/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/14.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E2/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page76/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page76/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/18.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page76/E2/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page76/E2/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page76/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/24.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page76/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/26.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page76/E2/27.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page76/E2/28.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page76/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/30.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page76/E2/31.jpg' },
        { url: 'img/FriendsPlus/Page76/E2/32.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page76/E2/33.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page76/E2/34.jpg' },
      ],
    ]
  },
}
export default json;