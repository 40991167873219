import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: "Unit 10",
    id: "WB5-V2-U10-P69-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page69/E1/Key/answerKey.png",
    inputSize: 130,
    titleQuestion: [{ color: 'black', num: '1', title: 'Look at the signs. Write <i>must</i> or <i>mustn’t</i>.' }],
    // titleImage: "img/FriendsPlus/Page69/E1/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page69/E1/1.jpg" />
            </div>
            <div style='margin-left: 20px; line-height: 58px;'>
              <div><b>1</b> You <u style='color: gray;'>&ensp;mustn’t&ensp;</u> play in the fountain.</div>
              <div><b>2</b> You # walk your dog here.</div>
              <div><b>3</b> You # put litter in the garbage can.</div>
              <div><b>4</b> You # walk on the grass.</div>
              <div><b>5</b> You # pick the flowers.</div
            </div>
          </div>
        `,
        answer: ["mustn't", "must", "mustn't", "mustn't"],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'WB4-U10-P69-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page69/E2/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page69/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page69/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page69/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page69/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page69/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page69/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page69/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page69/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/11.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page69/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page69/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page69/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/15.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page69/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page69/E2/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page69/E2/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page69/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/21.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page69/E2/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page69/E2/23.jpg' },
      ],
    ]
  },
}
export default json;