import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P94-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page94/E1/Key/answerKey.png",
    inputSize: 100,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page94/E1/1.jpg">
          <div style='display: flex;margin-left:50px;'>
            <div>
              <div><b>1</b> <u style='color:gray;'>&ensp;some&ensp;</u> rice</div>
              <div><b>4</b> # water</div>
              <div><b>7</b> # cookie</div>
              <div><b>10</b> # meat</div>
            </div>
            <div style='margin:0 20px;'>
              <div><b>2</b> # apple</div>
              <div><b>5</b> # lemon</div>
              <div><b>8</b> # oranges</div>
              <div><b>11</b> # melon</div>
            </div>
            <div>
              <div><b>3</b> # bananas</div>
              <div><b>6</b> # bread</div>
              <div><b>9</b> # cereal</div>
              <div><b>12</b> # egg</div>
            </div>
          </div>
        `,
        answer: ['some', 'a', 'some', 'an', 'a', 'some', 'a', 'some', 'some', 'some', 'an'],
      },
    ]
  },
  2: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P94-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page94/E2/Key/answerKey.png",
    inputSize: 110,
    // titleQuestion: [{ color: 'black', num: '2', title: 'Write a sentence.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page94/E2/1.jpg">
          <div style='margin-left:50px;'>
            <div><b>1</b> Carl is <u style='color: gray;'>&ensp;shorter&ensp;</u> (short) than Fred.</div>
            <div><b>2</b> Adam is # (tall) than Carl.</div>
            <div><b>3</b> Fred is the # (tall).</div>
            <div><b>4</b> Mice are # (small) than elephants.</div>
            <div><b>5</b> Cheetahs are # (big) than mice.</div>
            <div><b>6</b> Elephants are the # (big).</div>
            <div><b>7</b> A car is # (slow) than a plane</div>
            <div><b>8</b> A plane is the # (fast).</div>
          </div>
        `,
        answer: ['taller', 'tallest', 'smaller', 'bigger', 'biggest', 'slower', 'fastest'],
      },
    ]
  },
}
export default json;