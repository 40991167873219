import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  4: { // Exercise num
    unit: 'Review 1',
    id: 'WB4-PV-P27-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page27/E1/Key/answerKey.png',
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page27/E1/1.jpg'},
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page27/E1/2.jpg'},
        { url: 'img/FriendsPlus/Page27/E1/3.jpg', input: true, answer: "Can I"},
        { url: 'img/FriendsPlus/Page27/E1/4.jpg'},
        { url: 'img/FriendsPlus/Page27/E1/5.jpg', input: true, answer: "Can you"},
        { url: 'img/FriendsPlus/Page27/E1/6.jpg'},
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page27/E1/7.jpg'},
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page27/E1/8.jpg'},
        { url: 'img/FriendsPlus/Page27/E1/9.jpg', input: true, answer: "Yes, you can."},
        { url: 'img/FriendsPlus/Page27/E1/10.jpg'},
        { url: 'img/FriendsPlus/Page27/E1/11.jpg', input: true, answer: "No, I can't."},
        { url: 'img/FriendsPlus/Page27/E1/12.jpg'},
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page27/E1/13.jpg'},
      ],      
    ]
  },
  5: { // Exercise num
    unit: 'Review 1',
    id: 'WB4-PV-P27-E5',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page27/E2/Key/answerKey.png',
    isAllowSubmit: false,
    // checkUppercase: true,
    padding: 0,
    textAlign: 'center',
    maxLength: 2,
    question: [],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page27/E2/1.jpg'},
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page27/E2/2.jpg'},
        { url: 'img/FriendsPlus/Page27/E2/3.jpg', input: true, answer: "pl"},
        { url: 'img/FriendsPlus/Page27/E2/4.jpg'},
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page27/E2/5.jpg'},
        { url: 'img/FriendsPlus/Page27/E2/6.jpg', input: true, answer: "sp"},
        { url: 'img/FriendsPlus/Page27/E2/7.jpg'},
        { url: 'img/FriendsPlus/Page27/E2/8.jpg', input: true, answer: "cr"},
        { url: 'img/FriendsPlus/Page27/E2/9.jpg'},
        { url: 'img/FriendsPlus/Page27/E2/10.jpg', input: true, answer: "sn"},
        { url: 'img/FriendsPlus/Page27/E2/11.jpg'},
      ],      
    ],
  },
  6: { // Exercise num
    unit: 'Review 1',
    id: 'WB4-PV-P27-E6',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: '',
    isAllowSubmit: false,
    // checkUppercase: true,
    hideBtnFooter: true,
    question: [],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page27/E3/1.jpg'},
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page27/E3/2.jpg'},
        { url: 'img/FriendsPlus/Page27/E3/3.jpg', input: true, answer: ""},
        { url: 'img/FriendsPlus/Page27/E3/4.jpg'},
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page27/E3/5.jpg'},
        { url: 'img/FriendsPlus/Page27/E3/6.jpg', input: true, answer: ""},
        { url: 'img/FriendsPlus/Page27/E3/7.jpg'},
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page27/E3/8.jpg'},
        { url: 'img/FriendsPlus/Page27/E3/9.jpg', input: true, answer: ""},
        { url: 'img/FriendsPlus/Page27/E3/10.jpg'},
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page27/E3/11.jpg'},
        { url: 'img/FriendsPlus/Page27/E3/12.jpg', input: true, answer: ""},
        { url: 'img/FriendsPlus/Page27/E3/13.jpg'},
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page27/E3/14.jpg'},
      ],      
    ],
  },
}

export default json;