import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'WB4-U7-P52-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: "img/FriendsPlus/Page52/E1/Key/answerKey.png",
    isAllowSubmit: false,
    textAlign: 'center',
    maxLength: 1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page52/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page52/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/3.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page52/E1/4.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page52/E1/5.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page52/E1/6.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page52/E1/7.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page52/E1/8.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page52/E1/9.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page52/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/11.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page52/E1/12.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page52/E1/13.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page52/E1/14.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page52/E1/15.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page52/E1/16.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page52/E1/17.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page52/E1/18.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page52/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/20.jpg', input: true, answer: "p" },
        { url: 'img/FriendsPlus/Page52/E1/21.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page52/E1/22.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page52/E1/23.jpg', input: true, answer: "y" },
        { url: 'img/FriendsPlus/Page52/E1/24.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page52/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/26.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page52/E1/27.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page52/E1/28.jpg', input: true, answer: "v" },
        { url: 'img/FriendsPlus/Page52/E1/29.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page52/E1/30.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page52/E1/31.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page52/E1/32.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'WB4-U7-P52-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page52/E2/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page52/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page52/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page52/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page52/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page52/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page52/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page52/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page52/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/11.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page52/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page52/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page52/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page52/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page52/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page52/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/19.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page52/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page52/E2/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page52/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/23.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page52/E2/24.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/25.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page52/E2/26.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page52/E2/27.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page52/E2/28.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/29.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page52/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page52/E2/31.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page52/E2/32.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page52/E2/33.jpg' },
      ],
    ]
  }
}

export default json;