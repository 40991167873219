import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "WB5-V2-U5-P37-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page37/E1/Key/answerKey.png",
    maxLength: 2,
    isAllowSubmit: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/3.jpg', input: true, answer: "oy" },
        { url: 'img/FriendsPlus/Page37/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/5.jpg', input: true, answer: "oi" },
        { url: 'img/FriendsPlus/Page37/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/7.jpg', input: true, answer: "oy" },
        { url: 'img/FriendsPlus/Page37/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/9.jpg', input: true, answer: "oy" },
        { url: 'img/FriendsPlus/Page37/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page37/E1/11.jpg', input: true, answer: "oi" },
        { url: 'img/FriendsPlus/Page37/E1/12.jpg' },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "WB5-V2-U5-P37-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page37/E2/Key/answerKey.png",
    // maxLength: 2,
    isAllowSubmit: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/3.jpg', input: true, answer: "oyster" },
        { url: 'img/FriendsPlus/Page37/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page37/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/6.jpg', input: true, answer: "toy" },
        { url: 'img/FriendsPlus/Page37/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page37/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page37/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/10.jpg', input: true, answer: "coin" },
        { url: 'img/FriendsPlus/Page37/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page37/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/13.jpg', input: true, answer: "soil" },
        { url: 'img/FriendsPlus/Page37/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page37/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/16.jpg', input: true, answer: "oil" },
        { url: 'img/FriendsPlus/Page37/E2/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page37/E2/18.jpg' },
      ],
    ],
  },
  3: {
    unit: 'Unit 5',
    id: 'WB4-U5-P37-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page37/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Say the words. Circle the word that doesn’t have oy or oi.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          "oil coin coat soil", //0
          "gate boy oyster oil", //1
          "boy blue soil toy", //2
        ],
        answers: ['0-4', '1-0', '2-2'],
        initialValue: [],
      },
      Layout: `
        <div style="display: flex;flex-direction: row;line-height: 48px;justify-content: space-between;width: 850px; word-spacing: 10px;">
          <div>
            <div><b>1</b> boy toy coin <span style=" border: solid 2px gray; border-radius: 50%; padding: 5px; ">stone</span></div>
            <div><b>3</b> <input id='1' type='Circle' /> </div>
          </div>
          <div>
            <div><b>2</b> <input id='0' type='Circle' /> </div>
            <div><b>4</b> <input id='2' type='Circle' /> </div>
          </div>
        </div>
      `,
    },
  },
  4: {
    // Exercise num
    unit: "Unit 5",
    id: "WB5-V2-U5-P37-E4",
    audio: "img/FriendsPlus/Page37/Audio/audio-e4-p37.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page37/E4/Key/answerKey.png",
    inputSize: 150,
    titleQuestion: [{ color: 'black', num: '4', title: 'Listen and write. <headphone name="11" src="img/FriendsPlus/Page37/Audio/tieude-e4-p37.mp3"></headphone>'}],
    titleImage: "",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row;'>
            <div style='width: 50%;'>
              <img src="img/FriendsPlus/Page37/E4/1.jpg" style="width: 98%">
            </div>
            <div style='width: 50%;'>
                <div>Roy is a <sup>1</sup> #,</div>
                <div>Playing with a <sup>2</sup> #.</div>
                <div>He can see a <sup>3</sup> #.</div>
            </div>
          </div>
        `,
        answer: ["boy", "toy", "coin"],
      },
    ],
  },
};

export default json;
