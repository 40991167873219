import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 11",
    id: "WB5-V2-U11-P74-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page74/E1/Key/answerKey.png",
    inputSize: 100,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Write <i>and</i> or <i>or</i>.' }],
    // titleImage: "img/FriendsPlus/Page74/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page74/E1/1.jpg">
            </div>
            <div style='margin-left: 20px; line-height: 45px;'>
              <div><b>1</b>&ensp;<u style='color: gray;'>&ensp;five&ensp;</u> taxis</div>
              <div><b>2</b>&ensp;# motorcycles</div>
              <div><b>3</b>&ensp;# trains</div>
              <div><b>4</b>&ensp;# helicopters</div>
              <div><b>5</b>&ensp;# trolleys</div>
              <div><b>6</b>&ensp;# planes</div>
              <div><b>7</b>&ensp;# buses</div>
            </div>
          </div>
          <div>
            <img src="img/FriendsPlus/Page74/E1/2.jpg">
          </div>
        `,
        answer: ['seven', 'two', 'four', 'four', 'two', 'six'],
      },
    ]
  },
  2: { // Exercise num
    unit: "Unit 11",
    id: "WB5-V2-U11-P74-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page74/E2/Key/answerKey.png",
    inputSize: 70,
    // titleQuestion: [{ color: 'black', num: '3', title: 'Read and complete with <i>ai</i> or <i>ay</i>.' }],
    titleImage: "img/FriendsPlus/Page74/E2/1.jpg",
    questionImage: [],
    textAlign: 'center',
    maxLength: 3,
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page74/E2/2.jpg">
        <div style='display: flex;'>
          <div>
            <div><b>1</b> There’s a trolley near the airport.</div>
            <div><b>2</b> There’s a train at the station.</div>
            <div><b>3</b> There’s a bus in front of the supermarket.</div>
            <div><b>4</b> There’s a helicopter near the station.</div>
            <div><b>5</b> There’s a bus next to the school.</div>
            <div><b>6</b> There’s a taxi in front of the supermarket.</div>
            <div><b>7</b> There’s a plane at the airport.</div>
            <div><b>8</b> There’s a motorcycle in front of the school.</div>
          </div>
          <div>
            <div><u style='color: gray;'>&ensp;no&ensp;</u></div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
        </div>
        `,
        answer: ['yes', 'yes', 'yes', 'no', 'no', 'yes', 'yes'],
      },
    ]
  },
}
export default json;