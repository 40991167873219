import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB4-U5-P36-E1',
    audio: 'img/FriendsPlus/Page36/Audio/audio-e1-p36.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page36/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page36/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/2.jpg', audioUrl: "img/FriendsPlus/Page36/Audio/tieude-e1-p36.mp3" },
        { url: 'img/FriendsPlus/Page36/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page36/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page36/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page36/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page36/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page36/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/10.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page36/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page36/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/14.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page36/E1/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page36/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page36/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page36/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/20.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E1/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page36/E1/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page36/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/24.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page36/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/26.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page36/E1/27.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page36/E1/28.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page36/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/30.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/32.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page36/E1/33.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page36/E1/34.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: "Unit 5",
    id: "WB5-V2-U5-P36-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page36/E2/Key/answerKey.png",
    checkUppercase: true,
    inputSize: 200,
    titleImage: "",
    titleQuestion: [{ color: '#000000', num: '2', title: 'Read the text in the Student Book. Write the answer.' }],
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page36/E2/1.jpg">
          <div style='display: flex;'>
            <div style='line-height: 54px;flex:1;'>
                <div>1&ensp;Are the monkeys eating?</div>
                <div>2&ensp;Are the zebras running?</div>
                <div>3&ensp;Are the tigers playing?</div>
                <div>4&ensp;Are the parrots talking?</div>
                <div>5&ensp;Are the penguins walking?</div>
                <div>6&ensp;Are the parrots flying?</div>
            </div>
            <div style='line-height: 54px;flex:1;'>
                <div><u style='color: gray;'>&ensp;No, they aren’t.&ensp;</u></div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
            </div>
            <div style='flex:1;'>
              <img src="img/FriendsPlus/Page36/E2/2.jpg">
            </div>
          </div>
        `,
        answer: ["Yes, they are.", "No, they aren't.", "Yes, they are.", "No, they aren't.", "Yes, they are."],
      },
    ],
  },
}

export default json;