import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'WB4-U12-P82-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page82/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Look and circle the correct word.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white', fontWeight: 'bold' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          'long_hair / short_hair', //0
          'short / tall', //1
          'young / old', //2
        ],
        answers: ['0-4', '1-0', '2-0'],
        initialValue: [],
      },
      Layout: `
        <div style='display: flex;'>
          <div>
            <img src="img/FriendsPlus/Page82/E1/1.jpg">
          </div>
          <div style='line-height: 48px;'>
            <div>When he was two years old ...</div>
            <div><b>1</b> he was <span style='font-weight: bold;'>shy / <span style='border: 2px solid gray; border-radius: 50%;'>friendly.</span></span></div>
            <div><b>2</b> he had <input id='0' type='Circle' />.</div>
            <div><b>3</b> he was <input id='1' type='Circle' />.</div>
            <div><b>4</b> he was <input id='2' type='Circle' />.</div>
          </div>
        </div>
      `,
    },
  },
  2: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P82-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    inputSize: 150,
    titleQuestion: [{ color: 'black', num: '2', title: 'Write true sentences about you. Use <i>was, wasn’t, had,</i> or <i>didn’t have</i>.' }],
    // titleImage: "img/FriendsPlus/Page82/E2/1.jpg",
    // checkUppercase: true,
    hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <div>When I was four years old ...</div>
              <div>I # shy.</div>
              <div>I # short.</div>
              <div>I # long hair.</div>
            </div>
            <div>
              <div>When I was six years old ...</div>
              <div>I # friendly.</div>
              <div>I # short hair.</div>
              <div>I # tall.</div>
            </div>
          </div>
        `,
        answer: ['', '', '', '', '', ''],
      },
    ]
  },
  3: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P82-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page82/E3/Key/answerKey.png",
    inputSize: 70,
    titleQuestion: [{ color: 'black', num: '3', title: 'Complete the song with <i>was</i> or <i>had</i>.' }],
    // titleImage: "img/FriendsPlus/Page82/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    textAlign: 'center',
    maxLength: 3,
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page82/E3/1.jpg">
            </div>
            <div style='margin-left: 20px;'>
              <div>When my grandpa <sup>1</sup><u style='color: gray;'>&ensp;was&ensp;</u> a boy,</div>
              <div>He <sup>2</sup># a lot like me.</div>
              <div>He <sup>3</sup># a nice apartment,</div>
              <div>And a happy family.</div>
              <div>Grandpa <sup>4</sup># a happy boy,</div>
              <div>And he <sup>5</sup># lots of friends.</div>
              <div>They <sup>6</sup># lessons every day,</div>
              <div>And they <sup>7</sup># fun on weekends.</div>
              <div>When my grandpa <sup>8</sup># a boy,</div>
              <div>He <sup>9</sup># fun every day.</div>
              <div>He <sup>10</sup># lots of books to read,</div>
              <div>And lots of games to play.</div>
            </div>
          </div>
        `,
        answer: ['was', 'had', 'was', 'had', 'had', 'had', 'was', 'had', 'had'],
      },
    ]
  },
}
export default json;