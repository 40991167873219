import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P90-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page90/E1/Key/answerKey.png",
    inputSize: 80,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page90/E1/1.jpg">
          <div style='margin-left:50px;'>
            <div><b>1</b> We have a new DVD player. <u style='color: gray;'>&ensp;Our&ensp;</u> new DVD player is small.</div>
            <div><b>2</b> Lisa and Jim are playing with # friends.</div>
            <div><b>3</b> Can you put on # coat, please?</div>
            <div><b>4</b> We’re reading # books together.</div>
            <div><b>5</b> Can I use # camera, please?</div>
            <div><b>6</b> The children are using # new MP3 player.</div>
          </div>
        `,
        answer: ['their', 'your', 'our', 'your', 'their'],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Grammar Time',
    id: 'WB4-GT-P90-E2',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page90/E2/Key/answerKey.png',
    checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page90/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page90/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/3.jpg', input: true, answer: "Can" },
        { url: 'img/FriendsPlus/Page90/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/5.jpg', input: true, answer: "turn" },
        { url: 'img/FriendsPlus/Page90/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/7.jpg', input: true, answer: "Yes, he can." },
        { url: 'img/FriendsPlus/Page90/E2/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page90/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/10.jpg', input: true, answer: "Can" },
        { url: 'img/FriendsPlus/Page90/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/12.jpg', input: true, answer: "close" },
        { url: 'img/FriendsPlus/Page90/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/14.jpg', input: true, answer: "Yes, we can./Yes, you can." },
        { url: 'img/FriendsPlus/Page90/E2/15.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page90/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/17.jpg', input: true, answer: "Can" },
        { url: 'img/FriendsPlus/Page90/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/19.jpg', input: true, answer: "listen" },
        { url: 'img/FriendsPlus/Page90/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/21.jpg', input: true, answer: "No, she can't." },
        { url: 'img/FriendsPlus/Page90/E2/22.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page90/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/24.jpg', input: true, answer: "Can" },
        { url: 'img/FriendsPlus/Page90/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/26.jpg', input: true, answer: "have" },
        { url: 'img/FriendsPlus/Page90/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page90/E2/28.jpg', input: true, answer: "No, they can't." },
        { url: 'img/FriendsPlus/Page90/E2/29.jpg' },
      ],
    ]
  },
}
export default json;