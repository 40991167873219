import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'WB4-U10-P70-E1',
    audio: 'img/FriendsPlus/Page70/Audio/audio-e1-p70.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: "img/FriendsPlus/Page70/E1/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page70/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page70/Audio/tieude-e1-p70.mp3' },
        { url: 'img/FriendsPlus/Page70/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page70/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page70/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page70/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/8.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/10.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page70/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page70/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page70/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/14.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page70/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page70/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page70/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/22.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/24.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page70/E1/25.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page70/E1/26.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page70/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/28.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page70/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/30.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page70/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page70/E1/32.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E1/33.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page70/E1/34.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: "Unit 10",
    id: "WB5-V2-U10-P70-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page70/E2/Key/answerKey.png",
    inputSize: 110,
    titleQuestion: [{ color: 'black', num: '2', title: 'Complete the song with <i>must</i> or <i>mustn’t</i>.' }],
    // titleImage: "img/FriendsPlus/Page70/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page70/E2/1.jpg" />
            </div>
            <div>
              <div>Oh, you <sup>1</sup><u style='color: gray;'>&ensp;must&ensp;</u> come to the park,</div>
              <div>So we can have some fun.</div>
              <div>You <sup>2</sup># come to the park today,</div>
              <div>And play games in the sun.</div>
              <div>But we <sup>3</sup># walk on the path,</div>
              <div>And we <sup>4</sup># climb the trees.</div>
              <div>We <sup>5</sup># throw away our litter,</div>
              <div>Let’s keep the park clean, please.</div>
              <div>Oh, we <sup>6</sup># be very good,</div>
              <div>And we <sup>7</sup># pick the flowers.</div>
              <div>But we can have a lot of fun,</div>
              <div>And play for hours and hours.</div>
            </div>
          </div>
        `,
        answer: ["must", "must", "mustn't", "must", "must", "mustn't"],
      },
    ]
  },
}
export default json;