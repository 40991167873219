import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  4: { // Exercise num
    unit: "Review 2",
    id: "WB5-V2-PV-P47-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page47/E4/Key/answerKey.png",
    inputSize: 200,
    titleQuestion: [{ color: 'black', num: '4', title: 'Write.' }],
    titleImage: "",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; margin-bottom: 10px;'>
            <div style='margin-right: 10px;'>
              <img src="img/FriendsPlus/Page47/E4/1.jpg">
              <div>He <u style='color: gray;'>&ensp;doesn’t get&ensp;</u> (get) up at eight o’clock.</div>
            </div>
            <div style='margin-left: 10px; overflow: auto; width: 440px;'>
              <img src="img/FriendsPlus/Page47/E4/2.jpg">
              <div>They # (live) in a big house.</div>
            </div>
          </div>
          <div style='display: flex; margin-top: 10px;'>
            <div style='margin-right: 10px; overflow: auto; width: 440px;'>
              <img src="img/FriendsPlus/Page47/E4/3.jpg">
              <div>She # (have) breakfast with her family.</div>
            </div>
            <div style='margin-left: 10px; overflow: auto; width: 440px;'>
              <img src="img/FriendsPlus/Page47/E4/4.jpg">
              <div>He # (have) a shower in the morning.</div>
            </div>
          </div>
        `,
        answer: ['live', "doesn't have", 'has'],
      },
    ],
  },

  5: { // Exercise num
    unit: "Review 2",
    id: "WB5-V2-PV-P47-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page47/E5/Key/answerKey.png",
    inputSize: 100,
    // titleQuestion: [],
    titleImage: "img/FriendsPlus/Page47/E5/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='margin-right: 10px; overflow: auto; width: 214px;'>
              <img src="img/FriendsPlus/Page47/E5/2.jpg">
              <div>He’s sitting on a <u style='color: gray;'>&ensp;wall&ensp;</u>.</div>
            </div>
            <div style='margin: 0px 10px; overflow: auto; width: 214px;'>
              <img src="img/FriendsPlus/Page47/E5/3.jpg">
              <div>She’s playing with her #.</div>
            </div>
            <div style='margin: 0px 10px; overflow: auto; width: 214px;'>
              <img src="img/FriendsPlus/Page47/E5/4.jpg">
              <div>They’re shopping in the #.</div>
            </div>
            <div style='margin-left: 10px; overflow: auto; width: 214px;'>
              <img src="img/FriendsPlus/Page47/E5/5.jpg">
              <div>His sister is very #.</div>
            </div>
          </div>
        `,
        answer: ['ball', 'mall', 'small'],
      },
    ],
  },

  6: { // Exercise num
    unit: 'Review 2',
    id: 'WB4-PV-P47-E6',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    hideBtnFooter: true,
    exerciseKey: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page47/E6/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page47/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page47/E6/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page47/E6/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page47/E6/5.jpg' },
        { url: 'img/FriendsPlus/Page47/E6/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page47/E6/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page47/E6/8.jpg' },
        { url: 'img/FriendsPlus/Page47/E6/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page47/E6/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page47/E6/11.jpg' },
        { url: 'img/FriendsPlus/Page47/E6/12.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page47/E6/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page47/E6/14.jpg' },
      ],
    ],
  },
}

export default json;