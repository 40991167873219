import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: { // Exercise num
    unit: "Review 2",
    id: "WB5-V2-PV-P46-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page46/E1/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [{ color: 'black', num: '1', title: 'Write the verb.' }],
    titleImage: "",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page46/E1/1.jpg">
          <div style='display: flex;'>
            <div style='flex: 2;margin-right: 10px;'>
              <div>1&ensp;I <u style='color: gray;'>&ensp;have&ensp;</u> breakfast.</div>
              <div>3&ensp;I # my teeth.</div>
              <div>5&ensp;I # dressed.</div>
            </div>
            <div style='flex: 2;margin-left: 10px;'>
              <div>2&ensp;I # my homework.</div>
              <div>4&ensp;I # to school.</div>
              <div>6&ensp;I # outside.</div>
            </div>
          </div>
        `,
        answer: ['brush', 'get', 'do', 'go', 'go'],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Review 2',
    id: 'WB4-PV-P46-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page46/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Circle the odd-one-out.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          "sorry free kind water", //0
          "beautiful waterski surf snorkel", //1
          "safe dangerous dolphin clean", //2
          'first safe then next', //3
          'penguin monkey camel scared', //4
        ],
        answers: ['2-4', '0-6', '1-0', '4-6', '3-2'],
        initialValue: [],
      },
      Layout: `
        <div style="display: flex;flex-direction: row;line-height: 48px;justify-content: space-between;width: 850px; word-spacing: 10px;">
          <div>
            <div><b>1</b> kayak windsurf snorkel <span style=" border: solid 2px gray; border-radius: 50%; padding: 5px; ">polluted</span></div>
            <div><b>3</b> <input id='0' type='Circle' /> </div>
            <div><b>5</b> <input id='1' type='Circle' /> </div>
          </div>
          <div>
            <div><b>2</b> <input id='2' type='Circle' /> </div>
            <div><b>4</b> <input id='3' type='Circle' /> </div>
            <div><b>6</b> <input id='4' type='Circle' /> </div>
          </div>
        </div>
      `,
    },
  },
  3: { // Exercise num
    unit: 'Review 2',
    id: 'WB4-PV-P46-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page46/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Circle the correct word. Then write a short answer.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: { width: 200, color: 'black' },
        answers: ['they are', "I'm not/we aren't", 'he does', "we don't", 'she does'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white', fontWeight: 'bold' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          "I / they", //0
          "she / you", //1
          "he / they", //2
          'she / we', //3
          'she / you', //4
        ],
        answers: ['1-4', '0-4', '2-0', '3-4', '4-0'],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex;'>
          <div style='margin-right:10px;'>
            <div><b>1</b>&ensp;Is <span style='font-weight:bold;'><span style='border: 2px solid gray; border-radius: 50%;'>he</span> / I</span> sailing?</div>
            <div><b>2</b>&ensp;Are <input id='0' type='Circle' /> surfing?</div>
            <div><b>3</b>&ensp;Are <input id='1' type='Circle' /> watching the penguins?</div>
            <div><b>4</b>&ensp;Does <input id='2' type='Circle' /> live in a big house?</div>
            <div><b>5</b>&ensp;Do <input id='3' type='Circle' /> get up early?</div>
            <div><b>6</b>&ensp;Does <input id='4' type='Circle' /> have a shower in the morning?</div>
          </div>
          <div style='margin-left:10px;'>
            <div>No, <u style='color: gray;'>&ensp;he isn’t&ensp;</u>.</div>
            <div>Yes, <input id='0' />.</div>
            <div>No, <input id='1' />.</div>
            <div>Yes, <input id='2' />.</div>
            <div>No, <input id='3' />.</div>
            <div>Yes, <input id='4' />.</div>
          </div>
        </div>
      `,
    },
  },
}

export default json;