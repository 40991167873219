import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine'

const json = {
  1: {
    // Exercise num
    unit: 'Starter',
    id: 'WB4-S-P5-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page5/E1/Key/answerKey.png',
    titleImage: '',
    questionImage: [
      // Row
      [{ url: 'img/FriendsPlus/Page5/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page5/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page5/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/5.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/6.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/8.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page5/E1/11.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/13.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page5/E1/16.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page5/E1/17.jpg' }],
    ],
  },
  2: {
    // Exercise num
    unit: 'Starter',
    id: 'WB4-S-P5-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page5/E2/Key/answerKey.png',
    isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: 'img/FriendsPlus/Page5/E2/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page5/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/3.jpg', input: true, answer: 'bigger' },
        { url: 'img/FriendsPlus/Page5/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/6.jpg', input: true, answer: 'smaller' },
        { url: 'img/FriendsPlus/Page5/E2/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page5/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/9.jpg', input: true, answer: 'bigger' },
        { url: 'img/FriendsPlus/Page5/E2/10.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page5/E2/11.jpg' }],
    ],
  },
  3: {
    // Exercise num
    unit: 'Starter',
    id: 'WB4-S-P5-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page5/E3/Key/answerKey.png',
    isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: 'img/FriendsPlus/Page5/E3/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page5/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/3.jpg', input: true, answer: 'was' },
        { url: 'img/FriendsPlus/Page5/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/5.jpg', input: true, answer: 'were' },
        { url: 'img/FriendsPlus/Page5/E3/6.jpg' },
      ],
      [
        
        { url: 'img/FriendsPlus/Page5/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/8.jpg', input: true, answer: 'were' },
        { url: 'img/FriendsPlus/Page5/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/10.jpg', input: true, answer: 'was' },
        { url: 'img/FriendsPlus/Page5/E3/11.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page5/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/13.jpg', input: true, answer: 'was' },
        { url: 'img/FriendsPlus/Page5/E3/14.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page5/E3/15.jpg' },
      ],
    ],
  },
};

export default json;
