import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: "Unit 8",
    id: "WB5-V2-U8-P59-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page59/E1/Key/answerKey.png",
    inputSize: 150,
    titleQuestion: [{ color: 'black', num: '1', title: 'Order the words and write.' }],
    // titleImage: "img/FriendsPlus/Page59/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='line-height: 45px; margin-right: 20px;'>
              <div><b>1</b>&ensp;He’s playing with a <u style='color: gray;'>&ensp;small&ensp;</u>,<u style='color: gray;'>&ensp;blue&ensp;</u> <u style='color: gray;'>&ensp;ball&ensp;</u></div>
              <div><b>2</b>&ensp;She’s wearing #,# #</div>
              <div><b>3</b>&ensp;You need a #,# #</div>
              <div><b>4</b>&ensp;He’s sailing in a #,# #</div>
              <div><b>5</b>&ensp;We’d like a #,# #</div>
            </div>
            <div>
              <img src="img/FriendsPlus/Page59/E1/1.jpg" />
            </div>
          </div>
        `,
        answer: ['big', 'pink', 'sunglasses', 'big', 'red', 'onion', 'little', 'red', 'boat', 'long', 'green', 'cucumber'],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB4-U8-P59-E2',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page59/E2/Key/answerKey.png',
    checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page59/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page59/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page59/E2/3.jpg', input: true, answer: "butter" },
        { url: 'img/FriendsPlus/Page59/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page59/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page59/E2/6.jpg', input: true, answer: "cheese" },
        { url: 'img/FriendsPlus/Page59/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page59/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page59/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page59/E2/10.jpg', input: true, answer: "grapes" },
        { url: 'img/FriendsPlus/Page59/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page59/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page59/E2/13.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: "Unit 8",
    id: "WB5-V2-U8-P59-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    inputSize: 760,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Order the words and write.' }],
    // titleImage: "img/FriendsPlus/Page59/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    hideBtnFooter: true,
    textareaStyle: { width: 770 },
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div>
              <img src="img/FriendsPlus/Page59/E3/1.jpg" />
              <img src="img/FriendsPlus/Page59/E3/2.jpg" />
            </div>
            
            <div style=" position: absolute; top: 107px; left: 168px;background: transparent;">#</div>
            <div style=" position: absolute; top: 148px; left: 162px;"><textarea id="0" rows="4"></textarea></div>
          </div>
          `,
        answer: ['',''],
      },
    ]
  },
}
export default json;