import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Review 1',
    id: 'WB4-PV-P26-E1',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page26/E1/Key/answerKey.png',
    isAllowSubmit: false,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page26/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page26/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/3.jpg', input: true, answer: "visiting" },
        { url: 'img/FriendsPlus/Page26/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page26/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/6.jpg', input: true, answer: "cooking" },
        { url: 'img/FriendsPlus/Page26/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/8.jpg', input: true, answer: "taking" },
        { url: 'img/FriendsPlus/Page26/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page26/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/11.jpg', input: true, answer: "doing" },
        { url: 'img/FriendsPlus/Page26/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page26/E1/13.jpg', input: true, answer: "playing" },
        { url: 'img/FriendsPlus/Page26/E1/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page26/E1/15.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Review 1',
    id: 'WB4-PV-P26-E2',
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: "img/FriendsPlus/Page26/E2/Key/answerKey.png",
    titleImage: "",
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page26/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page26/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page26/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/5.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page26/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page26/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/9.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E2/10.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page26/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page26/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page26/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/16.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page26/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page26/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/22.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E2/23.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/24.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page26/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/26.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page26/E2/27.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page26/E2/28.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/29.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page26/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/31.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page26/E2/32.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/33.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page26/E2/34.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/35.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E2/36.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/37.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E2/38.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/39.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page26/E2/40.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/41.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page26/E2/42.jpg' },
        { url: 'img/FriendsPlus/Page26/E2/43.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page26/E2/44.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Review 1',
    id: 'WB4-PV-P26-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: 'img/FriendsPlus/Page26/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page26/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page26/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/5.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page26/E3/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page26/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/8.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page26/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/12.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page26/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/14.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E3/15.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page26/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/17.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page26/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/19.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/21.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page26/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page26/E3/23.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page26/E3/24.jpg' },
      ],
    ]
  },
}

export default json;