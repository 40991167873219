import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';

const json = {
  1: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P86-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page86/E1/Key/answerKey.png",
    inputSize: 120,
    titleQuestion: [{ color: 'black', num: '1', title: 'Choose the correct word. Then write.' }],
    // titleImage: "img/FriendsPlus/Page86/E2/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page86/E1/1.jpg">
          <div style='display:flex;margin: 20px 0;'>
            <div style='margin-right:10px;'>
              <div>
                <img src="img/FriendsPlus/Page86/E1/2.jpg">
              </div>
              <div>My grandpa was <u style='color:gray;'>&ensp;handsome&ensp;</u>.</div>
            </div>
            <div style='margin-left:10px;'>
              <div>
                <img src="img/FriendsPlus/Page86/E1/3.jpg">
              </div>
              <div>The boy is #.</div>
            </div>
          </div>
          <div style='display:flex;'>
            <div style='margin-right:10px;'>
              <div>
                <img src="img/FriendsPlus/Page86/E1/4.jpg">
              </div>
              <div>My dad is #.</div>
            </div>
            <div style='margin-left:10px;'>
              <div>
                <img src="img/FriendsPlus/Page86/E1/5.jpg">
              </div>
              <div>My grandma is #.</div>
            </div>
          </div>
        `,
        answer: ['worried', 'relaxed', 'short'],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'WB4-U12-P86-E1',
    audio: '',
    video: '',
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page86/E2/Key/answerKey.png",
    // titleQuestion: [{ num: '1', title: 'Listen and draw lines.', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          { boxMatchStyle: { position: 'absolute', top: '304px', left: '135px', width: 110, height: 25, border: '1px dashed black' }, }, //0
          { boxMatchStyle: { position: 'absolute', top: '443px', left: '418px', width: 70, height: 25, border: '1px dashed black' }, }, //1
          { boxMatchStyle: { position: 'absolute', top: '259px', left: '695px', width: 85, height: 25, border: '1px dashed black' }, }, //2

          { boxMatchStyle: { position: 'absolute', top: '351px', left: '158px', width: 160, height: 25, border: '1px dashed black' }, }, //3
          { boxMatchStyle: { position: 'absolute', top: '259px', left: '470px', width: 160, height: 25, border: '1px dashed black' }, }, //4
          { boxMatchStyle: { position: 'absolute', top: '306px', left: '702px', width: 95, height: 25, border: '1px dashed black' }, }, //5

          { boxMatchStyle: { position: 'absolute', top: '396px', left: '134px', width: 105, height: 25, border: '1px dashed black' }, }, //6
          { boxMatchStyle: { position: 'absolute', top: '394px', left: '433px', width: 85, height: 25, border: '1px dashed black' }, }, //7
          { boxMatchStyle: { position: 'absolute', top: '351px', left: '760px', width: 215, height: 25, border: '1px dashed black' }, }, //8

          { boxMatchStyle: { position: 'absolute', top: '441px', left: '132px', width: 100, height: 25, border: '1px dashed black' }, }, //9
          { boxMatchStyle: { position: 'absolute', top: '350px', left: '450px', width: 130, height: 25, border: '1px dashed black' }, }, //10
          { boxMatchStyle: { position: 'absolute', top: '395px', left: '693px', width: 85, height: 25, border: '1px dashed black' }, }, //11
        ],
        answers: ['0-1', '1-2', '3-4', '4-5', '6-7', '7-8', '10-9', '10-11'],
        initialValue: [],
      },
      Layout: `
        <img src='img/FriendsPlus/Page86/E2/1.jpg' />
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />

        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />

        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />

        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
        <input id='11' type= 'boxMatch' />
      `,
    },
  },
}
export default json;