import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'WB4-U8-P56-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page56/E1/Key/answerKey.png',
    checkUppercase: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page56/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page56/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/3.jpg', input: true, answer: "a melon, Stacy" },
        { url: 'img/FriendsPlus/Page56/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page56/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page56/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/7.jpg', input: true, answer: "some meat" },
        { url: 'img/FriendsPlus/Page56/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page56/E1/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page56/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/11.jpg', input: true, answer: "some cereal, Mark" },
        { url: 'img/FriendsPlus/Page56/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page56/E1/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page56/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/15.jpg', input: true, answer: "a lemon" },
        { url: 'img/FriendsPlus/Page56/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page56/E1/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page56/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page56/E1/19.jpg', input: true, answer: "a cucumber, Emma" },
        { url: 'img/FriendsPlus/Page56/E1/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page56/E1/21.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: "Unit 8",
    id: "WB5-V2-U8-P54-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page54/E2/Key/answerKey.png",
    inputSize: 70,
    titleQuestion: [{ color: 'black', num: '2', title: 'What would you like for dinner? Write <i>a</i>, <i>an</i>, or <i>some</i> and food words.' }],
    // titleImage: "img/FriendsPlus/Page54/E2/1.jpg",
    // checkUppercase: true,
    hideBtnFooter: true,
    textareaStyle: { width: 520 },
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <textarea id="0" rows="4"></textarea>
            </div>
            <div>
              <img src="img/FriendsPlus/Page56/E2/1.jpg">
            </div>
          </div>
        `,
        answer: ['no', 'yes', 'yes', 'no', 'yes', 'no'],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'WB4-U8-P56-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page56/E3/Key/answerKey.png',
    // checkUppercase: true,
    maxLength: 2,
    padding: 0,
    textAlign: 'center',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page56/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page56/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/3.jpg', input: true, answer: "6" },
        { url: 'img/FriendsPlus/Page56/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page56/E3/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page56/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/7.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page56/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page56/E3/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page56/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/11.jpg', input: true, answer: "7" },
        { url: 'img/FriendsPlus/Page56/E3/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page56/E3/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page56/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/15.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page56/E3/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page56/E3/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page56/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/19.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page56/E3/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page56/E3/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page56/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/23.jpg', input: true, answer: "9" },
        { url: 'img/FriendsPlus/Page56/E3/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page56/E3/25.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page56/E3/26.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/27.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page56/E3/28.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page56/E3/29.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page56/E3/30.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/31.jpg', input: true, answer: "11" },
        { url: 'img/FriendsPlus/Page56/E3/32.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page56/E3/33.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page56/E3/34.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/35.jpg', input: true, answer: "12" },
        { url: 'img/FriendsPlus/Page56/E3/36.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page56/E3/37.jpg' },
      ],
      [
        // Column20
        { url: 'img/FriendsPlus/Page56/E3/38.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/39.jpg', input: true, answer: "8" },
        { url: 'img/FriendsPlus/Page56/E3/40.jpg' },
      ],
      [
        // Column21
        { url: 'img/FriendsPlus/Page56/E3/41.jpg' },
      ],
      [
        // Column22
        { url: 'img/FriendsPlus/Page56/E3/42.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/43.jpg', input: true, answer: "10" },
        { url: 'img/FriendsPlus/Page56/E3/44.jpg' },
      ],
      [
        // Column23
        { url: 'img/FriendsPlus/Page56/E3/45.jpg' },
      ],
    ],
  },
}
export default json;