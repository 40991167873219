import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P61-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 7,
    exerciseKey: "img/FriendsPlus/Page61/E1/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page61/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page61/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page61/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/5.jpg', input: 1 },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page61/E1/6.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page61/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/8.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page61/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/10.jpg', input: 2 },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page61/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page61/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/13.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page61/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/15.jpg', input: 3, isCorrect: true },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page61/E1/16.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page61/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/18.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page61/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/20.jpg', input: 4 },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page61/E1/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page61/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/23.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page61/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/25.jpg', input: 5, isCorrect: true },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page61/E1/26.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page61/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/28.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page61/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/30.jpg', input: 6 },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page61/E1/31.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page61/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/33.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page61/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page61/E1/35.jpg', input: 7 },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page61/E1/36.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P61-E2',
    audio: 'img/FriendsPlus/Page61/Audio/audio-e2-p61.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page61/E2/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page61/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page61/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page61/Audio/tieude-e2-p61.mp3' },
        { url: 'img/FriendsPlus/Page61/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page61/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page61/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page61/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/9.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page61/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/11.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page61/E2/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page61/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page61/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page61/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/17.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page61/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/19.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page61/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/21.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page61/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/23.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page61/E2/24.jpg' },
        { url: 'img/FriendsPlus/Page61/E2/25.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page61/E2/26.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page61/E2/27.jpg' },
      ],

    ]
  },
  3: { // Exercise num
    unit: "Unit 9",
    id: "WB5-V2-U9-P61-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page61/E3/Key/answerKey.png",
    inputSize: 200,
    titleQuestion: [{ color: 'black', num: '3', title: 'Write the answer.' }],
    // titleImage: "img/FriendsPlus/Page61/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; margin-top: 20px;'>
            <div style='margin-right: 10px;'>
              <div><b>1</b> Are cheetahs faster than elephants?</div>
              <div><b>2</b> Are bikes bigger than cars?</div>
              <div><b>3</b> Is Ben the tallest boy?</div>
            </div>
            <div style='margin-left: 10px;'>
              <div><u style='color: gray;'>&ensp;Yes, they are.&ensp;</u></div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
        `,
        answer: ["No, they aren't.", "No, he isn't."],
      },
    ]
  },
}
export default json;