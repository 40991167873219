import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P14-E1",
    audio: "img/FriendsPlus/Page14/Audio/audio-e1-p14.mp3",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page14/E1/Key/answerKey.png",
    titleQuestion: [{ num: '1', title: 'Listen and draw lines. <headphone name="04" src="img/FriendsPlus/Page14/Audio/tieude-e1-p14.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          { boxMatchStyle: { position: 'absolute', top: '26px', left: '275px', width: "95px", height: "34px", border: "1px dashed black" }, }, // 0
          { boxMatchStyle: { position: 'absolute', top: '333px', left: '638px', width: "106px", height: "130px", border: "1px dashed black" }, }, // 1
          { boxMatchStyle: { position: 'absolute', top: '26px', left: '421px', width: "95px", height: "34px", border: "1px dashed black" }, }, // 2
          { boxMatchStyle: { position: 'absolute', top: '226px', left: '306px', width: "44px", height: "161px", border: "1px dashed black" }, }, // 3
          { boxMatchStyle: { position: 'absolute', top: '26px', left: '713px', width: "95px", height: "34px", border: "1px dashed black" }, }, // 4
          { boxMatchStyle: { position: 'absolute', top: '172px', left: '468px', width: "100px", height: "185px", transform: 'rotate(30deg)', border: "1px dashed black" }, }, // 5
          { boxMatchStyle: { position: 'absolute', top: '448px', left: '348px', width: "95px", height: "34px", border: "1px dashed black" }, }, // 6
          { boxMatchStyle: { position: 'absolute', top: '338px', left: '366px', width: "74px", height: "120px", border: "1px dashed black" }, }, // 7
          { boxMatchStyle: { position: 'absolute', top: '448px', left: '494px', width: "95px", height: "34px", border: "1px dashed black" }, }, // 8
          { boxMatchStyle: { position: 'absolute', top: '120px', left: '407px', width: "35px", height: "114px", transform: 'rotate(30deg)', border: "1px dashed black" }, }, // 9
          { boxMatchStyle: { position: 'absolute', top: '448px', left: '640px', width: "95px", height: "34px", border: "1px dashed black" }, }, // 10
          { boxMatchStyle: { position: 'absolute', top: '241px', left: '729px', width: "62px", height: "97px", border: "1px dashed black" }, }, // 11
        ],
        answers: ['0-1', '2-3', '4-5', '6-7', '8-9', '10-11'],
        initialValue: [],
      },
      Layout: `
        <img src='img/FriendsPlus/Page14/E1/4.jpg' />
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />
        <input id='2' type= 'boxMatch' />

        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />

        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />

        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
        <input id='11' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P14-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page14/E2/Key/answerKey.png",
    isAllowSubmit: true,
    checkUppercase: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page14/E2/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page14/E2/2.jpg" },
        { url: "img/FriendsPlus/Page14/E2/3.jpg", input: true, answer: "They play basketball" },
        { url: "img/FriendsPlus/Page14/E2/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page14/E2/5.jpg" }],
      [
        // Column4
        { url: "img/FriendsPlus/Page14/E2/6.jpg" },
        { url: "img/FriendsPlus/Page14/E2/7.jpg", input: true, answer: "They take photos" },
        { url: "img/FriendsPlus/Page14/E2/8.jpg" },
        { url: "img/FriendsPlus/Page14/E2/9.jpg", input: true, answer: "They read comics" },
        { url: "img/FriendsPlus/Page14/E2/10.jpg" },
      ],
    ],
  },
};

export default json;
