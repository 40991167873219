import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P96-E1",
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: "img/FriendsPlus/Page96/E1/Key/answerKey.png",
    // checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page96/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page96/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page96/E1/3.jpg', input: true, answer: "was" },
        { url: 'img/FriendsPlus/Page96/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page96/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page96/E1/6.jpg', input: true, answer: "were" },
        { url: 'img/FriendsPlus/Page96/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page96/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page96/E1/9.jpg', input: true, answer: "wasn't" },
        { url: 'img/FriendsPlus/Page96/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page96/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page96/E1/12.jpg', input: true, answer: "weren't" },
        { url: 'img/FriendsPlus/Page96/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page96/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page96/E1/15.jpg', input: true, answer: "was" },
        { url: 'img/FriendsPlus/Page96/E1/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page96/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page96/E1/18.jpg', input: true, answer: "wasn't" },
        { url: 'img/FriendsPlus/Page96/E1/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page96/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page96/E1/21.jpg', input: true, answer: "weren't" },
        { url: 'img/FriendsPlus/Page96/E1/22.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P96-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page96/E2/Key/answerKey.png",
    inputSize: 120,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page96/E2/1.jpg">
          <div style='display: flex;margin-left:50px;'>
            <div>
              <div><b>1</b> I # a music lesson.</div>
              <div><b>3</b> I # cereal for breakfast.</div>
            </div>
            <div style='margin-left:10px;'>
              <div><b>2</b> I # sandwiches for lunch.</div>
              <div><b>4</b> I # dinner with my family.</div>
            </div>
          </div>
        `,
        answer: ['', '', '', ''],
      },
    ]
  },
}
export default json;