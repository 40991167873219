import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P15-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page15/E1/Key/answerKey.png",
    isAllowSubmit: true,
    textAlign: "center",
    question: [],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page15/E1/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page15/E1/2.jpg" },
        { url: "img/FriendsPlus/Page15/E1/3.jpg", input: true, answer: "yes" },
        { url: "img/FriendsPlus/Page15/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page15/E1/5.jpg" }],
      [
        // Column4
        { url: "img/FriendsPlus/Page15/E1/6.jpg" },
        { url: "img/FriendsPlus/Page15/E1/7.jpg", input: true, answer: "no" },
        { url: "img/FriendsPlus/Page15/E1/8.jpg" },
        { url: "img/FriendsPlus/Page15/E1/9.jpg", input: true, answer: "yes" },
        { url: "img/FriendsPlus/Page15/E1/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page15/E1/11.jpg" }],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB5-V2-U2-15-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page15/E2/Key/answerKey.png",
    inputSize: '100%',
    // titleQuestion: [{ color: 'black', num: '1', title: 'Order the words and write.' }],
    // titleImage: "img/FriendsPlus/Page59/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    // hideBtnFooter: true,
    textareaStyle: { width: '100%', resize: 'none', padding: 0 },
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div>
              <img src="img/FriendsPlus/Page15/E2/1.jpg" />
            </div>
            <div style=" position: absolute; top: 207px; left: 587px;width: 266px;"><textarea id="0" rows="1"></textarea></div>
            <div style=" position: absolute; top: 280px; left: 210px;width: 262px;"><textarea id="1" rows="2">My brother \t\t comics.</textarea></div>
            <div style=" position: absolute; top: 280px; left: 691px;width: 248px;"><textarea id="2" rows="2">My sister \t\t chess.</textarea></div>
            <div style=" position: absolute; top: 603px; left: 294px;width: 182px;"><textarea id="3" rows="2">I \t\t .</textarea></div>
            <div style=" position: absolute; top: 637px; left: 572px;width: 356px;"><textarea id="4" rows="1"></textarea></div>
          </div>
          `,
        answer: ['like flying', "My brother doesn't like reading comics.", "My sister doesn't like playing chess.", 'I like playing basketball.', "don't like fishing"],
      },
    ]
  },
};

export default json;
