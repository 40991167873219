import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P51-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page51/E1/Key/answerKey.png",
    inputSize: 200,
    titleQuestion: [{ color: 'black', num: '1', title: 'Say the words with ld and lt. Circle the odd-one-out. Write.' }],
    // titleImage: "img/FriendsPlus/Page51/E1/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page51/E1/1.jpg">
          <div style='display: flex;'>
            <img src="img/FriendsPlus/Page51/E1/2.jpg">
            <div style='line-height: 100px; margin-left: 20px;'>
              <div><u style='color: gray;'>&ensp;quilt&ensp;</u></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
        `,
        answer: ['adult', 'field', 'child'],
      },
    ],
  },
  2: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P51-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page51/E2/Key/answerKey.png",
    inputSize: 50,
    // titleQuestion: [{ color: 'black', num: '2', title: 'Where are the people? Look and write.' }],
    titleImage: "img/FriendsPlus/Page51/E2/1.jpg",
    // checkUppercase: true,
    maxLength: 2,
    // textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div>
            <div>1&ensp;There’s an adu<u style='color: gray;'>&ensp;lt&ensp;</u> in the bedroom.</div>
            <div>2&ensp;There’s a be# on the chair.</div>
            <div>3&ensp;There’s a qui# on the bed.</div>
            <div>4&ensp;There’s a shie# on the door.</div>
            <div>5&ensp;There are some cows in the fie#.</div>
            <div>6&ensp;There’s a chi# in the bedroom.</div>
          </div>
          <div>
            <div><u style='color: gray;'>&ensp;F&ensp;</u></div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
          <div>
            <img src="img/FriendsPlus/Page51/E2/2.jpg">
          </div>
        </div>
        `,
        answer: ['lt', 'lt', 'ld', 'ld', 'ld', 'F', 'T', 'F', 'F', 'F'],
      },
    ],
  },
  3: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P51-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page51/E3/Key/answerKey.png",
    inputSize: 100,
    // titleQuestion: [{ color: 'black', num: '2', title: 'Where are the people? Look and write.' }],
    // titleImage: "img/FriendsPlus/Page51/E2/1.jpg",
    // checkUppercase: true,
    // textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex; align-items: center;'>
          <div style='flex: 1;'>
            <img src="img/FriendsPlus/Page51/E3/1.jpg">
          </div>
          <div style='flex: 1; line-height: 58px;'>
            <div>A child and an <sup>1</sup><u style='color: gray;'>&ensp;adult&ensp;</u> .</div>
            <div>Are standing in a <sup>2</sup>#.</div>
            <div>The adult has a <sup>3</sup>#.</div>
            <div>The child has a <sup>4</sup>#.</div>
          </div>          
        </div>
        `,
        answer: ['field', 'quilt', 'shield'],
      },
    ],
  },
}

export default json;