import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'WB4-U7-P53-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: "img/FriendsPlus/Page53/E1/Key/answerKey.png",
    isAllowSubmit: false,
    textAlign: 'center',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page53/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page53/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/3.jpg', input: true, answer: "big" },
        { url: 'img/FriendsPlus/Page53/E1/4.jpg', input: true, answer: "on" },
        { url: 'img/FriendsPlus/Page53/E1/5.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page53/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/7.jpg', input: true, answer: "watching" },
        { url: 'img/FriendsPlus/Page53/E1/8.jpg', input: true, answer: "old" },
        { url: 'img/FriendsPlus/Page53/E1/9.jpg', input: true, answer: "at" },
        { url: 'img/FriendsPlus/Page53/E1/10.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page53/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/12.jpg', input: true, answer: "sailing" },
        { url: 'img/FriendsPlus/Page53/E1/13.jpg', input: true, answer: "little" },
        { url: 'img/FriendsPlus/Page53/E1/14.jpg', input: true, answer: "in" },
        { url: 'img/FriendsPlus/Page53/E1/15.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page53/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page53/E1/17.jpg', input: true, answer: "goes" },
        { url: 'img/FriendsPlus/Page53/E1/18.jpg', input: true, answer: "new" },
        { url: 'img/FriendsPlus/Page53/E1/19.jpg', input: true, answer: "to" },
        { url: 'img/FriendsPlus/Page53/E1/20.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P53-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page53/E2/Key/answerKey.png",
    inputSize: 128,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Say the words with ld and lt. Circle the odd-one-out. Write.' }],
    // titleImage: "img/FriendsPlus/Page53/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div>
              <img src="img/FriendsPlus/Page53/E2/1.jpg">
              <img src="img/FriendsPlus/Page53/E2/2.jpg" style='margin-top: -6px;'>
            </div>
            <div style='position: absolute; top: 361px; left: 148px; transform: rotate(-3deg);'>#</div>
            <div style='position: absolute; top: 500px; left: 157px; transform: rotate(-3deg);'>#</div>
            <div style='position: absolute; top: 364px; left: 613px; transform: rotate(2deg);'>#</div>
            <div style='position: absolute; bottom: 87px; left: 613px; transform: rotate(2deg);'>#</div>
          </div>
        `,
        answer: ['Mark', 'Nam', 'Katya', 'Sam'],
      },
    ]
  },
  3: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P53-E3",
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    textareaStyle: { width: 820 },
    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div>
              <img src="img/FriendsPlus/Page53/E3/1.jpg">
              <img src="img/FriendsPlus/Page53/E3/2.jpg">
            </div>
            <div style=" position: absolute; top: 187px; left: 94px;"> <textarea id="0" rows="4"></textarea> </div>
          </div>
        `,
        answer: [''],
      },
    ]
  }
}

export default json;