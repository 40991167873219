import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  3: {
    unit: 'Unit 12',
    id: 'WB4-U12-P87-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page87/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Write <i>must</i> or <i>mustn’t</i>. Write ✓ or ✗ for the rules that are the same in your family.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: { width: 100, color: 'black' },
        answers: ["mustn't", "must", "mustn't"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          '✓ ✗', //0
          '✓ ✗', //1
          '✓ ✗', //2
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex;'>
          <div style='margin-right:10px;'>
            <div><b>1</b> You <u style='color: gray;'>&ensp;must&ensp;</u> brush your teeth every day.</div>
            <div><b>2</b> You <input id='0' /> eat in bed.</div>
            <div><b>3</b> You <input id='1' /> help your mom and dad.</div>
            <div><b>4</b> You <input id='2' /> get up late.</div>
          </div>
          <div style='margin-left:10px;'>
            <div><span style='border: 2px solid gray; border-radius: 50%;'>✓</span> ✗</div>
            <div><input id='0' type='Circle' /></div>
            <div><input id='1' type='Circle' /></div>
            <div><input id='2' type='Circle' /></div>
          </div>
        </div>
      `,
    },
  },
  4: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P87-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page87/E4/Key/answerKey.png",
    inputSize: 200,
    titleQuestion: [{ color: 'black', num: '4', title: 'Write <i>There was, There were, There wasn’t,</i> or <i>There weren’t.</i>' }],
    // titleImage: "img/FriendsPlus/Page87/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page87/E4/1.jpg">
            </div>
            <div style='margin-left: 20px;line-height:43px;'>
              <div><b>1</b> <u>&ensp;There were&ensp;</u> flowers.</div>
              <div><b>2</b> # a fountain.</div>
              <div><b>3</b> # any trees.</div>
              <div><b>4</b> # a garbage can.</div>
              <div><b>5</b> # paths.</div>
              <div><b>6</b> # a river.</div>
            </div>
          </div>
        `,
        answer: ["There was", "There weren't", "There wasn't", "There were", "There was"],
      },
    ]
  },
  5: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P87-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page87/E5/Key/answerKey.png",
    inputSize: 50,
    // titleQuestion: [{ color: 'black', num: '2', title: 'Listen and write. <headphone name="26" src="img/FriendsPlus/Page87/Audio/tieude-e2-p87.m43"></headphone>' }],
    titleImage: "img/FriendsPlus/Page87/E5/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    maxLength: 2,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='text-align:center;'>
              <div>
                <img src="img/FriendsPlus/Page87/E5/2.jpg">
              </div>
              <div>
                <div>st<u style='color: gray;'>&ensp;o&ensp;</u>n<u style='color: gray;'>&ensp;e&ensp;</u></div>
              </div>
            </div>
            <div style='text-align:center;margin: 0 10px 0 20px;'>
              <div>
                <img src="img/FriendsPlus/Page87/E5/3.jpg">
              </div>
              <div>
                <div>jell#</div>
              </div>
            </div>
            <div style='text-align:center;margin: 0 10px 0 10px;'>
              <div>
                <img src="img/FriendsPlus/Page87/E5/4.jpg">
              </div>
              <div>
                <div>sn#</div>
              </div>
            </div>
            <div style='text-align:center;margin: 0 10px 0 10px;'>
              <div>
                <img src="img/FriendsPlus/Page87/E5/5.jpg">
              </div>
              <div>
                <div>dr#m</div>
              </div>
            </div>
            <div style='text-align:center;margin: 0 20px 0 10px;'>
              <div>
                <img src="img/FriendsPlus/Page87/E5/6.jpg">
              </div>
              <div>
                <div>qu#n</div>
              </div>
            </div>
            <div style='text-align:center;'>
              <div>
                <img src="img/FriendsPlus/Page87/E5/7.jpg">
              </div>
              <div>
                <div>s#p</div>
              </div>
            </div>
          </div>
        `,
        answer: ['y', 'ow', 'ea', 'ee', 'oa'],
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 12',
    id: 'WB4-U12-P87-E6',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: '',
    // checkUppercase: true,
    hideBtnFooter: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page87/E6/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page87/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page87/E6/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page87/E6/5.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page87/E6/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page87/E6/8.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page87/E6/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page87/E6/11.jpg' },
        { url: 'img/FriendsPlus/Page87/E6/12.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page87/E6/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page87/E6/14.jpg' },
      ],
    ]
  },
}
export default json;