import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P10-E1",
    audio: "img/FriendsPlus/Page10/Audio/audio-e1-p10.mp3",
    video: "",
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: "img/FriendsPlus/Page10/E1/Key/answerKey.png",
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page10/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/2.jpg', audioUrl: "img/FriendsPlus/Page10/Audio/tieude-e1-p10.mp3" },
        { url: 'img/FriendsPlus/Page10/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page10/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page10/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page10/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/8.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page10/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/10.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page10/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page10/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page10/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/16.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page10/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/20.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/22.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page10/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/24.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page10/E1/25.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page10/E1/26.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page10/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/28.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page10/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/30.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page10/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/32.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/33.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/34.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page10/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/36.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page10/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page10/E1/38.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page10/E1/39.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page10/E1/40.jpg' },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P10-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page10/E2/Key/answerKey.png",
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page10/E2/title.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row;'>
            <div style='width: 50%;'>
              <img src="img/FriendsPlus/Page10/E2/title-02.jpg" style="width: 98%">
            </div>
            <div style='width: 50%;'>
                <div>I'm <sup>1</sup> <u style='color: gray;'>from</u> Brazil.</div>
                <div><sup>2</sup> # nice to meet you!</div>
                <div>Hello, hello,</div>
                <div>Nice to <sup>3</sup> # you, too.</div>
                <div>This is my friend.</div>
                <div><sup>4</sup> # from the U.S.A.</div>
                <div>Hello, hello,</div>
                <div>How <sup>5</sup> # you today?</div>
                <div>Where <sup>6</sup> # from?</div>
                <div><sup>7</sup> # from #.</div>
                <div>Hello, hello,</div>
                <div>How are <sup>8</sup> # today?</div>
            </div>
          </div>
        `,
        answer: ["It's", "meet", "He's", "are", "are you", "I'm", "Viet Nam", "you"],
      },
    ],
  },
};

export default json;
