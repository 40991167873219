
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: { // Exercise num
    unit: "Unit 3",
    id: "WB5-V2-U3-P22-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: "img/FriendsPlus/Page22/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page22/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page22/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page22/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page22/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page22/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page22/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page22/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page22/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page22/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page22/E1/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page22/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/18.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page22/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page22/E1/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page22/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/23.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page22/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/25.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page22/E1/26.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page22/E1/27.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page22/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/29.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page22/E1/30.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/31.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page22/E1/32.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page22/E1/33.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page22/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/35.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page22/E1/36.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/37.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page22/E1/38.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page22/E1/39.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/40.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page22/E1/41.jpg' },
        { url: 'img/FriendsPlus/Page22/E1/42.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page22/E1/43.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P22-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page22/E2/Key/answerKey.png',
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E2/1.jpg'},
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page22/E2/2.jpg'},
        { url: 'img/FriendsPlus/Page22/E2/3.jpg', input: true, answer: "Can we play"},
        { url: 'img/FriendsPlus/Page22/E2/4.jpg'},
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page22/E2/5.jpg'},
        { url: 'img/FriendsPlus/Page22/E2/6.jpg', input: true, answer: "Can we watch"},
        { url: 'img/FriendsPlus/Page22/E2/7.jpg'},
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page22/E2/8.jpg'},
        { url: 'img/FriendsPlus/Page22/E2/9.jpg', input: true, answer: "Can you watch"},
        { url: 'img/FriendsPlus/Page22/E2/10.jpg'},
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page22/E2/11.jpg'},
        { url: 'img/FriendsPlus/Page22/E2/12.jpg', input: true, answer: "Can we play"},
        { url: 'img/FriendsPlus/Page22/E2/13.jpg'},
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page22/E2/14.jpg'},
        { url: 'img/FriendsPlus/Page22/E2/15.jpg', input: true, answer: "Can you play"},
        { url: 'img/FriendsPlus/Page22/E2/16.jpg'},
      ],      
    ]
  },

}

export default json;