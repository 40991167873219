import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P66-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page66/E1/Key/answerKey.png',
    // checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page66/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page66/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/3.jpg', input: true, answer: "some pastries" },
        { url: 'img/FriendsPlus/Page66/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page66/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page66/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/7.jpg', input: true, answer: "a singer" },
        { url: 'img/FriendsPlus/Page66/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/9.jpg', input: true, answer: "a cave" },
        { url: 'img/FriendsPlus/Page66/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page66/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page66/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/13.jpg', input: true, answer: "some cereal" },
        { url: 'img/FriendsPlus/Page66/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/15.jpg', input: true, answer: "a building" },
        { url: 'img/FriendsPlus/Page66/E1/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page66/E1/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page66/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/19.jpg', input: true, answer: "a cucumber" },
        { url: 'img/FriendsPlus/Page66/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/21.jpg', input: true, answer: "an ocean" },
        { url: 'img/FriendsPlus/Page66/E1/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page66/E1/23.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page66/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/25.jpg', input: true, answer: "an onion" },
        { url: 'img/FriendsPlus/Page66/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/27.jpg', input: true, answer: "a lake" },
        { url: 'img/FriendsPlus/Page66/E1/28.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: "Unit 9",
    id: "WB5-V2-U9-P66-E2",
    audio: '',
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page66/E2/Key/answerKey.png",
    inputSize: 100,
    // titleQuestion: [{ color: 'black', num: '2', title: 'Listen and write. <headphone name="21" src="img/FriendsPlus/Page66/Audio/tieude-e2-p66.mp3"></headphone>' }],
    titleImage: "img/FriendsPlus/Page66/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='margin: 20px 0;'>The swimming pool in my town is 50 years <sup>1</sup><u style='color: gray;'>&ensp;old&ensp;</u>. The pool is 30 meters <sup>2</sup># and 10 meters <sup>3</sup>#. The water is 1 meter <sup>4</sup>#. It is quite <sup>5</sup>#.</div>
          <img src="img/FriendsPlus/Page66/E2/2.jpg" />
          </div>
        `,
        answer: ['long', 'wide', 'deep', 'big'],
      },
    ]
  },
}
export default json;