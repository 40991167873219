import D1 from '../../components/ExcerciseTypes/Design/TypeIn'

const json = {

  1: { // Exercise num
    unit: 'Starter',
    id: 'WB4-S-P7-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page7/E1/Key/answerKey.png',
    isAllowSubmit: false,
    maxLength: 1,
    textAlign: 'center',
    padding: 0,
    question: [],
    questionImage: [
      [{ url: 'img/FriendsPlus/Page7/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page7/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/3.jpg', input: true, answer: 'd' },
        { url: 'img/FriendsPlus/Page7/E1/4.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page7/E1/5.jpg' }],
      [
        { url: 'img/FriendsPlus/Page7/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/7.jpg', input: true, answer: 'e' },
        { url: 'img/FriendsPlus/Page7/E1/8.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page7/E1/9.jpg' }],
      [
        { url: 'img/FriendsPlus/Page7/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/11.jpg', input: true, answer: 'g' },
        { url: 'img/FriendsPlus/Page7/E1/12.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page7/E1/13.jpg' }],
      [
        { url: 'img/FriendsPlus/Page7/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/15.jpg', input: true, answer: 'c' },
        { url: 'img/FriendsPlus/Page7/E1/16.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page7/E1/17.jpg' }],
      [
        { url: 'img/FriendsPlus/Page7/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/19.jpg', input: true, answer: 'b' },
        { url: 'img/FriendsPlus/Page7/E1/20.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page7/E1/21.jpg' }],
      [
        { url: 'img/FriendsPlus/Page7/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/23.jpg', input: true, answer: 'h' },
        { url: 'img/FriendsPlus/Page7/E1/24.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page7/E1/25.jpg' }],
      [
        { url: 'img/FriendsPlus/Page7/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/27.jpg', input: true, answer: 'f' },
        { url: 'img/FriendsPlus/Page7/E1/28.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page7/E1/29.jpg' }],
    ]
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'WB4-S-P7-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page7/E2/Key/answerKey.png',
    isAllowSubmit: true,
    question: [],
    questionImage: [
      [{ url: 'img/FriendsPlus/Page7/E2/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page7/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/3.jpg', input: true, answer: 'ten' },
        { url: 'img/FriendsPlus/Page7/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/6.jpg', input: true, answer: 'thirty-six' },
        { url: 'img/FriendsPlus/Page7/E2/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/9.jpg', input: true, answer: 'sixty-nine' },
        { url: 'img/FriendsPlus/Page7/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/12.jpg', input: true, answer: 'fifteen' },
        { url: 'img/FriendsPlus/Page7/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/15.jpg', input: true, answer: 'one hundred' },
        { url: 'img/FriendsPlus/Page7/E2/16.jpg' },
      ],
    ]
  },

  3: { // Exercise num
    unit: 'Starter',
    id: 'WB4-S-P7-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page7/E3/Key/answerKey.png',
    isAllowSubmit: true,
    checkUppercase: true,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page7/E3/1.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/3.jpg', input: true, answer: 'Forty and fifty is ninety' },
        { url: 'img/FriendsPlus/Page7/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/6.jpg', input: true, answer: 'Thirty and forty is seventy' },
        { url: 'img/FriendsPlus/Page7/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/9.jpg', input: true, answer: 'Twenty and eighty is one hundred' },
        { url: 'img/FriendsPlus/Page7/E3/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page7/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page7/E3/12.jpg', input: true, answer: 'Sixty and thirty is ninety' },
        { url: 'img/FriendsPlus/Page7/E3/13.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
}

export default json;