import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'WB4-U10-P71-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page71/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Look at the words with <i>ee</i>, <i>ea</i>, and <i>y</i>. Circle the odd-one-out.<br />Then write these words with the correct pictures.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: { width: 140, color: 'black', textAlign: 'center' },
        answers: ['happy', 'jelly', 'ice cream', 'queen'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white', margin: 10 },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          'please seat happy', //0
          'family ice_cream funny', //1
          'happy queen family', //2
          'jelly tree sheep', //3
        ],
        answers: ['2-2', '0-4', '3-0', '1-2'],
        initialValue: [],
      },
      Layout: `
        <div style='display: flex; margin-bottom: 20px;'>
          <div style='margin-right: 50px;'>
            <div><b>1</b> <span style='margin: 10px;'>green</span> <span style='margin: 10px;'>week</span> <span style='border: 2px solid gray; border-radius: 50%; margin: 10px;'>dream</span></div>
            <div><b>3</b> <input id='0' type='Circle' /></div>
            <div><b>5</b> <input id='1' type='Circle' /></div>
          </div>
          <div style='margin-left: 50px;'>
            <div><b>2</b> <input id='2' type='Circle' /></div>
            <div><b>4</b> <input id='3' type='Circle' /></div>
          </div>
        </div>
        <div style='display: flex; margin-top: 20px;'>
          <div>
            <img src="img/FriendsPlus/Page71/E1/1.jpg" />
            <div><input id='0' /></div>
          </div>
          <div style='margin: 0 10px 0 20px;'>
            <img src="img/FriendsPlus/Page71/E1/2.jpg" />
            <div><input id='1' /></div>
          </div>
          <div style='margin: 0 10px 0 10px;'>
            <img src="img/FriendsPlus/Page71/E1/3.jpg" />
            <div><input id='2' /></div>
          </div>
          <div style='margin: 0 20px 0 10px;'>
            <img src="img/FriendsPlus/Page71/E1/4.jpg" />
            <div><input id='3' /></div>
          </div>
          <div>
            <img src="img/FriendsPlus/Page71/E1/5.jpg" />
            <div style='text-align: center;'><u style='color: gray;'>&ensp;dream&ensp;</u></div>
          </div>
        </div>
      `,
    },
  },
  2: { // Exercise num
    unit: "Unit 10",
    id: "WB5-V2-U10-P71-E2",
    audio: "img/FriendsPlus/Page71/Audio/audio-e2-p71.mp3",
    video: "",
    component: T6,
    inputSize: 110,
    exerciseKey: "img/FriendsPlus/Page71/E2/Key/answerKey.png",
    titleQuestion: [{ color: 'black', num: '2', title: 'Listen and write.  <headphone name="23" src="img/FriendsPlus/Page71/Audio/tieude-e2-p71.mp3"></headphone>' }],
    // titleImage: "img/FriendsPlus/Page64/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page71/E2/1.jpg" />
            </div>
            <div style='margin-left: 20px;'>
              <div>In my <sup>1</sup><u style='color: gray;'>&ensp;dream&ensp;</u>,</div>
              <div>I am a <sup>2</sup>#.</div>
              <div>I eat <sup>3</sup># <sup>4</sup>#,</div>
              <div>and <sup>5</sup>#.</div>
              <div>I’m very <sup>6</sup>#,</div>
              <div>In my dream.</div>
            </div>
          </div>
        `,
        answer: ['queen', 'ice', 'cream', 'jelly', 'happy'],
      },
    ]
  },
  3: { // Exercise num
    unit: "Unit 10",
    id: "WB5-V2-U10-P71-E3",
    audio: "",
    video: "",
    component: T6,
    inputSize: 110,
    exerciseKey: "img/FriendsPlus/Page71/E3/Key/answerKey.png",
    titleQuestion: [{ color: 'black', num: '3', title: 'Write.' }],
    // titleImage: "img/FriendsPlus/Page64/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page71/E3/1.jpg" />
          <div style='display: flex;'>
            <div style='margin-right: 10px;'>
              <div><b>1</b> My mom is sitting under a <u style='color: gray;'>&ensp;tree&ensp;</u>.</div>
              <div><b>3</b> Can you get me a melon, #?</div>
              <div><b>5</b> There’s a # on the path.</div>
            </div>
            <div style='margin-left: 10px;'>
              <div><b>2</b> There are five people in my #.</div>
              <div><b>4</b> My brother is #!</div>
            </div>
          </div>
        `,
        answer: ['please', 'sheep', 'family', 'happy'],
      },
    ]
  },
}
export default json;