import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "WB5-V2-U5-P38-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page38/E1/Key/answerKey.png",
    isAllowSubmit: false,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page38/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page38/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page38/E1/3.jpg', input: true, answer: "sorry" },
        { url: 'img/FriendsPlus/Page38/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page38/E1/5.jpg', input: true, answer: "free" },
        { url: 'img/FriendsPlus/Page38/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page38/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page38/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page38/E1/9.jpg', input: true, answer: "funny" },
        { url: 'img/FriendsPlus/Page38/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page38/E1/11.jpg', input: true, answer: "scared" },
        { url: 'img/FriendsPlus/Page38/E1/12.jpg' },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "WB5-V2-U5-P38-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page38/E2/Key/answerKey.png",
    isAllowSubmit: false,
    maxLength: 1,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page38/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page38/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/3.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page38/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page38/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page38/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/7.jpg', input: true, answer: "6" },
        { url: 'img/FriendsPlus/Page38/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page38/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page38/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/11.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page38/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page38/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page38/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/15.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page38/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page38/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page38/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/19.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page38/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page38/E2/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page38/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/23.jpg', input: true, answer: "8" },
        { url: 'img/FriendsPlus/Page38/E2/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page38/E2/25.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page38/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/27.jpg', input: true, answer: "7" },
        { url: 'img/FriendsPlus/Page38/E2/28.jpg' },
      ],
    ],
  },
};

export default json;
