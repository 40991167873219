import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P91-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page91/E1/Key/answerKey.png",
    inputSize: 300,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page91/E1/1.jpg">
          <div style='display:flex;margin-left:50px;'>
            <div>
              <div><b>1</b> I’m surfing..</div>
              <div><b>2</b> She’s snorkeling.</div>
              <div><b>3</b> They’re eating.</div>
              <div><b>4</b> We aren’t sleeping.</div>
              <div><b>5</b> You aren’t windsurfing.</div>
            </div>
            <div>
              <div><u style='color: gray;'>&ensp;I’m not sailing.&ensp;</u> (not sail)</div>
              <div># (not waterski)</div>
              <div># (not play)</div>
              <div style='display:flex;'><u style='color: gray;margin-right:-4px;'>&ensp;We’re&ensp;</u># (read)</div>
              <div># (surf)</div>
            </div>
          </div>
        `,
        answer: ["She isn't waterskiing.", "They aren't playing.", "reading.", "You're surfing."],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Grammar Time',
    id: 'WB4-GT-P91-E2',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page91/E2/Key/answerKey.png',
    checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page91/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page91/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page91/E2/3.jpg', input: true, answer: "Is" },
        { url: 'img/FriendsPlus/Page91/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page91/E2/5.jpg', input: true, answer: "Yes, she is." },
        { url: 'img/FriendsPlus/Page91/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page91/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page91/E2/8.jpg', input: true, answer: "Am" },
        { url: 'img/FriendsPlus/Page91/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page91/E2/10.jpg', input: true, answer: "Yes, I am./Yes, you are." },
        { url: 'img/FriendsPlus/Page91/E2/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page91/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page91/E2/13.jpg', input: true, answer: "Is" },
        { url: 'img/FriendsPlus/Page91/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page91/E2/15.jpg', input: true, answer: "No, he isn't." },
        { url: 'img/FriendsPlus/Page91/E2/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page91/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page91/E2/18.jpg', input: true, answer: "Are" },
        { url: 'img/FriendsPlus/Page91/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page91/E2/20.jpg', input: true, answer: "No, I'm not./No, we're not." },
        { url: 'img/FriendsPlus/Page91/E2/21.jpg' },
      ],
    ]
  },
}
export default json;