import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P23-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page23/E1/Key/answerKey.png',
    isAllowSubmit: false,
    maxLength: 1,
    textAlign: 'center',
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page23/E1/1.jpg'},
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page23/E1/2.jpg'},
        { url: 'img/FriendsPlus/Page23/E1/3.jpg', input: true, answer: "t"},
        { url: 'img/FriendsPlus/Page23/E1/4.jpg'},
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page23/E1/5.jpg'},
        { url: 'img/FriendsPlus/Page23/E1/6.jpg', input: true, answer: "a"},
        { url: 'img/FriendsPlus/Page23/E1/7.jpg'},
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page23/E1/8.jpg'},
        { url: 'img/FriendsPlus/Page23/E1/9.jpg', input: true, answer: "s"},
        { url: 'img/FriendsPlus/Page23/E1/10.jpg', input: true, answer: "c"},
        { url: 'img/FriendsPlus/Page23/E1/11.jpg', input: true, answer: "a"},
        { url: 'img/FriendsPlus/Page23/E1/12.jpg', input: true, answer: "r"},
        { url: 'img/FriendsPlus/Page23/E1/13.jpg', input: true, answer: "f"},
        { url: 'img/FriendsPlus/Page23/E1/14.jpg'},
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page23/E1/15.jpg'},
        { url: 'img/FriendsPlus/Page23/E1/16.jpg', input: true, answer: "a"},
        { url: 'img/FriendsPlus/Page23/E1/17.jpg'},
        { url: 'img/FriendsPlus/Page23/E1/18.jpg', input: true, answer: "a"},
        { url: 'img/FriendsPlus/Page23/E1/19.jpg', input: true, answer: "r"},
        { url: 'img/FriendsPlus/Page23/E1/20.jpg', input: true, answer: "m"},
        { url: 'img/FriendsPlus/Page23/E1/21.jpg'},
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page23/E1/22.jpg'},
        { url: 'img/FriendsPlus/Page23/E1/23.jpg', input: true, answer: "r"},
        { url: 'img/FriendsPlus/Page23/E1/24.jpg'},
        { url: 'img/FriendsPlus/Page23/E1/25.jpg', input: true, answer: "r"},
        { url: 'img/FriendsPlus/Page23/E1/26.jpg'},
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page23/E1/27.jpg'},
        { url: 'img/FriendsPlus/Page23/E1/28.jpg', input: true, answer: "m"},
        { url: 'img/FriendsPlus/Page23/E1/29.jpg'},
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page23/E1/30.jpg'},
      ],      
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P23-E2',
    audio: 'img/FriendsPlus/Page23/Audio/audio-e2-p23.mp3',
    video: '',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page23/E2/Key/answerKey.png",
    inputSize: 150,
    titleImage: "",
    titleQuestion: [{ color: 'black', num: '2', title: 'Listen and write. <headphone name="07" src="img/FriendsPlus/Page23/Audio/tieude-e2-p23.mp3"></headphone>'}],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row;'>
            <div style='width: 50%;'>
              <img src="img/FriendsPlus/Page23/E2/title.jpg" style="width: 98%">
            </div>
            <div style='width: 50%;'>
                <div>I'm in the <sup>1</sup> <u style='color: gray;'>car</u>,</div>
                <div>After playing in the <sup>2</sup> #.</div>
                <div>I have an orange <sup>3</sup> #.</div>
                <div>I have a toy <sup>4</sup> #.</div>
            </div>
          </div>
        `,
        answer: [ "park", "scarf", "shark" ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P23-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page23/E3/Key/answerKey.png",
    inputSize: 150,
    // titleImage: "img/FriendsPlus/Page23/E3/title.jpg",
    titleQuestion: [{ color: 'black', num: '3', title: 'Count and write.'}],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row;'>
            <div style='width: 50%;'>
                <div>1&nbsp;&nbsp;There's one <u style='color: gray;'>arm</u>.</div>
                <div>2&nbsp;&nbsp;There are two #.</div>
                <div>3&nbsp;&nbsp;There are three #.</div>
                <div>4&nbsp;&nbsp;There are four #.</div>
                <div>5&nbsp;&nbsp;There are five #.</div>
            </div>
            <div style='width: 50%;'>
              <img src="img/FriendsPlus/Page23/E3/title.jpg" style="width: 98%">
            </div>
          </div>
        `,
        answer: [ "parks", "stars", "cars", "sharks" ],
      },
    ],
  },
}

export default json;