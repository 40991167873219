import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: "Unit 8",
    id: "WB5-V2-U8-P55-E1",
    audio: 'img/FriendsPlus/Page55/Audio/audio-e1-p55.mp3',
    video: '',
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page55/E1/Key/answerKey.png",
    titleQuestion: [{ num: '1', title: 'Listen and draw lines. <headphone name="16" src="img/FriendsPlus/Page55/Audio/tieude-e1-p55.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          { boxMatchStyle: { position: 'absolute', top: '25px', left: '495px', width: 121, height: 41, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '25px', left: '717px', width: 121, height: 41, border: '1px dashed black' }, },

          { boxMatchStyle: { position: 'absolute', top: '140px', left: '201px', width: 114, height: 99, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '140px', left: '319px', width: 114, height: 99, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '132px', left: '553px', width: 114, height: 99, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '138px', left: '670px', width: 114, height: 99, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '150px', left: '785px', width: 114, height: 99, border: '1px dashed black' }, },

          { boxMatchStyle: { position: 'absolute', top: '382px', left: '191px', width: 114, height: 43, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '380px', left: '332px', width: 101, height: 53, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '385px', left: '428px', width: 88, height: 57, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '364px', left: '536px', width: 114, height: 44, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '381px', left: '656px', width: 114, height: 41, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '387px', left: '782px', width: 126, height: 50, border: '1px dashed black' }, },

          { boxMatchStyle: { position: 'absolute', top: '484px', left: '273px', width: 121, height: 41, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '484px', left: '495px', width: 121, height: 41, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '484px', left: '717px', width: 121, height: 41, border: '1px dashed black' }, },
        ],
        answers: ["0-4", "1-3", "11-13", "14-7", "12-15"],
        initialValue: [],
      },
      Layout: `
        <img src='img/FriendsPlus/Page55/E1/4.jpg' />
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />

        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />

        <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
        <input id='11' type= 'boxMatch' />
        <input id='12' type= 'boxMatch' />
        
        <input id='13' type= 'boxMatch' />
        <input id='14' type= 'boxMatch' />
        <input id='15' type= 'boxMatch' />
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB4-U8-P55-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: "img/FriendsPlus/Page55/E2/Key/answerKey.png",
    checkUppercase: true,
    isAllowSubmit: false,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page55/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page55/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/3.jpg', input: true, answer: "some salad" },
        { url: 'img/FriendsPlus/Page55/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page55/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page55/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/7.jpg', input: true, answer: "No, thank you." },
        { url: 'img/FriendsPlus/Page55/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page55/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page55/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/11.jpg', input: true, answer: "an ice cream" },
        { url: 'img/FriendsPlus/Page55/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page55/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page55/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/15.jpg', input: true, answer: "No, thank you." },
        { url: 'img/FriendsPlus/Page55/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page55/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page55/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/19.jpg', input: true, answer: "a banana" },
        { url: 'img/FriendsPlus/Page55/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page55/E2/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page55/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page55/E2/23.jpg', input: true, answer: "Yes, please." },
        { url: 'img/FriendsPlus/Page55/E2/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page55/E2/25.jpg' },
      ],
    ],
  },
}

export default json;