import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P17-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 10,
    exerciseKey: "img/FriendsPlus/Page17/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      [{ url: "img/FriendsPlus/Page17/E1/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page17/E1/2.jpg" },
        { url: "img/FriendsPlus/Page17/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/4.jpg" },
        { url: "img/FriendsPlus/Page17/E1/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page17/E1/6.jpg" },
        { url: "img/FriendsPlus/Page17/E1/7.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/8.jpg" },
        { url: "img/FriendsPlus/Page17/E1/9.jpg", input: 2 },
        { url: "img/FriendsPlus/Page17/E1/10.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page17/E1/11.jpg" },
        { url: "img/FriendsPlus/Page17/E1/12.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/13.jpg" },
        { url: "img/FriendsPlus/Page17/E1/14.jpg", input: 3 },
        { url: "img/FriendsPlus/Page17/E1/15.jpg" },
        { url: "img/FriendsPlus/Page17/E1/16.jpg", input: 4 },
        { url: "img/FriendsPlus/Page17/E1/17.jpg" },
        { url: "img/FriendsPlus/Page17/E1/18.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/19.jpg" },
        { url: "img/FriendsPlus/Page17/E1/20.jpg", input: 5 },
        { url: "img/FriendsPlus/Page17/E1/21.jpg" },
        { url: "img/FriendsPlus/Page17/E1/22.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/23.jpg" },
        { url: "img/FriendsPlus/Page17/E1/24.jpg", input: 6 },
        { url: "img/FriendsPlus/Page17/E1/25.jpg" },
        { url: "img/FriendsPlus/Page17/E1/26.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/27.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page17/E1/28.jpg" },
        { url: "img/FriendsPlus/Page17/E1/29.jpg", input: 7 },
        { url: "img/FriendsPlus/Page17/E1/30.jpg" },
        { url: "img/FriendsPlus/Page17/E1/31.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/32.jpg" },
        { url: "img/FriendsPlus/Page17/E1/33.jpg", input: 8, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/34.jpg" },
        { url: "img/FriendsPlus/Page17/E1/35.jpg", input: 8 },
        { url: "img/FriendsPlus/Page17/E1/36.jpg" },
        { url: "img/FriendsPlus/Page17/E1/37.jpg", input: 9, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/38.jpg" },
        { url: "img/FriendsPlus/Page17/E1/39.jpg", input: 9 },
        { url: "img/FriendsPlus/Page17/E1/40.jpg" },
        { url: "img/FriendsPlus/Page17/E1/41.jpg", input: 10 },
        { url: "img/FriendsPlus/Page17/E1/42.jpg" },
        { url: "img/FriendsPlus/Page17/E1/43.jpg", input: 10, isCorrect: true },
        { url: "img/FriendsPlus/Page17/E1/44.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P17-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page17/E2/Key/answerKey.png",
    isAllowSubmit: true,
    maxLength: 1,
    textAlign: "center",
    padding: 0,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page17/E2/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page17/E2/2.jpg" },
        { url: "img/FriendsPlus/Page17/E2/3.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page17/E2/4.jpg" },
        { url: "img/FriendsPlus/Page17/E2/5.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page17/E2/6.jpg" },
        { url: "img/FriendsPlus/Page17/E2/7.jpg", input: true, answer: "i" },
        { url: "img/FriendsPlus/Page17/E2/8.jpg" },
        { url: "img/FriendsPlus/Page17/E2/9.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page17/E2/10.jpg" },
        { url: "img/FriendsPlus/Page17/E2/11.jpg", input: true, answer: "u" },
        { url: "img/FriendsPlus/Page17/E2/12.jpg" },
        { url: "img/FriendsPlus/Page17/E2/13.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page17/E2/14.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page17/E2/15.jpg" }],
      [
        // Column4
        { url: "img/FriendsPlus/Page17/E2/16.jpg" },
        { url: "img/FriendsPlus/Page17/E2/17.jpg", input: true, answer: "u" },
        { url: "img/FriendsPlus/Page17/E2/18.jpg" },
        { url: "img/FriendsPlus/Page17/E2/19.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page17/E2/20.jpg" },
        { url: "img/FriendsPlus/Page17/E2/21.jpg", input: true, answer: "i" },
        { url: "img/FriendsPlus/Page17/E2/22.jpg" },
        { url: "img/FriendsPlus/Page17/E2/23.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page17/E2/24.jpg" },
        { url: "img/FriendsPlus/Page17/E2/25.jpg", input: true, answer: "i" },
        { url: "img/FriendsPlus/Page17/E2/26.jpg" },
        { url: "img/FriendsPlus/Page17/E2/27.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page17/E2/28.jpg" },
        { url: "img/FriendsPlus/Page17/E2/29.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page17/E2/30.jpg" },
        { url: "img/FriendsPlus/Page17/E2/31.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page17/E2/32.jpg" },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P17-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page17/E3/Key/answerKey.png",
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page17/E3/title.jpg",
    // titleQuestion: [{ color: '#000000', num: '3', title: 'Listen and write. <headphone name="05" src="img/FriendsPlus/Page16/Audio/tieude-e3-p16.mp3"></headphone>'}],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row;'>
            <div style='flex: 1;'>
              <img src="img/FriendsPlus/Page17/E3/title-02.jpg">
            </div>
            <div style='flex: 1;'>
                <div>Let's play together, me and you.</div>
                <div>I have a <sup>1</sup> <u style='color: gray;'>kites</u>,</div>
                <div>And a jump <sup>2</sup> # too.</div>
                <div>I have a <sup>3</sup> #,</div>
                <div>And a toy from <sup>4</sup> #.</div>
                <div>Look. It has a purple <sup>5</sup> #.</div>
            </div>
          </div>
        `,
        answer: ["rope", "cube", "space", "face"],
      },
    ],
  },
};

export default json;
