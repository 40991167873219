import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'WB4-U11-P78-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: "img/FriendsPlus/Page78/E1/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page78/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page78/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page78/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page78/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/6.jpg', input: 1, },
        { url: 'img/FriendsPlus/Page78/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page78/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page78/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page78/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page78/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page78/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page78/E1/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page78/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/18.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page78/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page78/E1/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page78/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/23.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page78/E1/24.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page78/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/26.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page78/E1/27.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page78/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/29.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page78/E1/30.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page78/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/32.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page78/E1/33.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page78/E1/34.jpg' },
        { url: 'img/FriendsPlus/Page78/E1/35.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page78/E1/36.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page78/E1/37.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: "Unit 11",
    id: "WB5-V2-U11-P78-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page78/E2/Key/answerKey.png",
    inputSize: 50,
    titleQuestion: [{ color: 'black', num: '2', title: 'Read the text in the Student Book. Write T (true) or F (false).' }],
    // titleImage: "img/FriendsPlus/Page78/E2/1.jpg",
    // checkUppercase: true,
    maxLength: 1,
    textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div>
            <div><b>1</b>&ensp;Oanh’s hometown is Nha Trang.</div>
            <div><b>2</b>&ensp;Her cousins travelled from Ho Chi Minh City by plane.</div>
            <div><b>3</b>&ensp;Before Tet, Oanh’s family cleaned the house.</div>
            <div><b>4</b>&ensp;Oanh’s grandparents gave her and her brother new toys.</div>
            <div><b>5</b>&ensp;On the second day of Tet, Oanh walked to the top of a hill.</div>
            <div><b>6</b>&ensp;Oanh gave her teacher flowers and fruit.</div>
          </div>
          <div>
            <div><u style='color: gray;'>&ensp;F&ensp;</u></div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
        </div>
        `,
        answer: ['T', 'T', 'F', 'F', 'T'],
      },
    ]
  },
}
export default json;