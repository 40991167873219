import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'WB4-U6-P43-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page43/E1/Key/answerKey.png',
    checkUppercase: true,
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page43/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page43/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/3.jpg', input: true, answer: "ow" },
        { url: 'img/FriendsPlus/Page43/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/5.jpg', input: true, answer: "ow" },
        { url: 'img/FriendsPlus/Page43/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/7.jpg', input: true, answer: "S" },
        { url: 'img/FriendsPlus/Page43/E1/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page43/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page43/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/11.jpg', input: true, answer: "ou" },
        { url: 'img/FriendsPlus/Page43/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/13.jpg', input: true, answer: "ow" },
        { url: 'img/FriendsPlus/Page43/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/15.jpg', input: true, answer: "D" },
        { url: 'img/FriendsPlus/Page43/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/17.jpg', input: true, answer: "ou" },
        { url: 'img/FriendsPlus/Page43/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/19.jpg', input: true, answer: "ou" },
        { url: 'img/FriendsPlus/Page43/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/21.jpg', input: true, answer: "S" },
        { url: 'img/FriendsPlus/Page43/E1/22.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page43/E1/23.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page43/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/25.jpg', input: true, answer: "ow" },
        { url: 'img/FriendsPlus/Page43/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/27.jpg', input: true, answer: "ou" },
        { url: 'img/FriendsPlus/Page43/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/29.jpg', input: true, answer: "D" },
        { url: 'img/FriendsPlus/Page43/E1/30.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'WB4-U6-P43-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page43/E2/Key/answerKey.png',
    checkUppercase: true,
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page43/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page43/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/3.jpg', input: true, answer: "flowers" },
        { url: 'img/FriendsPlus/Page43/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page43/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page43/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/7.jpg', input: true, answer: "round" },
        { url: 'img/FriendsPlus/Page43/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page43/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page43/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/11.jpg', input: true, answer: "house" },
        { url: 'img/FriendsPlus/Page43/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page43/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page43/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/15.jpg', input: true, answer: "mouse" },
        { url: 'img/FriendsPlus/Page43/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page43/E2/17.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: "Unit 6",
    id: "WB5-V2-U6-P43-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page43/E3/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [{ color: 'black', num: '3', title: 'Write.'}],
    titleImage: "",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row; align-items: center;'>
            <div style='flex: 1;margin-right: 10px;'>
              <img src="img/FriendsPlus/Page43/E3/1.jpg">
            </div>
            <div style='flex: 2;margin-left: 10px;'>
              <div>1&ensp;The boy has a <u style='color: gray;'>&ensp;round&ensp;</u> balloon.</div>
              <div>2&ensp;Please come to my #.</div>
              <div>3&ensp;The # is wearing a hat.</div>
              <div>4&ensp;This is a beautiful #.</div>
              <div>5&ensp;A # is bigger than a monkey.</div>
              <div>6&ensp;A # is smaller than a horse.</div>
            </div>
          </div>
        `,
        answer: ["house", "clown", "flower", 'cow', 'mouse'],
      },
    ],
  },
}

export default json;