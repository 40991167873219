import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P88-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page88/E1/Key/answerKey.png",
    inputSize: 120,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page88/E1/1.jpg">
          <div style='display: flex;margin-left:50px;'>
            <div>
              <div><b>1</b> Where is she from?</div>
              <div><b>2</b> Where are Tom and Jane from?</div>
              <div><b>3</b> Where are we from?</div>
              <div><b>4</b> Where are you from?</div>
              <div><b>5</b> Where # from?</div>
              <div><b>6</b> Where # from?</div>
              <div><b>7</b> Where # from?</div>
              <div><b>8</b> Where # from?</div>
            </div>
            <div style='margin-left: 20px;'>
              <div><u style='color: gray;'>&ensp;She’s&ensp;</u> from the U.S.A.</div>
              <div># from Australia.</div>
              <div># from Brazil.</div>
              <div># from #</div>
              <div>He’s from Mexico.</div>
              <div>We’re from Thailand.</div>
              <div>They’re from Korea.</div>
              <div>She’s from Viet Nam.</div>
            </div>
          </div>
        `,
        answer: ['is he', 'are you', 'are they', 'is she', "They're", "We're", "We're", ''],
      },
    ]
  },
  2: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P88-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page88/E2/Key/answerKey.png",
    inputSize: 300,
    // titleQuestion: [{ color: 'black', num: '2', title: 'Write a sentence.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page88/E2/1.jpg">
          <img src="img/FriendsPlus/Page88/E2/2.jpg">
          <div style='display: flex;margin-left:50px;'>
            <div>
              <div><b>1</b> Ellie / the U.S.A.</div>
              <div><b>2</b> Carl and Jack / Australia</div>
              <div><b>3</b> Tam / Viet Nam</div>
              <div><b>4</b> I / Mexico</div>
            </div>
            <div style='margin-left: 20px;'>
              <div><u style='color: gray;'>&ensp;She’s from the U.S.A.&ensp;</u></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
        `,
        answer: ["They're from Australia.", "She's from Viet Nam.", "I'm from Mexico."],
      },
    ]
  },
}
export default json;