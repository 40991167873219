import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB4-U4-P31-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page31/E1/Key/answerKey.png',
    isAllowSubmit: false,
    textAlign: 'center',
    maxLength: 1,
    question: [],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page31/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/3.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page31/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/5.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page31/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/7.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page31/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/9.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page31/E1/10.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB4-U4-P31-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page31/E2/Key/answerKey.png',
    isAllowSubmit: false,
    question: [],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page31/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/3.jpg', input: true, answer: "tall/small" },
        { url: 'img/FriendsPlus/Page31/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page31/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/6.jpg', input: true, answer: "small/tall" },
        { url: 'img/FriendsPlus/Page31/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page31/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page31/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/10.jpg', input: true, answer: "ball" },
        { url: 'img/FriendsPlus/Page31/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page31/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page31/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page31/E2/14.jpg', input: true, answer: "all" },
        { url: 'img/FriendsPlus/Page31/E2/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page31/E2/16.jpg' },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 4",
    id: "WB5-V2-U4-P31-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page31/E3/Key/answerKey.png",
    inputSize: 150,
    titleImage: "",
    titleQuestion: [{ color: 'black', num: '3', title: 'Write words with all.'}],
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='width: 60%;'>
                <div>1&nbsp;&nbsp;We go to school <u style='color: gray;'>all</u> day.</div>
                <div>2&nbsp;&nbsp;We buy clothes in the # .</div>
                <div>3&nbsp;&nbsp;Giraffes are very # animals.</div>
                <div>4&nbsp;&nbsp;Let’s play with a # in the park.</div>
                <div>5&nbsp;&nbsp;There’s a cat on the # .</div>
                <div>6&nbsp;&nbsp;Australia isn’t a # country.</div>
            </div>
            <div style='width: 40%;'>
              <img src="img/FriendsPlus/Page31/E3/title.jpg" style="width: 98%">
            </div>
          </div>
        `,
        answer: ["mall", "tall", "ball", "wall", "small"],
      },
    ],
  },
}

export default json;