import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';

const json = {

  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB4-U4-P28-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page28/E1/Key/answerKey.png',
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page28/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page28/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/3.jpg', input: true, answer: "windsurf" },
        { url: 'img/FriendsPlus/Page28/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/5.jpg', input: true, answer: "sail" },
        { url: 'img/FriendsPlus/Page28/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page28/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page28/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/9.jpg', input: true, answer: "beach" },
        { url: 'img/FriendsPlus/Page28/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/11.jpg', input: true, answer: "waterski" },
        { url: 'img/FriendsPlus/Page28/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page28/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page28/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/15.jpg', input: true, answer: "snorkel" },
        { url: 'img/FriendsPlus/Page28/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/17.jpg', input: true, answer: "kayak" },
        { url: 'img/FriendsPlus/Page28/E1/18.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB4-U4-P28-E2',
    audio: 'img/FriendsPlus/Page28/Audio/audio-e2-p28.mp3',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page28/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'What are Ben and his family doing at the beach? Listen and match. <headphone name="08" src="img/FriendsPlus/Page28/Audio/tieude-e2-p28.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          { boxMatchStyle: {position: 'relative', transform: 'none', marginBottom: 10 }, children: "<img src='img/FriendsPlus/Page28/E2/1.jpg' />" },
          { boxMatchStyle: {position: 'relative', transform: 'none', marginBottom: 10 }, children: "<img src='img/FriendsPlus/Page28/E2/3.jpg' />" },
          { boxMatchStyle: {position: 'relative', transform: 'none', marginBottom: 10 }, children: "<img src='img/FriendsPlus/Page28/E2/5.jpg' />" },

          { boxMatchStyle: {position: 'relative', transform: 'none', border: 'dashed', margin: 20, padding: 10, textAlign: 'center', color: 'black' }, children: '<div>Ben</div>' },
          { boxMatchStyle: {position: 'relative', transform: 'none', border: 'dashed', margin: 20, padding: 10, textAlign: 'center', color: 'black' }, children: '<div>Mom</div>' },
          { boxMatchStyle: {position: 'relative', transform: 'none', border: 'dashed', margin: 20, padding: 10, textAlign: 'center', color: 'black' }, children: '<div>Dad</div>' },
          { boxMatchStyle: {position: 'relative', transform: 'none', border: 'dashed', margin: 20, padding: 10, textAlign: 'center', color: 'black' }, children: '<div>Henry</div>' },
          { boxMatchStyle: {position: 'relative', transform: 'none', border: 'dashed', margin: 20, padding: 10, textAlign: 'center', color: 'black' }, children: '<div>Tina</div>' },

          { boxMatchStyle: {position: 'relative', transform: 'none', marginBottom: 10 }, children: "<img src='img/FriendsPlus/Page28/E2/2.jpg' />" },
          { boxMatchStyle: {position: 'relative', transform: 'none', marginBottom: 10 }, children: "<img src='img/FriendsPlus/Page28/E2/4.jpg' />" },
        ],
        answers: ['1-3', '5-8', '0-4', '6-9', '2-7'],
        initialValue: ['1-3'],
      },
      Layout: `
          <div style='display: flex;flex-direction: row;justify-content: space-between;width: 800px;'>
            <div style='display: flex;flex-direction: column;'>
              <input id='0' type= 'boxMatch' />
              <input id='1' type= 'boxMatch' />
              <input id='2' type= 'boxMatch' />
            </div>
            <div style='display: flex;flex-direction: column;'>
              <input id='3' type= 'boxMatch' />
              <input id='4' type= 'boxMatch' />
              <input id='5' type= 'boxMatch' />
              <input id='6' type= 'boxMatch' />
              <input id='7' type= 'boxMatch' />
            </div>
            <div style='display: flex;align-items: center;'>
              <div style='display: flex;flex-direction: column;'>
                <input id='8' type= 'boxMatch' />
                <input id='9' type= 'boxMatch' />
               </div>
            </div>
          </div>
      `,
    },
  },
}

export default json;