import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P9-E1",
    audio: "img/FriendsPlus/Page9/Audio/audio-e1-p9.mp3",
    video: "",
    component: DesignUnderLine,
    totalInput: 6,
    exerciseKey: "img/FriendsPlus/Page9/E1/Key/answerKey.png",
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page9/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page9/Audio/tieude-e1-p9.mp3' },
        { url: 'img/FriendsPlus/Page9/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page9/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page9/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page9/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page9/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page9/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/10.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page9/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page9/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page9/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/14.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page9/E1/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page9/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page9/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page9/E1/19.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page9/E1/21.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page9/E1/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page9/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/24.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page9/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/26.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page9/E1/27.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page9/E1/28.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page9/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/30.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page9/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/32.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page9/E1/33.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page9/E1/34.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page9/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/36.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page9/E1/37.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/38.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page9/E1/39.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page9/E1/40.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page9/E1/41.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/42.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page9/E1/43.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/44.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page9/E1/45.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page9/E1/46.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page9/E1/47.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/48.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page9/E1/49.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/50.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page9/E1/51.jpg' },
      ],
      [
        // Column20
        { url: 'img/FriendsPlus/Page9/E1/52.jpg' },
      ],
      [
        // Column21
        { url: 'img/FriendsPlus/Page9/E1/53.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/54.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page9/E1/55.jpg' },
      ],
      [
        // Column22
        { url: 'img/FriendsPlus/Page9/E1/56.jpg' },
      ],
      [
        // Column23
        { url: 'img/FriendsPlus/Page9/E1/57.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/58.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page9/E1/59.jpg' },
      ],
      [
        // Column24
        { url: 'img/FriendsPlus/Page9/E1/60.jpg' },
      ],
      [
        // Column25
        { url: 'img/FriendsPlus/Page9/E1/61.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/62.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page9/E1/63.jpg' },
      ],
      [
        // Column26
        { url: 'img/FriendsPlus/Page9/E1/64.jpg' },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P9-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page9/E2/Key/answerKey.png",
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page9/E2/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page9/E2/2.jpg" },
        { url: "img/FriendsPlus/Page9/E2/3.jpg", input: true, answer: "He's from Viet Nam." },
        { url: "img/FriendsPlus/Page9/E2/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page9/E2/5.jpg" }],
      [
        // Column4
        { url: "img/FriendsPlus/Page9/E2/6.jpg" },
        { url: "img/FriendsPlus/Page9/E2/7.jpg", input: true, answer: "We're from Brazil." },
        { url: "img/FriendsPlus/Page9/E2/8.jpg" },
        { url: "img/FriendsPlus/Page9/E2/9.jpg", input: true, answer: "She's from Mexico." },
        { url: "img/FriendsPlus/Page9/E2/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page9/E2/11.jpg" }],
    ],
  },
};

export default json;
