import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'WB4-U12-P83-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page83/E1/Key/answerKey.png',
    titleQuestion: [{ color: 'black', num: '1', title: 'Say the words with <i>ow, oa,</i> and <i>o_e</i>. Circle the odd-one-out. Write the word.' }],
    question: {
      Write: {
        checkUppercase: true,
        isHiddenCheck: true,
        inputStyle: { width: 100, color: 'black' },
        answers: ['nose', 'elbow', 'snow'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { borderRadius: '50%', color: 'transparent', width: 120, height: 91, fontSize: 0 },
        selectWordStyle: { border: 'solid 5px', borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          'nose soap coat', //0
          'soap elbow nose', //1
          'coat soap snow', //2
        ],
        answers: ['0-0', '1-2', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex;'>
          <div style='position:relative;flex:2;'>
            <img src='img/FriendsPlus/Page83/E1/1.jpg' />
            <div style='position: absolute; top: 164px ; width: 464px ; display: flex; justify-content: space-between; left: 55px ;'><input id='0' type='Circle' /></div>
            <div style='position: absolute; top: 265px ; width: 464px ; display: flex; justify-content: space-between; left: 55px ;'><input id='1' type='Circle' /></div>
            <div style='position: absolute; top: 374px ; width: 464px ; display: flex; justify-content: space-between; left: 55px ;'><input id='2' type='Circle' /></div>
          </div>
          <div style='margin-left: 20px;line-height:100px;margin-top:50px;flex:1;'>
            <div> <u>&ensp;soap&ensp;</u></div>
            <div><input id='0' /></div>
            <div><input id='1' /></div>
            <div><input id='2' /></div>
          </div>
        </div>
      `,
    },
  },
  2: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P83-E2",
    audio: "img/FriendsPlus/Page83/Audio/audio-e2-p83.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page83/E2/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [{ color: 'black', num: '2', title: 'Listen and write. <headphone name="26" src="img/FriendsPlus/Page83/Audio/tieude-e2-p83.mp3"></headphone>' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page83/E2/1.jpg">
            </div>
            <div style='margin-left: 20px;'>
              <div>I put on my <sup>1</sup><u style='color: gray;'>&ensp;coat&ensp;</u>,</div>
              <div>And go out in the <sup>2</sup>#.</div>
              <div>There is snow on my <sup>3</sup>#,</div>
              <div>And on my <sup>4</sup>#.</div>
            </div>
          </div>
        `,
        answer: ['snow', 'nose', 'elbow'],
      },
    ]
  },
  3: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P83-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page83/E3/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [{ color: 'black', num: '3', title: 'Write.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page83/E3/1.jpg">
          <div style='margin-left:50px;'>
            <div><b>1</b> I wash my hands with <u style='color: gray;'>&ensp;soap&ensp;</u> and water.</div>
            <div><b>2</b> In winter, I put on my warm #.</div>
            <div><b>3</b> I have brown eyes and a small #.</div>
            <div><b>4</b> My # is in the middle of my arm.</div>
            <div><b>5</b> A # is hard.</div>
          </div>
        `,
        answer: ['coat', 'nose', 'elbow', 'stone'],
      },
    ]
  },
}
export default json;