import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P85-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page85/E1/Key/answerKey.png",
    inputSize: 80,
    titleQuestion: [{ color: 'black', num: '1', title: 'Write <i>and</i> or <i>but</i>.' }],
    // titleImage: "img/FriendsPlus/Page85/E2/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    maxLength: 3,
    questionImage: [],
    questions: [
      {
        title: `
          <div>
            <div><b>1</b> My uncle is old, <u style='color: gray;'>&ensp;but&ensp;</u> he’s handsome.</div>
            <div><b>2</b> My aunt is generous # relaxed.</div>
            <div><b>3</b> My brother is mean, # he’s cheerful.</div>
            <div><b>4</b> My mom is pretty, # she isn’t tall.</div>
          </div>
        `,
        answer: ['and', 'but', 'but'],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'WB4-U12-P85-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page85/E2/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page85/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page85/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page85/E2/4.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page85/E2/5.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page85/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/7.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page85/E2/8.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page85/E2/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page85/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page85/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page85/E2/12.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page85/E2/13.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page85/E2/14.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page85/E2/15.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page85/E2/16.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P85-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    // titleQuestion: [{ color: 'black', num: '2', title: 'Complete the sentence with <i>He had</i> or <i>He didn’t have.</i>' }],
    // titleImage: "img/FriendsPlus/Page85/E2/1.jpg",
    hideBtnFooter: true,
    textareaStyle: { width: 535 },
    questionImage: [],
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div>
              <img src="img/FriendsPlus/Page85/E3/1.jpg">
              <img src="img/FriendsPlus/Page85/E3/2.jpg">
            </div>
            <div style=" position: absolute; top: 170px; left: 80px;"> <textarea id="0" rows="10"></textarea> </div>
          </div>
        `,
        answer: [''],
      },
    ]
  },
}
export default json;