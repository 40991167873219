import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P63-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page63/E1/Key/answerKey.png',
    // checkUppercase: true,
    maxLength: 2,
    textAlign: 'center',
    padding: 0,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page63/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page63/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/3.jpg', input: true, answer: "ay" },
        { url: 'img/FriendsPlus/Page63/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/5.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page63/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/7.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page63/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/9.jpg', input: true, answer: "ai" },
        { url: 'img/FriendsPlus/Page63/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/11.jpg', input: true, answer: "ay" },
        { url: 'img/FriendsPlus/Page63/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/13.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page63/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page63/E1/15.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page63/E1/16.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: "Unit 9",
    id: "WB5-V2-U9-P63-E2",
    audio: "img/FriendsPlus/Page63/Audio/audio-e2-p63.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page63/E2/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [{ color: 'black', num: '2', title: 'Listen and write. <headphone name="21" src="img/FriendsPlus/Page63/Audio/tieude-e2-p63.mp3"></headphone>' }],
    // titleImage: "img/FriendsPlus/Page63/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page63/E2/1.jpg" />
            </div>
            <div style='margin-left: 20px; line-height: 48px;'>
              <div>It’s <sup>1</sup><u style='color: gray;'>&ensp;Monday&ensp;</u> <sup>2</sup>#,</div>
              <div>And I can <sup>3</sup>#.</div>
              <div>Outside there’s <sup>4</sup>#,</div>
              <div>But I’m in with my <sup>5</sup>#s.</div>
              <div>I open my <sup>6</sup>#,</div>
              <div>And the trains have a <sup>7</sup>#!</div>
            </div>
          </div>
        `,
        answer: ['today', 'play', 'rain', 'train', 'case', 'race'],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P63-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: "img/FriendsPlus/Page63/E3/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page63/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page63/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page63/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page63/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page63/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page63/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page63/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page63/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/12.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page63/E3/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page63/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/15.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page63/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page63/E3/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page63/E3/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page63/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/21.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page63/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/23.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page63/E3/24.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page63/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/26.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page63/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/28.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page63/E3/29.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page63/E3/30.jpg' },
      ],
    ]
  },
}
export default json;