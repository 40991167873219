import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/UnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P11-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page11/E1/Key/answerKey.png",
    isAllowSubmit: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page11/E1/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page11/E1/2.jpg" },
        { url: "img/FriendsPlus/Page11/E1/3.jpg", input: true, answer: "pl" },
        { url: "img/FriendsPlus/Page11/E1/4.jpg" },
        { url: "img/FriendsPlus/Page11/E1/5.jpg", input: true, answer: "cr" },
        { url: "img/FriendsPlus/Page11/E1/6.jpg" },
        { url: "img/FriendsPlus/Page11/E1/7.jpg", input: true, answer: "dr" },
        { url: "img/FriendsPlus/Page11/E1/8.jpg" },
        { url: "img/FriendsPlus/Page11/E1/9.jpg", input: true, answer: "sp" },
        { url: "img/FriendsPlus/Page11/E1/10.jpg" },
        { url: "img/FriendsPlus/Page11/E1/11.jpg", input: true, answer: "br" },
        { url: "img/FriendsPlus/Page11/E1/12.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P11-E2",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page11/E2/Key/answerKey.png",
    questionImage: [
      [{ url: "img/FriendsPlus/Page11/E2/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page11/E2/2.jpg"  },
        { url: "img/FriendsPlus/Page11/E2/3.jpg", input: 1, isCorrect: true  },
        { url: "img/FriendsPlus/Page11/E2/4.jpg", input: 1  },
        { url: "img/FriendsPlus/Page11/E2/5.jpg", input: 1  },
        { url: "img/FriendsPlus/Page11/E2/6.jpg"  },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page11/E2/7.jpg"  },
        { url: "img/FriendsPlus/Page11/E2/8.jpg", input: 2  },
        { url: "img/FriendsPlus/Page11/E2/9.jpg", input: 2, isCorrect: true  },
        { url: "img/FriendsPlus/Page11/E2/10.jpg", input: 2  },
        { url: "img/FriendsPlus/Page11/E2/11.jpg"  },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page11/E2/12.jpg"  },
        { url: "img/FriendsPlus/Page11/E2/13.jpg", input: 3, isCorrect: true  },
        { url: "img/FriendsPlus/Page11/E2/14.jpg", input: 3  },
        { url: "img/FriendsPlus/Page11/E2/15.jpg", input: 3  },
        { url: "img/FriendsPlus/Page11/E2/16.jpg"  },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P11-E3",
    audio: "img/FriendsPlus/Page11/Audio/audio-e3-p11.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page11/E3/Key/answerKey.png",
    inputSize: 100,
    titleImage: "",
    titleQuestion: [{ color: '#44509f', num: '3', title: 'Listen and write. <headphone name="03" src="img/FriendsPlus/Page11/Audio/tieude-e3-p11.mp3"></headphone>'}],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row;'>
            <div style='width: 32%;'>
              <img src="img/FriendsPlus/Page11/E3/title-01.jpg" style="width: 98%">
            </div>
            <div style='line-height: 54px;width: 36%;'>
                <div>We <sup>1</sup> <u>drink</u> from a cup.</div>
                <div>We eat with a <sup>2</sup> # .</div>
                <div>We <sup>3</sup> # with a</div>
                <div><sup>4</sup> #.</div>
                <div>We <sup>5</sup> # all afternoon.</div>
            </div>
            <div style='width: 32%;'>
              <img src="img/FriendsPlus/Page11/E3/title-02.jpg" style="width: 134%">
            </div>
          </div>
        `,
        answer: [ "spoon", "draw", "crayon", "play" ],
      },
    ],
  },
};

export default json;
