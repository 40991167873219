import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'WB4-U11-P77-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page77/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Say the words. Circle the word that doesn’t have a long vowel sound <i>i</i>.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white', margin: 11 },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          'smile sit sky dry', //0
          'sky night fish shine', //1
          'pin shine dry light', //2
          'night fig shine light', //3
          'dry light smile pink', //4
        ],
        answers: ['2-0', '0-2', '3-2', '1-4', '4-6'],
        initialValue: [],
      },
      Layout: `
        <div style='display: flex; margin-bottom: 20px;'>
          <div style='margin-right: 50px;'>
            <div><b>1</b> <span style='margin: 11px;'>sky</span> <span style='margin: 11px;'>night</span> <span style='border: 2px solid gray; border-radius: 50%; margin: 11px;'>six</span> <span style='margin: 11px;'>smile</span></div>
            <div><b>3</b> <input id='0' type='Circle' /></div>
            <div><b>5</b> <input id='1' type='Circle' /></div>
          </div>
          <div style='margin-left: 50px;'>
            <div><b>2</b> <input id='2' type='Circle' /></div>
            <div><b>4</b> <input id='3' type='Circle' /></div>
            <div><b>6</b> <input id='4' type='Circle' /></div>
          </div>
        </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'WB4-U11-P77-E2',
    audio: 'img/FriendsPlus/Page77/Audio/audio-e2-p77.mp3',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page77/E2/Key/answerKey.png',
    // checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page77/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page77/Audio/tieude-e2-p77.mp3' },
        { url: 'img/FriendsPlus/Page77/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page77/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page77/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/6.jpg', input: true, answer: "night" },
        { url: 'img/FriendsPlus/Page77/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/8.jpg', input: true, answer: "sky" },
        { url: 'img/FriendsPlus/Page77/E2/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page77/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/11.jpg', input: true, answer: "light" },
        { url: 'img/FriendsPlus/Page77/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/13.jpg', input: true, answer: "white" },
        { url: 'img/FriendsPlus/Page77/E2/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page77/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/16.jpg', input: true, answer: "smile" },
        { url: 'img/FriendsPlus/Page77/E2/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page77/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page77/E2/19.jpg', input: true, answer: "shines" },
        { url: 'img/FriendsPlus/Page77/E2/20.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page77/E2/21.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: "Unit 11",
    id: "WB5-V2-U11-P77-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page77/E3/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [{ color: 'black', num: '3', title: 'Write.' }],
    // titleImage: "img/FriendsPlus/Page77/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page77/E3/1.jpg">
        <div style='display: flex;'>
          <div>
            <div><b>1</b>&ensp;We <u style='color: gray;'>&ensp;smile&ensp;</u> when we’re happy.</div>
            <div><b>3</b>&ensp;Some animals don’t sleep at #.</div>
            <div><b>5</b>&ensp;We can’t see when there isn’t any #.</div>
          </div>
          <div>
            <div><b>2</b>&ensp;Please wash and # your hands.</div>
            <div><b>4</b>&ensp;There’s a plane in the #.</div>
            <div><b>6</b>&ensp;Sometimes the sun doesn’t #.</div>
          </div>
        </div>
        `,
        answer: ['night', 'light', 'dry', 'sky', 'shine'],
      },
    ]
  },
}
export default json;