import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 9",
    id: "WB5-V2-U9-P64-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page64/E1/Key/answerKey.png",
    inputSize: 150,
    titleQuestion: [{ color: 'black', num: '1', title: 'Order the letters and write the word.' }],
    textAlign: 'center',
    // titleImage: "img/FriendsPlus/Page64/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='margin-right: 10px;'>
              <div>
                <img src="img/FriendsPlus/Page64/E1/1.jpg" />
                <div style='margin-left: 50px;'><u style='color: gray;'>&ensp;world&ensp;</u></div>
              </div>
              <div>
                <img src="img/FriendsPlus/Page64/E1/3.jpg" />
                <div style='margin-left: 20px;'>#</div>
              </div>
              <div>
                <img src="img/FriendsPlus/Page64/E1/5.jpg" />
                <div style='margin-left: 20px;'>#</div>
              </div>
            </div>
            <div style='margin-left: 10px;'>
              <div>
                <img src="img/FriendsPlus/Page64/E1/2.jpg" />
                <div style='margin-left: 20px;'>#</div>
              </div>
              <div>
                <img src="img/FriendsPlus/Page64/E1/4.jpg" />
                <div style='margin-left: 20px;'>#</div>
              </div>
            </div>
          </div>
        `,
        answer: ['river', 'building', 'cave', 'island'],
      },
    ]
  },
  2: { // Exercise num
    unit: "Unit 9",
    id: "WB5-V2-U9-P64-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page64/E2/Key/answerKey.png",
    inputSize: 200,
    titleQuestion: [{ color: 'black', num: '2', title: 'Read the text in the Student Book and write.' }],
    // titleImage: "img/FriendsPlus/Page64/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div><b>1</b>&ensp;Fansipan is the <u style='color: gray;'>&ensp;highest mountain&ensp;</u> in Viet Nam. It is # high.</div>
          <div><b>2</b>&ensp;Son Doong is the # in the world. It is # long, and it has its own #.</div>
          <div><b>3</b>&ensp;The Dong Nai River is the # in Viet Nam. Many people # and # on the river.</div>
          <div><b>4</b>&ensp;Keangnam Ha Noi Landmark Tower is the # in Viet Nam. It has #!</div>
          `,
        answer: ['3,143 meters', 'largest', '8.9 kilometers', 'river', 'longest river', 'live', 'work', 'tallest building', '72 floors'],
      },
    ]
  },
}
export default json;