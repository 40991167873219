import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {

  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB4-U5-P34-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page34/E1/Key/answerKey.png',
    isAllowSubmit: false,
    // checkUppercase: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page34/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page34/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/3.jpg', input: true, answer: "camel" },
        { url: 'img/FriendsPlus/Page34/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/5.jpg', input: true, answer: "kangaroo" },
        { url: 'img/FriendsPlus/Page34/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page34/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page34/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/9.jpg', input: true, answer: "lizard" },
        { url: 'img/FriendsPlus/Page34/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/11.jpg', input: true, answer: "penguin" },
        { url: 'img/FriendsPlus/Page34/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page34/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page34/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/15.jpg', input: true, answer: "zebra" },
        { url: 'img/FriendsPlus/Page34/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page34/E1/17.jpg', input: true, answer: "crocodile" },
        { url: 'img/FriendsPlus/Page34/E1/18.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: "Unit 5",
    id: "SB3-U5-P34-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page34/E2/Key/answerKey.png",
    inputSize: 200,
    titleImage: '',
    backgroundColor: 'transparent',
    titleQuestion: [{ color: "#44509f", num: "2", title: 'Write.' }],
    questionImage: [],
    checkUppercase: true,
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex; flex-direction: row;'>
          <div style='flex: 1;'>
            <div style=" display: flex; flex-direction: row; margin-bottom: 10px;">
              <div> <img src='img/FriendsPlus/Page34/E2/1.jpg' /> </div>
              <div style=" margin: auto 20px ; ">
                <div>That isn’t a monkey.</div>
                <div><u style='color: gray;'>It’s a zebra.</u></div>
              </div>
            </div>        
            <div style=" display: flex; flex-direction: row; margin-bottom: 10px;">
              <div> <img src='img/FriendsPlus/Page34/E2/3.jpg' /> </div>
              <div style=" margin: auto 20px ; ">
                <div>That isn’t a monkey.</div>
                <div>#</div>
              </div>
            </div>        
            <div style=" display: flex; flex-direction: row; margin-bottom: 10px;">
              <div> <img src='img/FriendsPlus/Page34/E2/5.jpg' /> </div>
              <div style=" margin: auto 20px ; ">
                <div>That isn’t a monkey.</div>
                <div>#</div>
              </div>
            </div>
          </div>
          <div style='flex: 1'>
            <div style=" display: flex; flex-direction: row; margin-bottom: 10px;">
              <div> <img src='img/FriendsPlus/Page34/E2/2.jpg' /> </div>
              <div style=" margin: auto 20px ; ">
                <div>That isn’t a monkey.</div>
                <div>#</div>
              </div>
            </div>        
            <div style=" display: flex; flex-direction: row; margin-bottom: 10px;">
              <div> <img src='img/FriendsPlus/Page34/E2/4.jpg' /> </div>
              <div style=" margin: auto 20px ; ">
                <div>That isn’t a monkey.</div>
                <div>#</div>
              </div>
            </div>        
            <div style=" display: flex; flex-direction: row; margin-bottom: 10px;">
              <div> <img src='img/FriendsPlus/Page34/E2/6.jpg' /> </div>
              <div style=" margin: auto 20px ; ">
                <div>That’s a #!</div>
              </div>
            </div>
          </div>
        </div>
          
        `,
        answer: ["It's a kangaroo.", "It's a crocodile.", "It's a camel.", "It's a penguin.", "monkey"],
      },
    ],
  },
}

export default json;