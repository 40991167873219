import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P48-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page48/E1/Key/answerKey.png",
    inputSize: 200,
    // titleQuestion: [{ color: 'black', num: '4', title: 'Write.' }],
    titleImage: "img/FriendsPlus/Page48/E1/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div>1&ensp;People sit in here and watch movies. <u style='color: gray;'>&ensp;movie theater&ensp;</u></div>
          <div>2&ensp;You can buy clothes, books, and toys here. #</div>
          <div>3&ensp;You can play outside on swings and a slide. #</div>
          <div>4&ensp;You can read books here. You must be quiet. #</div>
          <div>5&ensp;You can drink coffee and juice here. You can also eat cake! #</div>
          <div>6&ensp;People come here to play and exercise in the water. #</div>
          <div>7&ensp;You can see paintings and objects from many years in the past here. #</div>
        `,
        answer: ['shopping mall', 'playground', 'library', 'cafe/café', 'swimming pool', 'museum'],
      },
    ],
  },
  2: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P48-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page48/E2/Key/answerKey.png",
    inputSize: 550,
    titleQuestion: [{ color: 'black', num: '2', title: 'Where are the people? Look and write.' }],
    // titleImage: "img/FriendsPlus/Page48/E1/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page48/E2/1.jpg">
          <div>1&ensp;<u style='color: gray;'>&ensp;They’re at the playground.&ensp;</u></div>
          <div>2&ensp;#</div>
          <div>3&ensp;#</div>
          <div>4&ensp;#</div>
        `,
        answer: ["They're at the swimming pool.", "They're at the cafe.", "They're at the library."],
      },
    ],
  },
}

export default json;