import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P49-E1",
    audio: 'img/FriendsPlus/Page49/Audio/audio-e1-p49.mp3',
    video: '',
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page49/E1/Key/answerKey.png",
    titleQuestion: [{ num: '1', title: 'Listen and draw a line from the day to the correct picture.  <headphone name="14" src="img/FriendsPlus/Page49/Audio/tieude-e1-p49.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          { boxMatchStyle: { position: 'absolute', top: '95px', left: '154px', width: 243, height: 170 }, },
          { boxMatchStyle: { position: 'absolute', top: '572px', left: '154px', width: 243, height: 170 }, },

          { boxMatchStyle: { position: 'absolute', top: '161px', left: '495px', width: 100, height: 50, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '212px', left: '495px', width: 100, height: 50, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '267px', left: '495px', width: 135, height: 50, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '320px', left: '495px', width: 110, height: 50, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '374px', left: '495px', width: 100, height: 50, border: '1px dashed black' }, },
          { boxMatchStyle: { position: 'absolute', top: '478px', left: '495px', width: 100, height: 50, border: '1px dashed black' }, },

          { boxMatchStyle: { position: 'absolute', top: '97px', left: '791px', width: 243, height: 170 }, },
          { boxMatchStyle: { position: 'absolute', top: '333px', left: '835px', width: 243, height: 170 }, },
          { boxMatchStyle: { position: 'absolute', top: '573px', left: '792px', width: 243, height: 170 }, },
        ],
        answers: ['0-5', '2-8', '6-9', '1-7', '10-4'],
        initialValue: [],
      },
      Layout: `
        <img src='img/FriendsPlus/Page49/E1/4.jpg' />
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />

        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
        
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
      `,
    },
  },
  2: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P49-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page49/E2/Key/answerKey.png",
    inputSize: 50,
    titleQuestion: [{ color: 'black', num: '2', title: 'Complete the sentences about Jane with in, on, or at.' }],
    // titleImage: "img/FriendsPlus/Page48/E1/1.jpg",
    checkUppercase: true,
    maxLength: 2,
    textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='flex: 2;'>
              <div>1&ensp;She always gets up <u style='color: gray;'>&ensp;at&ensp;</u> eight o’clock.</div>
              <div>2&ensp;She never goes to school # Sundays.</div>
              <div>3&ensp;She sometimes visits her cousins # July.</div>
              <div>4&ensp;She sometimes goes to bed # nine thirty.</div>
              <div>5&ensp;She always plays soccer # Saturdays.</div>
              <div>6&ensp;She never wears a coat # August.</div>
            </div>
            <div style='flex: 1;'>
              <img src="img/FriendsPlus/Page49/E2/1.jpg">
            </div>
          </div>
        `,
        answer: ['on', 'in', 'at', 'on', 'in'],
      },
    ],
  },
}

export default json;