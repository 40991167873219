import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 8",
    id: "WB5-V2-U8-P57-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page57/E1/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [{ color: 'black', num: '1', title: 'Say the words with <i>nd</i>, <i>nt</i>, and <i>mp</i>. Circle the odd-one-out. Write.' }],
    // titleImage: "img/FriendsPlus/Page57/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page57/E1/1.jpg" />
            </div>
            <div style='line-height: 108px; margin: 45px 0 0 20px;'>
              <div><u style='color: gray;'>&ensp;hand&ensp;</u></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
        `,
        answer: ['pond', 'lamp', 'tent'],
      },
    ]
  },
  2: { // Exercise num
    unit: "Unit 8",
    id: "WB5-V2-U8-P57-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page57/E2/Key/answerKey.png",
    inputSize: 60,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Say the words with <i>nd</i>, <i>nt</i>, and <i>mp</i>. Circle the odd-one-out. Write.' }],
    titleImage: "img/FriendsPlus/Page57/E2/1.jpg",
    // checkUppercase: true,
    maxLength: 2,
    textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='flex: 1;'>
              <div><b>1</b>&ensp;There’s a frog in the po<u style='color: gray;'>&ensp;nd&ensp;</u>.</div>
              <div><b>3</b>&ensp;I like sleeping in a te#.</div>
              <div><b>5</b>&ensp;There’s a ca# in the field.</div>
            </div>
            <div style='flex: 1; width: 420px;'>
              <div><b>2</b>&ensp;She’s playing in the sa#.</div>
              <div><b>4</b>&ensp;He’s holding a la#.</div>
              <div><b>6</b>&ensp;There’s a pla# under the tree.</div>
            </div>
          </div>
        `,
        answer: ['nt', 'mp', 'nd', 'mp', 'nt'],
      },
    ]
  },
  3: { // Exercise num
    unit: "Unit 8",
    id: "WB5-V2-U8-P57-E3",
    audio: "img/FriendsPlus/Page57/Audio/audio-e3-p57.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page57/E3/Key/answerKey.png",
    inputSize: 100,
    titleQuestion: [{ num: '3', title: 'Listen and write. <headphone name="17" src="img/FriendsPlus/Page57/Audio/tieude-e3-p57.mp3"></headphone>', color: "#5B5A5D" }],
    // titleImage: "img/FriendsPlus/Page57/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page57/E3/1.jpg" />
            </div>
            <div style='margin-left: 20px;'>
              <div>We put up the <sup>1</sup><u style='color: gray;'>&ensp;tent&ensp;</u>,</div>
              <div>At the big, big <sup>2</sup>#.</div>
              <div>We hear the <sup>3</sup>#.</div>
              <div>We light the <sup>4</sup>#.</div>
              <div>We sit by the <sup>5</sup>#.</div>
              <div>We look at the <sup>6</sup>#s.</div>
              <div>We’re happy together,</div>
              <div>Just me and my <sup>7</sup>#.</div>
            </div>
          </div>
        `,
        answer: ['camp', 'wind', 'lamp', 'pond', 'plant', 'aunt'],
      },
    ]
  },
}
export default json;