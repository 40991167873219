import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P62-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page62/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Look at the chart. Circle the mistake in each sentence. Write the correct sentence.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: { width: 500, marginLeft: 20, color: 'black' },
        answers: ['A mouse is shorter than a horse.','Australia is bigger than Viet Nam.','A plane is faster than a car.','Viet Nam is smaller than the U.S.A.','A giraffe is taller than a horse.'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          "A mouse is taller than a horse.", //0
          "Australia is smaller than Viet Nam.", //1
          "A plane is slower than a car.", //2
          'Viet Nam is bigger than the U.S.A.', //3
          'A giraffe is shorter than a horse.', //4
        ],
        answers: ['0-6', '1-4', '2-6', '3-6', '4-6'],
        initialValue: [],
      },
      Layout: `
        <img src="img/FriendsPlus/Page62/E1/1.jpg" />
        <div>
          <div><b>1</b>&ensp;A train is <span style='border: 2px solid gray; border-radius: 50%;'>faster</span> than a plane.</div>
          <div>&emsp;<u style='color: gray;'>&emsp;A train is slower than a plane.&ensp;</u></div>
        </div>
        <div>
          <div><b>2</b>&ensp;<input id='0' type='Circle' /></div>
          <div>&emsp;<input id='0' /></div>
        </div>
        <div>
          <div><b>3</b>&ensp;<input id='1' type='Circle' /></div>
          <div>&emsp;<input id='1' /></div>
        </div>
        <div>
          <div><b>4</b>&ensp;<input id='2' type='Circle' /></div>
          <div>&emsp;<input id='2' /></div>
        </div>
        <div>
          <div><b>5</b>&ensp;<input id='3' type='Circle' /></div>
          <div>&emsp;<input id='3' /></div>
        </div>
        <div>
          <div><b>6</b>&ensp;<input id='4' type='Circle' /></div>
          <div>&emsp;<input id='4' /></div>
        </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P62-E2',
    audio: 'img/FriendsPlus/Page62/Audio/audio-e2-p62.mp3',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page62/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Listen. Circle the correct answer. <headphone name="20" src="img/FriendsPlus/Page62/Audio/tieude-e2-p62.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white', margin: '0 10px' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          "Thailand Australia", //0
          "a_giraffe a_mouse a_horse", //1
          "a_plane a_car a_bike", //2
          'Thailand Viet_Nam Australia', //3
          'a_mouse a_horse a_giraffe', //4
        ],
        answers: ['0-0', '1-0', '2-4', '3-4', '4-0'],
        initialValue: [],
      },
      Layout: `
        <div style='display: flex;'>
          <div style='line-height: 58px;'>
            <div><b>1</b>&ensp;<span style='border: 2px solid gray; border-radius: 50%;'>a plane</span> a car</div>
            <div><b>2</b>&ensp;<input id='0' type='Circle' /></div>
            <div><b>3</b>&ensp;<input id='1' type='Circle' /></div>
            <div><b>4</b>&ensp;<input id='2' type='Circle' /></div>
            <div><b>5</b>&ensp;<input id='3' type='Circle' /></div>
            <div><b>6</b>&ensp;<input id='4' type='Circle' /></div>
          </div>
          <div>
            <img src="img/FriendsPlus/Page62/E2/1.jpg" />
          </div>
        </div>
      `,
    },
  },
}
export default json;