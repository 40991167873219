import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DrawColor from '../../components/ExcerciseTypes/DrawColor';

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P19-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page19/E1/Key/answerKey.png",
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page19/E1/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page19/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/3.jpg",
          input: true,
          answer: "don't",
        },
        { url: "img/FriendsPlus/Page19/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page19/E1/5.jpg" }],
      [
        // Column4
        { url: "img/FriendsPlus/Page19/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/7.jpg",
          input: true,
          answer: "aren't",
        },
        { url: "img/FriendsPlus/Page19/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/9.jpg",
          input: true,
          answer: "don't",
        },
        { url: "img/FriendsPlus/Page19/E1/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page19/E1/11.jpg" }],
      [
        // Column6
        { url: "img/FriendsPlus/Page19/E1/12.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/13.jpg",
          input: true,
          answer: "doesn't",
        },
        { url: "img/FriendsPlus/Page19/E1/14.jpg" },
        {
          url: "img/FriendsPlus/Page19/E1/15.jpg",
          input: true,
          answer: "He's",
        },
        { url: "img/FriendsPlus/Page19/E1/16.jpg" },
      ],
    ],
  },
  // 2: {
  //   // Exercise num
  //   unit: "Unit 2",
  //   id: "WB5-V2-U2-P19-E2",
  //   audio: "",
  //   video: "",
  //   component: DesignUnderLine,
  //   totalInput: 10,
  //   exerciseKey: "img/FriendsPlus/Page19/E2/Key/answerKey.png",
  //   titleImage: "",
  //   questionImage: [
  //     [{ url: "img/FriendsPlus/Page19/E2/1.jpg" }],
  //     [
  //       // Column2
  //       { url: "img/FriendsPlus/Page19/E2/2.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/3.jpg", input: 1 },
  //       { url: "img/FriendsPlus/Page19/E2/4.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/5.jpg", input: 1, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/6.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/7.jpg", input: 1 },
  //       { url: "img/FriendsPlus/Page19/E2/8.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/9.jpg", input: 2, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/10.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/11.jpg", input: 2 },
  //       { url: "img/FriendsPlus/Page19/E2/12.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/13.jpg", input: 2 },
  //       { url: "img/FriendsPlus/Page19/E2/14.jpg" },
  //     ],
  //     [
  //       // Column3
  //       { url: "img/FriendsPlus/Page19/E2/15.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/16.jpg", input: 3 },
  //       { url: "img/FriendsPlus/Page19/E2/17.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/18.jpg", input: 4 },
  //       { url: "img/FriendsPlus/Page19/E2/19.jpg", input: 5, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/20.jpg", input: 6 },
  //       { url: "img/FriendsPlus/Page19/E2/21.jpg", input: 7 },
  //       { url: "img/FriendsPlus/Page19/E2/22.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/23.jpg", input: 8, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/24.jpg", input: 9 },
  //       { url: "img/FriendsPlus/Page19/E2/25.jpg", input: 10 },
  //       { url: "img/FriendsPlus/Page19/E2/26.jpg", input: 11, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/27.jpg", input: 12 },
  //       { url: "img/FriendsPlus/Page19/E2/28.jpg" },
  //     ],
  //     [
  //       // Column4
  //       { url: "img/FriendsPlus/Page19/E2/29.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/30.jpg", input: 13 },
  //       { url: "img/FriendsPlus/Page19/E2/31.jpg", input: 14 },
  //       { url: "img/FriendsPlus/Page19/E2/32.jpg", input: 15, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/33.jpg", input: 16 },
  //       { url: "img/FriendsPlus/Page19/E2/34.jpg", input: 17 },
  //       { url: "img/FriendsPlus/Page19/E2/35.jpg", input: 18 },
  //       { url: "img/FriendsPlus/Page19/E2/36.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/37.jpg", input: 19, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/38.jpg", input: 20 },
  //       { url: "img/FriendsPlus/Page19/E2/39.jpg", input: 21 },
  //       { url: "img/FriendsPlus/Page19/E2/40.jpg", input: 22 },
  //       { url: "img/FriendsPlus/Page19/E2/41.jpg", input: 23, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/42.jpg" },
  //     ],
  //     [
  //       // Column5
  //       { url: "img/FriendsPlus/Page19/E2/43.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/44.jpg", input: 24 },
  //       { url: "img/FriendsPlus/Page19/E2/45.jpg", input: 25 },
  //       { url: "img/FriendsPlus/Page19/E2/46.jpg", input: 26 },
  //       { url: "img/FriendsPlus/Page19/E2/47.jpg", input: 27 },
  //       { url: "img/FriendsPlus/Page19/E2/48.jpg", input: 28 },
  //       { url: "img/FriendsPlus/Page19/E2/49.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/50.jpg", input: 29 },
  //       { url: "img/FriendsPlus/Page19/E2/51.jpg", input: 30 },
  //       { url: "img/FriendsPlus/Page19/E2/52.jpg", input: 31 },
  //       { url: "img/FriendsPlus/Page19/E2/53.jpg", input: 32 },
  //       { url: "img/FriendsPlus/Page19/E2/54.jpg", input: 33 },
  //       { url: "img/FriendsPlus/Page19/E2/55.jpg" },
  //     ],
  //     [
  //       // Column6
  //       { url: "img/FriendsPlus/Page19/E2/56.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/57.jpg", input: 34 },
  //       { url: "img/FriendsPlus/Page19/E2/58.jpg", input: 35 },
  //       { url: "img/FriendsPlus/Page19/E2/59.jpg", input: 36 },
  //       { url: "img/FriendsPlus/Page19/E2/60.jpg", input: 37 },
  //       { url: "img/FriendsPlus/Page19/E2/61.jpg", input: 38 },
  //       { url: "img/FriendsPlus/Page19/E2/62.jpg", input: 39 },
  //       { url: "img/FriendsPlus/Page19/E2/63.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/64.jpg", input: 40 },
  //       { url: "img/FriendsPlus/Page19/E2/65.jpg", input: 41 },
  //       { url: "img/FriendsPlus/Page19/E2/66.jpg", input: 42 },
  //       { url: "img/FriendsPlus/Page19/E2/67.jpg", input: 43 },
  //       { url: "img/FriendsPlus/Page19/E2/68.jpg" },
  //     ],
  //     [
  //       // Column7
  //       { url: "img/FriendsPlus/Page19/E2/69.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/70.jpg", input: 44 },
  //       { url: "img/FriendsPlus/Page19/E2/71.jpg", input: 45 },
  //       { url: "img/FriendsPlus/Page19/E2/72.jpg", input: 46 },
  //       { url: "img/FriendsPlus/Page19/E2/73.jpg", input: 47 },
  //       { url: "img/FriendsPlus/Page19/E2/74.jpg", input: 48 },
  //       { url: "img/FriendsPlus/Page19/E2/75.jpg", input: 49 },
  //       { url: "img/FriendsPlus/Page19/E2/76.jpg", input: 50 },
  //       { url: "img/FriendsPlus/Page19/E2/77.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/78.jpg", input: 51 },
  //       { url: "img/FriendsPlus/Page19/E2/79.jpg", input: 52 },
  //       { url: "img/FriendsPlus/Page19/E2/80.jpg", input: 53 },
  //       { url: "img/FriendsPlus/Page19/E2/81.jpg", input: 54 },
  //       { url: "img/FriendsPlus/Page19/E2/82.jpg", input: 55 },
  //       { url: "img/FriendsPlus/Page19/E2/83.jpg", input: 56 },
  //       { url: "img/FriendsPlus/Page19/E2/84.jpg", input: 57 },
  //       { url: "img/FriendsPlus/Page19/E2/85.jpg", input: 58 },
  //       { url: "img/FriendsPlus/Page19/E2/86.jpg", input: 59 },
  //       { url: "img/FriendsPlus/Page19/E2/87.jpg" },
  //     ],
  //     [
  //       // Column8
  //       { url: "img/FriendsPlus/Page19/E2/88.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/89.jpg", input: 60, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/90.jpg", input: 61 },
  //       { url: "img/FriendsPlus/Page19/E2/91.jpg", input: 62 },
  //       { url: "img/FriendsPlus/Page19/E2/92.jpg", input: 63 },
  //       { url: "img/FriendsPlus/Page19/E2/93.jpg", input: 64 },
  //       { url: "img/FriendsPlus/Page19/E2/94.jpg", input: 65 },
  //       { url: "img/FriendsPlus/Page19/E2/95.jpg", input: 66 },
  //       { url: "img/FriendsPlus/Page19/E2/96.jpg", input: 67 },
  //       { url: "img/FriendsPlus/Page19/E2/97.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/98.jpg", input: 68 },
  //       { url: "img/FriendsPlus/Page19/E2/99.jpg", input: 69, isCorrect: true },
  //       { url: "img/FriendsPlus/Page19/E2/100.jpg", input: 70 },
  //       { url: "img/FriendsPlus/Page19/E2/101.jpg", input: 71 },
  //       { url: "img/FriendsPlus/Page19/E2/102.jpg", input: 72 },
  //       { url: "img/FriendsPlus/Page19/E2/103.jpg", input: 73 },
  //       { url: "img/FriendsPlus/Page19/E2/104.jpg" },
  //     ],
  //     [
  //       // Column9
  //       { url: "img/FriendsPlus/Page19/E2/105.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/106.jpg", input: 74 },
  //       { url: "img/FriendsPlus/Page19/E2/107.jpg", input: 75 },
  //       { url: "img/FriendsPlus/Page19/E2/108.jpg", input: 76 },
  //       { url: "img/FriendsPlus/Page19/E2/109.jpg", input: 77 },
  //       { url: "img/FriendsPlus/Page19/E2/110.jpg", input: 78 },
  //       { url: "img/FriendsPlus/Page19/E2/111.jpg", input: 79 },
  //       { url: "img/FriendsPlus/Page19/E2/112.jpg", input: 80 },
  //       { url: "img/FriendsPlus/Page19/E2/113.jpg", input: 81 },
  //       { url: "img/FriendsPlus/Page19/E2/114.jpg", input: 82 },
  //       { url: "img/FriendsPlus/Page19/E2/115.jpg", input: 83 },
  //       { url: "img/FriendsPlus/Page19/E2/116.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/117.jpg", input: 84 },
  //       { url: "img/FriendsPlus/Page19/E2/118.jpg", input: 85 },
  //       { url: "img/FriendsPlus/Page19/E2/119.jpg", input: 86 },
  //       { url: "img/FriendsPlus/Page19/E2/120.jpg", input: 87 },
  //       { url: "img/FriendsPlus/Page19/E2/121.jpg", input: 88 },
  //       { url: "img/FriendsPlus/Page19/E2/122.jpg", input: 89 },
  //       { url: "img/FriendsPlus/Page19/E2/123.jpg" },
  //     ],
  //     [
  //       // Column10
  //       { url: "img/FriendsPlus/Page19/E2/124.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/125.jpg", input: 90 },
  //       { url: "img/FriendsPlus/Page19/E2/126.jpg", input: 91 },
  //       { url: "img/FriendsPlus/Page19/E2/127.jpg", input: 92 },
  //       { url: "img/FriendsPlus/Page19/E2/128.jpg", input: 93 },
  //       { url: "img/FriendsPlus/Page19/E2/129.jpg", input: 94 },
  //       { url: "img/FriendsPlus/Page19/E2/130.jpg", input: 95 },
  //       { url: "img/FriendsPlus/Page19/E2/131.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/132.jpg", input: 96 },
  //       { url: "img/FriendsPlus/Page19/E2/133.jpg", input: 97 },
  //       { url: "img/FriendsPlus/Page19/E2/134.jpg", input: 98 },
  //       { url: "img/FriendsPlus/Page19/E2/135.jpg", input: 99 },
  //       { url: "img/FriendsPlus/Page19/E2/136.jpg" },
  //     ],
  //     [
  //       // Column11
  //       { url: "img/FriendsPlus/Page19/E2/137.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/138.jpg", input: 100 },
  //       { url: "img/FriendsPlus/Page19/E2/139.jpg", input: 101 },
  //       { url: "img/FriendsPlus/Page19/E2/140.jpg" },
  //       { url: "img/FriendsPlus/Page19/E2/141.jpg", input: 102 },
  //       { url: "img/FriendsPlus/Page19/E2/142.jpg", input: 103 },
  //       { url: "img/FriendsPlus/Page19/E2/143.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page19/E2/144.jpg" }],
  //   ],
  // },
  2: {
    unit: 'Unit 2',
    id: 'WB5-U2-P19-E2',
    audio: '',
    video: '',
    component: DrawColor,
    // component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page19/E2/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Circle the correct form of the verb.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: {},
          circle: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'gray' },
          underline: { borderBottom: '2px solid gray' },
          // square: { padding: 3, border: 'solid 2px', borderColor: 'gray' },
        },
        // limitSelect: 1,
        listWords: [
          "Hello Hoang / Eun-sol / Mon!",	//0
          "My name’s Kelly. I’m nine years ",	//1
          "old, and I’m from the U.S.A. ",	//2
          "You like visiting your family ",	//3
          "and I love visiting my family, ",	//4
          "too! I love cooking with my grandma. ",	//5
          "She’s always really kind. I like playing chess ",	//6
          "with my grandpa, too. I want to be your email ",	//7
          "pen pal. Please write to me.",	//8
          "From Kelly",	//9
          "Hi Hoang / Eun-sol / Mon! ",	//10
          "I’m Felipe and I’m nine. ",	//11
          "I’m from Brazil. I don’t ",	//12
          "like shopping, but I like ",	//13
          "playing the guitar, too! ",	//14
          "I also like playing on the beach with my ",	//15
          "friends. We’re doing gymnastics in this ",	//16
          "photo. Do you like doing gymnastics, ",	//17
          "too? Please email me.",	//18
          "From Felipe",	//19
        ],
        answers: {'0-6':'circle','10-2':'circle','1-6':'underline','2-4':'underline','6-0':'underline','11-0':'underline','11-6':'underline','12-0':'underline','12-8':'underline','16-2':'underline'},
        initialValue: {},
      },
      Layout: `
        <div style='position: relative;'>
        <div>
        <img src='img/FriendsPlus/Page19/E2/background/1.jpg' />
        </div>
          <div style='position: absolute;top: 190px;font-size: 20px;left: 80px;'>
            <div><input id='0' type='Circle' /></div>
            <div><input id='1' type='Circle' /></div>
            <div><input id='2' type='Circle' /></div>
            <div><input id='3' type='Circle' /></div>
            <div><input id='4' type='Circle' /></div>
            <div><input id='5' type='Circle' /></div>
            <div><input id='6' type='Circle' /></div>
            <div><input id='7' type='Circle' /></div>
            <div><input id='8' type='Circle' /></div>
            <div><input id='9' type='Circle' /></div>
          </div>
        
          <div style='position: absolute; top: 190px; right: 95px; font-size: 20px;'>
            <div><input id='10' type='Circle' /></div>
            <div><input id='11' type='Circle' /></div>
            <div><input id='12' type='Circle' /></div>
            <div><input id='13' type='Circle' /></div>
            <div><input id='14' type='Circle' /></div>
            <div><input id='15' type='Circle' /></div>
            <div><input id='16' type='Circle' /></div>
            <div><input id='17' type='Circle' /></div>
            <div><input id='18' type='Circle' /></div>
            <div><input id='19' type='Circle' /></div>
          </div>
        </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P19-E3",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "",
    isAllowSubmit: false,
    hideBtnFooter: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page19/E3/1.jpg" }],
      [{ url: "img/FriendsPlus/Page19/E3/2.jpg" }],
      [
        // Column3
        { url: "img/FriendsPlus/Page19/E3/3.jpg" },
        { url: "img/FriendsPlus/Page19/E3/4.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page19/E3/5.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page19/E3/6.jpg" },
        { url: "img/FriendsPlus/Page19/E3/7.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page19/E3/8.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page19/E3/9.jpg" },
        { url: "img/FriendsPlus/Page19/E3/10.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page19/E3/11.jpg" },
      ],
      [
        // Column6
        { url: "img/FriendsPlus/Page19/E3/12.jpg" },
        { url: "img/FriendsPlus/Page19/E3/13.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page19/E3/14.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page19/E3/15.jpg" },
        { url: "img/FriendsPlus/Page19/E3/16.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page19/E3/17.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page19/E3/18.jpg" },
        { url: "img/FriendsPlus/Page19/E3/19.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page19/E3/20.jpg" },
      ],
      [
        // Column9
        { url: "img/FriendsPlus/Page19/E3/21.jpg" },
        { url: "img/FriendsPlus/Page19/E3/22.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page19/E3/23.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page19/E3/24.jpg" }],
    ],
  },
};

export default json;
