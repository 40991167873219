import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 11",
    id: "WB5-V2-U11-P75-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page75/E1/Key/answerKey.png",
    inputSize: 150,
    titleQuestion: [{ color: 'black', num: '1', title: 'Write <i>There was</i> or <i>There were</i>.' }],
    // titleImage: "img/FriendsPlus/Page75/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div><b>1</b>&ensp;<u style='color: gray;'>&ensp;There was&ensp;</u> a hotel in our town one hundred years ago.</div>
          <div><b>2</b>&ensp;# trolleys in our town one hundred years ago.</div>
          <div><b>3</b>&ensp;# some buses in our town one hundred years ago.</div>
          <div><b>4</b>&ensp;# a park in our town one hundred years ago.</div>
          <div><b>5</b>&ensp;# lots of bikes in our town one hundred years ago.</div>
          <div><b>6</b>&ensp;# a museum in our town one hundred years ago.</div>
        `,
        answer: ['There were', 'There were', 'There was', 'There were', 'There was'],
      },
    ]
  },
  2: { // Exercise num
    unit: "Unit 11",
    id: "WB5-V2-U11-P75-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page75/E2/Key/answerKey.png",
    inputSize: 170,
    titleQuestion: [{ color: 'black', num: '2', title: 'Write <i>There was, There were, There wasn’t,</i> or <i>There weren’t</i>.' }],
    // titleImage: "img/FriendsPlus/Page75/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page75/E2/1.jpg">
        <div>
          <div><b>1</b>&ensp;<u style='color: gray;'>&ensp;There weren’t&ensp;</u> any books at seven o’clock.</div>
          <div><b>2</b>&ensp;# some flowers at ten o’clock.</div>
          <div><b>3</b>&ensp;# a camera at seven o’clock.</div>
          <div><b>4</b>&ensp;# a computer at ten o’clock.</div>
          <div><b>5</b>&ensp;# a banana at seven o’clock.</div>
          <div><b>6</b>&ensp;# any pens at seven o’clock.</div>
          <div><b>7</b>&ensp;# a camera at ten o’clock.</div>
        </div>
        `,
        answer: ['There were', "There wasn't", 'There was', "There wasn't", "There weren't", 'There was'],
      },
    ]
  },
}
export default json;