import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P16-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page16/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      [{ url: "img/FriendsPlus/Page16/E1/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page16/E1/2.jpg" },
        { url: "img/FriendsPlus/Page16/E1/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page16/E1/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page16/E1/5.jpg" },
        { url: "img/FriendsPlus/Page16/E1/6.jpg", input: 1 },
        { url: "img/FriendsPlus/Page16/E1/7.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page16/E1/8.jpg" },
        { url: "img/FriendsPlus/Page16/E1/9.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page16/E1/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page16/E1/11.jpg" }],
      [
        // Column6
        { url: "img/FriendsPlus/Page16/E1/12.jpg" },
        { url: "img/FriendsPlus/Page16/E1/13.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page16/E1/14.jpg" },
        { url: "img/FriendsPlus/Page16/E1/15.jpg", input: 3 },
        { url: "img/FriendsPlus/Page16/E1/16.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page16/E1/17.jpg" },
        { url: "img/FriendsPlus/Page16/E1/18.jpg", input: 2 },
        { url: "img/FriendsPlus/Page16/E1/19.jpg" },
        { url: "img/FriendsPlus/Page16/E1/20.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page16/E1/21.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page16/E1/22.jpg" },
        { url: "img/FriendsPlus/Page16/E1/23.jpg", input: 2 },
        { url: "img/FriendsPlus/Page16/E1/24.jpg" },
        { url: "img/FriendsPlus/Page16/E1/25.jpg", input: 3 },
        { url: "img/FriendsPlus/Page16/E1/26.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "WB5-V2-U2-P16-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: '',
    titleQuestion: [{ num: '2', title: 'Write about you. Complete the question and circle your answer.', color: "#5B5A5D" }],
    hideBtnFooter: true,
    question: {
      Write: {
        inputStyle: { width: 100, color: 'black' },
        answers: ['', ''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          "Yes,_I_do. / No,_I_don’t.", //0
          "Yes,_I_do. / No,_I_don’t.", //1
          "Yes,_I_do. / No,_I_don’t.", //2
          'Yes,_I_do. / No,_I_don’t.', //3
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <div style='display:flex;'>
          <div style='margin-right:10px;'>
            <div><b>1</b> Do you like reading comics?</div>
            <div><b>2</b> Do you like climbing?</div>
            <div><b>3</b> Do you <input id='0' /> gymnastics?</div>
            <div><b>4</b> Do you <input id='1' /> chess?</div>
          </div>
          <div style='margin-left:10px;'>
            <div><input id='0' type='Circle' /></div>
            <div><input id='1' type='Circle' /></div>
            <div><input id='2' type='Circle' /></div>
            <div><input id='3' type='Circle' /></div>
          </div>
        </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P16-E3",
    audio: "img/FriendsPlus/Page16/Audio/audio-e3-p16.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page16/E3/Key/answerKey.png",
    inputSize: 150,
    titleImage: "",
    titleQuestion: [{ color: '#000000', num: '3', title: 'Listen and write. <headphone name="05" src="img/FriendsPlus/Page16/Audio/tieude-e3-p16.mp3"></headphone>' }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row;'>
            <div style='width: 50%;'>
              <img src="img/FriendsPlus/Page16/E3/title.jpg">
            </div>
            <div style='width: 50%;'>
                <div>Dad <sup>1</sup> <u style='color: gray;'>likes</u> playing basketball,</div>
                <div>He also likes playing<sup>2</sup> #.</div>
                <div>Mom likes <sup>3</sup> # tennis,</div>
                <div>But I<sup>4</sup> # fishing best!</div>
                <div>Mom likes <sup>5</sup> # pictures,</div>
                <div>And <sup>6</sup> # like drawing, too.</div>
                <div>Dad likes taking<sup>7</sup> # ,</div>
                <div>So <sup>8</sup> # have lots to do!</div>
            </div>
          </div>
        `,
        answer: ["chess", "playing", "like", "drawing", "I", "photos", "we"],
      },
    ],
  },
};

export default json;
