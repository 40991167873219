import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P50-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page50/E1/Key/answerKey.png",
    inputSize: 300,
    // titleQuestion: [{ color: 'black', num: '4', title: 'Write.' }],
    titleImage: "img/FriendsPlus/Page50/E1/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='margin-right: 10px;'>
              <div style='display: flex; align-items: center;'>
                <img src="img/FriendsPlus/Page50/E1/2.jpg">
                <div>Bao <u style='color: gray;'>&ensp;always plays&ensp;</u> volleyball.</div>
              </div>
              <div style='display: flex; align-items: center; margin: 20px 0px;'>
                <img src="img/FriendsPlus/Page50/E1/4.jpg">
                <div>Bao # basketball.</div>
              </div>
              <div style='display: flex; align-items: center;'>
                <img src="img/FriendsPlus/Page50/E1/6.jpg">
                <div>Xuan #.</div>
              </div>
            </div>
            <div style='margin-left: 10px;'>
              <div style='display: flex; align-items: center;'>
                <img src="img/FriendsPlus/Page50/E1/3.jpg">
                <div>Mai # soccer.</div>
              </div>
              <div style='display: flex; align-items: center; margin: 20px 0px;'>
                <img src="img/FriendsPlus/Page50/E1/5.jpg">
                <div>Nhan #.</div>
              </div>
              <div style='display: flex; align-items: center;'>
                <img src="img/FriendsPlus/Page50/E1/7.jpg">
                <div>Nhan #.</div>
              </div>
            </div>
          </div>
        `,
        answer: ['sometimes plays', 'never plays basketball', 'sometimes plays', 'never plays volleyball', 'always plays soccer'],
      },
    ],
  },
  2: { // Exercise num
    unit: "Unit 7",
    id: "WB5-V2-U7-P50-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page50/E2/Key/answerKey.png",
    inputSize: 300,
    titleQuestion: [{ color: 'black', num: '2', title: 'Write true sentences about you. Use never, sometimes, or always.' }],
    // titleImage: "img/FriendsPlus/Page48/E1/1.jpg",
    // checkUppercase: true,
    hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div>1&ensp;<u style='color: gray;'>&ensp; I never get up&ensp;</u> late.</div>
          <div>2&ensp;# my teeth in the morning.</div>
          <div>3&ensp;# breakfast with my family.</div>
          <div>4&ensp;# the bus to school.</div>
          <div>5&ensp;# TV in the evening.</div>
        `,
        answer: ['', '', '', ''],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 7',
    id: 'WB4-U7-P50-E3',
    audio: 'img/FriendsPlus/Page50/Audio/audio-e3-p50.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 11,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page50/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page50/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/tieude-e3-p50.mp3' },
        { url: 'img/FriendsPlus/Page50/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page50/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page50/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page50/E3/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page50/E3/8.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page50/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page50/E3/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page50/E3/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page50/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/16.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page50/E3/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/18.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page50/E3/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page50/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/21.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page50/E3/22.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/23.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page50/E3/24.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page50/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/26.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page50/E3/27.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/28.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page50/E3/29.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page50/E3/30.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/31.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page50/E3/32.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page50/E3/33.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/34.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page50/E3/35.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/36.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/37.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page50/E3/38.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page50/E3/39.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page50/E3/40.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/41.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/42.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page50/E3/43.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page50/E3/44.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page50/E3/45.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/46.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page50/E3/47.jpg', input: 9, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/48.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page50/E3/49.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page50/E3/50.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/51.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page50/E3/52.jpg', input: 10, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/53.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page50/E3/54.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page50/E3/55.jpg' },
        { url: 'img/FriendsPlus/Page50/E3/56.jpg', input: 11 },
        { url: 'img/FriendsPlus/Page50/E3/57.jpg', input: 11 },
        { url: 'img/FriendsPlus/Page50/E3/58.jpg', input: 11, isCorrect: true },
        { url: 'img/FriendsPlus/Page50/E3/59.jpg' },
      ],
    ]
  },
}

export default json;