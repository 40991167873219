import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import TextEditer from "../../components/ExcerciseTypes/TextEditer";

const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB4-U5-P39-E1',
    audio: '',
    video: '',
    component: TextEditer,
    exerciseKey: 'img/FriendsPlus/Page39/E1/Key/answerKey.png',
    inputSize: 150,
    // titleImage: "img/FriendsPlus/Page13/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Write the quotation marks.', color: "#5B5A5D" }],
    questionImage: [],
    question: {
      editer: {
        editerStyle: {},
        initialValue: `1 "Goodbye," says the teacher.\n2 I'm taking a photo, says the boy.\n3 Open your books, she says. Read the story.\n4 The monkeys are my favorite animals, says the girl.`,
        answer: `1 "Goodbye," says the teacher.\n2 "I'm taking a photo," says the boy.\n3 "Open your books," she says. "Read the story".\n4 "The monkeys are my favorite animals," says the girl.`,
      },
      Layout: `
      <div style=" width: 800px; ">
        <editer></editer>
      </div>
      `
    },
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB4-U5-P39-E2',
    audio: '',
    video: '',
    component: D1,
    // typeInput: 'center',
    // fontSize: 40,
    // inputHeight: '100%',
    isAllowSubmit: true,
    exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
    questionImage: [ // Row
      [{ url: 'img/FriendsPlus/Page39/E2/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page39/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page39/E2/3.jpg', input: true, answer: "Monkeys" },
        { url: 'img/FriendsPlus/Page39/E2/4.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page39/E2/5.jpg' }],
      [{ url: 'img/FriendsPlus/Page39/E2/6.jpg' }],
    ],
  },
  3: { // Exercise num
    // unit: 'Unit 5',
    // id: 'WB4-U5-P39-E3',
    // audio: '',
    // video: '',
    // component: D1,
    // isAllowSubmit: true,
    // exerciseKey: 'img/FriendsPlus/Page39/E3/Key/answerKey.png',
    // hideBtnFooter: true,
    // inputHeight: 150,
    // questionImage: [ // Row
    //   [{ url: 'img/FriendsPlus/Page39/E3/1.jpg' }],
    //   [{ url: 'img/FriendsPlus/Page39/E3/2.jpg' }],
    //   [
    //     { url: 'img/FriendsPlus/Page39/E3/3.jpg' },
    //     { url: 'img/FriendsPlus/Page39/E3/4.jpg', input: true, multiLine: true, rows: 3, lineHeight: 1.9, answer: "" },
    //     { url: 'img/FriendsPlus/Page39/E3/5.jpg' },
    //   ],
    //   [{ url: 'img/FriendsPlus/Page39/E3/6.jpg' }],
    //   [
    //     { url: 'img/FriendsPlus/Page39/E3/7.jpg' },
    //     { url: 'img/FriendsPlus/Page39/E3/8.jpg', input: true, multiLine: true, rows: 3, lineHeight: 1.9, answer: "" },
    //     { url: 'img/FriendsPlus/Page39/E3/9.jpg' },
    //   ],
    //   [{ url: 'img/FriendsPlus/Page39/E3/10.jpg' }],
    // ],
    unit: 'Unit 5',
    id: 'WB4-U5-P39-E3',
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    questionImage: [],
    hideBtnFooter: true,
    textareaStyle: { width: '100%', resize: 'none', background: 'transparent', lineHeight: '1.9em' },
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div>
              <img src="img/FriendsPlus/Page39/E3/background/1.jpg">
            </div>
            <div style=" position: absolute; top: 147px; left: 95px;width:845px;"> <textarea id="0" rows="3"></textarea> </div>
            <div style=" position: absolute; top: 330px; left: 95px;width:845px;"> <textarea id="1" rows="3"></textarea> </div>
        `,
        answer: ['', '', '', ''],
      },
    ]
  },
}

export default json;