import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'WB4-U10-P72-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page72/E1/Key/answerKey.png',
    maxLength: 1,
    textAlign: 'center',
    padding: 0,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page72/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page72/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/3.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page72/E1/4.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page72/E1/5.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page72/E1/6.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page72/E1/7.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page72/E1/8.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page72/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/10.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page72/E1/11.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page72/E1/12.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page72/E1/13.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page72/E1/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page72/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/16.jpg', input: true, answer: "m" },
        { url: 'img/FriendsPlus/Page72/E1/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page72/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/19.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page72/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/21.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page72/E1/22.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page72/E1/23.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page72/E1/24.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page72/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/26.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page72/E1/27.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page72/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/29.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page72/E1/30.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page72/E1/31.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'WB4-U10-P72-E2',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page72/E2/Key/answerKey.png',
    // checkUppercase: true,
    padding: 0,
    maxLength: 2,
    textAlign: 'center',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page72/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page72/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/3.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page72/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page72/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page72/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/7.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page72/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page72/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page72/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/11.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page72/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page72/E2/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page72/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/15.jpg', input: true, answer: "8" },
        { url: 'img/FriendsPlus/Page72/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page72/E2/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page72/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/19.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page72/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page72/E2/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page72/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/23.jpg', input: true, answer: "7" },
        { url: 'img/FriendsPlus/Page72/E2/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page72/E2/25.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page72/E2/26.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/27.jpg', input: true, answer: "6" },
        { url: 'img/FriendsPlus/Page72/E2/28.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page72/E2/29.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page72/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/31.jpg', input: true, answer: "11" },
        { url: 'img/FriendsPlus/Page72/E2/32.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page72/E2/33.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page72/E2/34.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/35.jpg', input: true, answer: "12" },
        { url: 'img/FriendsPlus/Page72/E2/36.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page72/E2/37.jpg' },
      ],
      [
        // Column20
        { url: 'img/FriendsPlus/Page72/E2/38.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/39.jpg', input: true, answer: "9" },
        { url: 'img/FriendsPlus/Page72/E2/40.jpg' },
      ],
      [
        // Column21
        { url: 'img/FriendsPlus/Page72/E2/41.jpg' },
      ],
      [
        // Column22
        { url: 'img/FriendsPlus/Page72/E2/42.jpg' },
        { url: 'img/FriendsPlus/Page72/E2/43.jpg', input: true, answer: "10" },
        { url: 'img/FriendsPlus/Page72/E2/44.jpg' },
      ],
      [
        // Column23
        { url: 'img/FriendsPlus/Page72/E2/45.jpg' },
      ],
    ]
  },
}
export default json;