import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'WB4-U8-P54-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: "img/FriendsPlus/Page54/E1/Key/answerKey.png",
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page54/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page54/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/3.jpg', input: true, answer: "cereal" },
        { url: 'img/FriendsPlus/Page54/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/5.jpg', input: true, answer: "noodles" },
        { url: 'img/FriendsPlus/Page54/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page54/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page54/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/9.jpg', input: true, answer: "lemon" },
        { url: 'img/FriendsPlus/Page54/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/11.jpg', input: true, answer: "cucumber" },
        { url: 'img/FriendsPlus/Page54/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/13.jpg', input: true, answer: "onion" },
        { url: 'img/FriendsPlus/Page54/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page54/E1/15.jpg', input: true, answer: "melon" },
        { url: 'img/FriendsPlus/Page54/E1/16.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: "Unit 8",
    id: "WB5-V2-U8-P54-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page54/E2/Key/answerKey.png",
    inputSize: 70,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Say the words with ld and lt. Circle the odd-one-out. Write.' }],
    titleImage: "img/FriendsPlus/Page54/E2/1.jpg",
    // checkUppercase: true,
    maxLength: 3,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <div><b>1</b>&nbsp;She’d like a cucumber.</div>
              <div><b>2</b>&nbsp;She’d like some cereal.</div>
              <div><b>3</b>&nbsp;She’d like some meat.</div>
              <div><b>4</b>&nbsp;She’d like an onion.</div>
              <div><b>5</b>&nbsp;She’d like a melon.</div>
              <div><b>6</b>&nbsp;She’d like some noodles.</div>
              <div><b>7</b>&nbsp;She’d like some rice.</div>
            </div>
            <div style='margin: 0 20px;'>
              <div><u style='color: gray;'>&ensp;yes&ensp;</u></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
            <div>
              <img src="img/FriendsPlus/Page54/E2/2.jpg">
            </div>
          </div>
        `,
        answer: ['no', 'yes', 'yes', 'no', 'yes', 'no'],
      },
    ]
  },
}

export default json;