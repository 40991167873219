import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Unit 4",
    id: "WB5-V2-U4-P33-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page33/E1/Key/answerKey.png",
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page33/E1/title.jpg",
    questionImage: [],
    questions: [
      {
        title: `
          <div style="display: flex;">
            <div style='margin-right:10px;'>
              <div>1 Do you like <u style='color:gray'>&ensp;going&ensp;</u> (go) on holiday?</div>
              <div>2 Do you like # (fly) kites?</div>
              <div>3 Do you like # (visit) zoos?</div>
              <div>4 Do you like # (play) computer games?</div>
            </div>
            <div style='margin-left:10px;'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>            
          </div>
        `,
        answer: ["flying", "visiting", "playing", "", "", "", ""],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB4-U4-P33-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page33/E2/Key/answerKey.png",
    titleImage: "",
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page33/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page33/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/5.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page33/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page33/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page33/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/9.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page33/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page33/E2/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page33/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/14.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page33/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page33/E2/16.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page33/E2/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page33/E2/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page33/E2/19.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'WB4-U4-P33-E3',
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    inputSize: '100%',
    // titleQuestion: [{ color: 'black', num: '3', title: 'Read and complete with <i>ai</i> or <i>ay</i>.' }],
    // titleImage: "img/FriendsPlus/Page79/E2/1.jpg",
    stylesTextInput: { background: 'transparent', border: 'none' },
    questionImage: [],
    hideBtnFooter: true,
    textareaStyle: { width: '100%', resize: 'none' },
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div>
              <img src="img/FriendsPlus/Page33/E3/1.jpg">
            </div>
            <div style=" position: absolute; top: 130px; left: 80px;width:845px;"> <textarea id="0" rows="9">We're at \t\t\t\t\t\t\t\t\t . It is \n\n\n\n\n\n\n\nFrom</textarea> </div>
        `,
        answer: ['', '', '', ''],
      },
    ]
  },
}

export default json;