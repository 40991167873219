import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB4-U4-P32-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page32/E1/Key/answerKey.png",
    titleQuestion: [{ num: '1', title: 'Circle the correct word.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 3px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          'dangerous / beautiful.', //0
          'safe / dangerous!', //1
          'clean / polluted.', //2
          'polluted / beautiful.', //3
        ],
        answers: ['0-0', '1-0', '2-0', '3-4'],
        initialValue: [],
      },
      Layout: `
        <div >
          <div style='display: flex;flex-wrap: wrap;justify-content: space-around;align-items: flex-end;align-content: space-around;'>
            <div style='width: 300px; height: 360px;'>
              <img src="img/FriendsPlus/Page32/E1/1.jpg">
              <div>This river is <b>beautiful / <span style='border: 3px solid grey;border-radius: 50%;padding: 5px;'>polluted</span></b></div>
            </div>
            <div style='width: 300px; height: 360px;'>
              <img src="img/FriendsPlus/Page32/E1/2.jpg">
              <div>This park is <span style='font-weight: bold;'><input id='0' type='Circle' /></span></div>
            </div>
            <div style='width: 300px; height: 360px;'>
              <img src="img/FriendsPlus/Page32/E1/3.jpg">
              <div>This is <span style='font-weight: bold;'><input id='1' type='Circle' /></span></div>
            </div>
            <div style='width: 300px; height: 360px;'>
              <img src="img/FriendsPlus/Page32/E1/4.jpg">
              <div>This river is <span style='font-weight: bold;'><input id='2' type='Circle' /></span></div>
            </div>
            <div style='width: 300px; height: 360px;'>
              <img src="img/FriendsPlus/Page32/E1/5.jpg">
              <div>This park is <span style='font-weight: bold;'><input id='3' type='Circle' /></span></div>
            </div>
          </div>
        </div>
      `,
    },
  },
  2: { // Exercise num
    unit: "Unit 4",
    id: "WB5-V2-U4-P32-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page32/E2/Key/answerKey.png",
    inputSize: 74,
    titleImage: "",
    maxLength: 1,
    textAlign: 'center',
    titleQuestion: [{ color: 'black', num: '2', title: 'Read the text in the Student Book. Write T (true) or F (false).' }],
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style="display: flex;flex-direction: row;justify-content: space-between;width: 550px;">
            <div>
              <div><b>1</b> The sea in Phan Thiet is polluted. </div>
              <div><b>2</b> The people are friendly. </div>
              <div><b>3</b> The sand on the beach is red. </div>
              <div><b>4</b> There are seafood restaurants. </div>
              <div><b>5</b> There are lots of hotels. </div>
              <div><b>6</b> The sand dunes are dangerous! </div>
            </div>
            <div style=" width: 150px; ">
              <div style='margin-left: 2px'><u style='color: gray;'>&emsp; F &emsp;</u></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>            
          </div>
        `,
        answer: ["T", "F", "T", "T", "F"],
      },
    ],
  },
}

export default json;