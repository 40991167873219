import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P24-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page24/E1/Key/answerKey.png',
    isAllowSubmit: false,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page24/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page24/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/3.jpg', input: true, answer: "pins" },
        { url: 'img/FriendsPlus/Page24/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/5.jpg', input: true, answer: "shells" },
        { url: 'img/FriendsPlus/Page24/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page24/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page24/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/9.jpg', input: true, answer: "posters" },
        { url: 'img/FriendsPlus/Page24/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/11.jpg', input: true, answer: "collection" },
        { url: 'img/FriendsPlus/Page24/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page24/E1/13.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P24-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page24/E2/Key/answerKey.png",
    inputSize: 150,
    titleImage: "",
    titleQuestion: [{ color: 'black', num: '2', title: 'Read the text in the Student Book. Circle the wrong word.<br />Write the correct word.'}],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; flex-direction: row;'>
            <div style='width: 80%;'>
              <div>1&nbsp;&nbsp;Trang has twenty postcards.</div>
              <div>2&nbsp;&nbsp;Her puppets are in a bathroom.</div>
              <div>3&nbsp;&nbsp;Her favorite puppets is a fish.</div>
              <div>4&nbsp;&nbsp;There are thirty pins in Anh's collection.</div>
              <div>5&nbsp;&nbsp;She wears her pins on her shirt.</div>
              <div>6&nbsp;&nbsp;She gives her brother some of her pins.</div>
            </div>
            <div style='width: 20%;'>
                <div><u style='color: gray;'>puppets</u></div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
            </div>
          </div>
        `,
        answer: [ "bedroom", "a dragon", "fifty", "jacket", "friends" ],
      },
    ],
  },
}

export default json;