import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "WB5-V2-U5-P35-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page35/E1/Key/answerKey.png",
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page35/E1/1.jpg",
    questionImage: [],
    questions: [
      {
        title: `
          <div>
            <div style='display: flex;'><div style='flex: 5;'>1&ensp;Dad is taking a photo.</div><div style='color: gray;flex: 1;'><u>yes</u></div></div>
            <div style='display: flex;'><div style='flex: 5;'>2&ensp;The crocodile is eating.</div><div style='flex: 1;'>#</div></div>
            <div style='display: flex;'><div style='flex: 5;'>3&ensp;The crocodile is sleeping.</div><div style='flex: 1;'>#</div></div>
            <div style='display: flex;'><div style='flex: 5;'>4&ensp;The boys are looking at the bird.</div><div style='flex: 1;'>#</div></div>
            <div style='display: flex;'><div style='flex: 5;'>5&ensp;The girl is eating a banana.</div><div style='flex: 1;'>#</div></div>
          </div>
        `,
        answer: ["no", "yes", "yes", "no"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "WB5-V2-U5-P35-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page35/E2/Key/answerKey.png",
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/3.jpg', input: true, multiLine: true, answer: "Are you watching TV?" },
        { url: 'img/FriendsPlus/Page35/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/7.jpg', input: true, multiLine: true, answer: "Are you listening to music?" },
        { url: 'img/FriendsPlus/Page35/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/9.jpg', input: true, multiLine: true, answer: "Are you reading comics?" },
        { url: 'img/FriendsPlus/Page35/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page35/E2/11.jpg' },
      ],
    ],
  },
};

export default json;
