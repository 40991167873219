import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'WB4-U6-P42-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page42/E1/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page42/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page42/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page42/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page42/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/7.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page42/E1/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page42/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/11.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page42/E1/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page42/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/15.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page42/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page42/E1/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page42/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/19.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page42/E1/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page42/E1/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page42/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/23.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page42/E1/25.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'WB4-U6-P42-E2',
    audio: 'img/FriendsPlus/Page42/Audio/audio-e2-p42.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page42/E2/Key/answerKey.png',
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page42/E2/1.jpg'},
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page42/E2/2.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/3.jpg', audioUrl: "img/FriendsPlus/Page42/Audio/tieude-e2-p42.mp3"},
        { url: 'img/FriendsPlus/Page42/E2/4.jpg'},
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page42/E2/5.jpg'},
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page42/E2/6.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/7.jpg', input: true, answer: "2"},
        { url: 'img/FriendsPlus/Page42/E2/8.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/9.jpg', input: true, answer: "6"},
        { url: 'img/FriendsPlus/Page42/E2/10.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/11.jpg', input: true, answer: "see"},
        { url: 'img/FriendsPlus/Page42/E2/12.jpg'},
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page42/E2/13.jpg'},
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page42/E2/14.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/15.jpg', input: true, answer: "8"},
        { url: 'img/FriendsPlus/Page42/E2/16.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/17.jpg', input: true, answer: "go"},
        { url: 'img/FriendsPlus/Page42/E2/18.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/19.jpg', input: true, answer: "5"},
        { url: 'img/FriendsPlus/Page42/E2/20.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/21.jpg', input: true, answer: "have"},
        { url: 'img/FriendsPlus/Page42/E2/22.jpg'},
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page42/E2/23.jpg'},
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page42/E2/24.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/25.jpg', input: true, answer: "7"},
        { url: 'img/FriendsPlus/Page42/E2/26.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/27.jpg', input: true, answer: "do"},
        { url: 'img/FriendsPlus/Page42/E2/28.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/29.jpg', input: true, answer: "3"},
        { url: 'img/FriendsPlus/Page42/E2/30.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/31.jpg', input: true, answer: "have"},
        { url: 'img/FriendsPlus/Page42/E2/32.jpg'},
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page42/E2/33.jpg'},
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page42/E2/34.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/35.jpg', input: true, answer: "get up"},
        { url: 'img/FriendsPlus/Page42/E2/36.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/37.jpg', input: true, answer: "4"},
        { url: 'img/FriendsPlus/Page42/E2/38.jpg'},
        { url: 'img/FriendsPlus/Page42/E2/39.jpg', input: true, answer: "catch"},
        { url: 'img/FriendsPlus/Page42/E2/40.jpg'},
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page42/E2/41.jpg'},
      ],      
    ],
  },
}

export default json;