import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'WB4-U10-P68-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page68/E1/Key/answerKey.png',
    // checkUppercase: true,
    padding: 0,
    maxLength: 1,
    textAlign: 'center',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page68/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page68/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/3.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page68/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page68/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/6.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page68/E1/7.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page68/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/9.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page68/E1/10.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page68/E1/11.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page68/E1/12.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page68/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/14.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page68/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/16.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page68/E1/17.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page68/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/19.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page68/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/21.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page68/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/23.jpg', input: true, answer: "o" },
        { url: 'img/FriendsPlus/Page68/E1/24.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page68/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/26.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page68/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/28.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page68/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/30.jpg', input: true, answer: "u" },
        { url: 'img/FriendsPlus/Page68/E1/31.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page68/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/33.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page68/E1/34.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page68/E1/35.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page68/E1/36.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page68/E1/37.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page68/E1/38.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page68/E1/39.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page68/E1/40.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page68/E1/41.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page68/E1/42.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page68/E1/43.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page68/E1/44.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/45.jpg', input: true, answer: "r" },
        { url: 'img/FriendsPlus/Page68/E1/46.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/47.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page68/E1/48.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/49.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page68/E1/50.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/51.jpg', input: true, answer: "t" },
        { url: 'img/FriendsPlus/Page68/E1/52.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page68/E1/53.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/54.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page68/E1/55.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/56.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page68/E1/57.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/58.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page68/E1/59.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page68/E1/60.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/61.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page68/E1/62.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/63.jpg', input: true, answer: "i" },
        { url: 'img/FriendsPlus/Page68/E1/64.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page68/E1/65.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/66.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page68/E1/67.jpg' },
        { url: 'img/FriendsPlus/Page68/E1/68.jpg', input: true, answer: "n" },
        { url: 'img/FriendsPlus/Page68/E1/69.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page68/E1/70.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: "Unit 10",
    id: "WB5-V2-U10-P68-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page68/E2/Key/answerKey.png",
    inputSize: 170,
    // titleQuestion: [{ color: 'black', num: '5', title: 'Read and complete with <i>ai</i> or <i>ay</i>.' }],
    titleImage: "img/FriendsPlus/Page68/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page68/E2/3.jpg" />
            </div>
            <div style='margin: 0 10px 0 20px;'>
              <div>
                <img src="img/FriendsPlus/Page68/E2/2.jpg" />
              </div>
              <div>
                <div>My name’s Xuan and I live near a <u style='color: gray;'>&ensp;park&ensp;</u>. This is a</div>
                <div>picture of me and my friend Giang. We’re walking on</div>
                <div>the #. There’s a cat in the #.</div>
                <div>There’s a wall around the park. There are #</div>
                <div>on the wall. They’re very beautiful! Here are some</div>
                <div>rules for the park. Can you see that? Someone left</div>
                <div>litter near the wall. You mustn’t leave any</div>
                <div>#. You must use a #.</div>
                <div>Also, there’s a dog playing in the #. You</div>
                <div>mustn’t let your dog play in the fountain.</div>
              </div>
            </div>
          </div>
        `,
        answer: ['path', 'tree', 'flowers', 'litter', 'garbage can', 'fountain'],
      },
    ]
  },
}
export default json;