import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P65-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page65/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Circle all the words that make sense.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white' },
        selectWordStyle: { borderColor: 'gray' },
        // limitSelect: 1,
        listWords: [
          'always / sometimes / never', //0
          'always / sometimes / never', //1
          'always / sometimes / never', //2
          'always / sometimes / never', //3
        ],
        answers: ['0-0', '0-4', '0-8', '1-4', '2-0', '2-4', '2-8', '3-4'],
        initialValue: [],
      },
      Layout: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'>
            <div><b>1</b>&ensp;... she watches TV in the evening.</div>
            <div><b>2</b>&ensp;He ... plays computer games at night.</div>
            <div><b>3</b>&ensp;He has a shower in the morning ...</div>
            <div><b>4</b>&ensp;He ... goes to the theater on Saturdays.</div>
            <div><b>5</b>&ensp;... they play basketball after school.</div>
          </div>
          <div style='margin-left: 10px;'>
            <div>always / <span style='border: 2px solid gray; border-radius: 50%;'>sometimes</span> / never</div>
            <div><input id='0' type='Circle' /></div>
            <div><input id='1' type='Circle' /></div>
            <div><input id='2' type='Circle' /></div>
            <div><input id='3' type='Circle' /></div>
          </div>
        </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P65-E2',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page65/E2/Key/answerKey.png',
    checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page65/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page65/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/3.jpg', input: true, answer: "rivers" },
        { url: 'img/FriendsPlus/Page65/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page65/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/6.jpg', input: true, answer: "volleyball" },
        { url: 'img/FriendsPlus/Page65/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page65/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/9.jpg', input: true, answer: "Saturdays" },
        { url: 'img/FriendsPlus/Page65/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page65/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/12.jpg', input: true, answer: "make" },
        { url: 'img/FriendsPlus/Page65/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page65/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/15.jpg', input: true, answer: "vacation" },
        { url: 'img/FriendsPlus/Page65/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page65/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/18.jpg', input: true, answer: "tomato" },
        { url: 'img/FriendsPlus/Page65/E2/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page65/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page65/E2/21.jpg', input: true, answer: "mall" },
        { url: 'img/FriendsPlus/Page65/E2/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page65/E2/23.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page65/E2/24.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: "Unit 9",
    id: "WB5-V2-U9-P65-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    // titleQuestion: [{ color: 'black', num: '1', title: 'Order the letters and write the word.' }],
    // titleImage: "img/FriendsPlus/Page64/E2/1.jpg",
    // checkUppercase: true,
    hideBtnFooter: true,
    textareaStyle: { width: 870 },
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: relative;'>
            <div>
              <img src="img/FriendsPlus/Page65/E3/1.jpg" />
              <img src="img/FriendsPlus/Page65/E3/2.jpg" />
            </div>
            <div style=" position: absolute; top: 150px; left: 420px;"><textarea id="0" rows="6"></textarea></div>
          </div>
        `,
        answer: [''],
      },
    ]
  },
}
export default json;