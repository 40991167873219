import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P92-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page92/E1/Key/answerKey.png",
    inputSize: 200,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page92/E1/1.jpg">
          <div style='margin-left:50px;'>
            <div><b>1</b> You have breakfast in the morning. You <u style='color: gray;'>&ensp;don’t have&ensp;</u> dinner in the morning.</div>
            <div><b>2</b> She doesn’t live in the U.S.A. She # in Brazil.</div>
            <div><b>3</b> We finish school at three o’clock. We # school at four o’clock.</div>
            <div><b>4</b> I don’t do my homework in the morning. I # my homework at night.</div>
            <div><b>5</b> He gets up at seven o’clock. He # at eight o’clock.</div>
            <div><b>6</b> They don’t walk to school. They # the bus.</div>
          </div>
        `,
        answer: ["lives", "don't finish", "do", "doesn't get up", "catch"],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Grammar Time',
    id: 'WB4-GT-P92-E2',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page92/E2/Key/answerKey.png',
    checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page92/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page92/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page92/E2/3.jpg', input: true, answer: "Do" },
        { url: 'img/FriendsPlus/Page92/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page92/E2/5.jpg', input: true, answer: "Yes, we do." },
        { url: 'img/FriendsPlus/Page92/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page92/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page92/E2/8.jpg', input: true, answer: "Does" },
        { url: 'img/FriendsPlus/Page92/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page92/E2/10.jpg', input: true, answer: "Yes, she does." },
        { url: 'img/FriendsPlus/Page92/E2/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page92/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page92/E2/13.jpg', input: true, answer: "Do" },
        { url: 'img/FriendsPlus/Page92/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page92/E2/15.jpg', input: true, answer: "No, they don't." },
        { url: 'img/FriendsPlus/Page92/E2/16.jpg' },
      ],
    ]
  },
}
export default json;