import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P12-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page12/E1/Key/answerKey.png",
    maxLength: 1,
    textAlign: 'center',
    padding: 0,
    isAllowSubmit: true,
    isHiddenCheck: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/E1/1.jpg'},
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page12/E1/2.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/3.jpg', input: true, answer: "5"},
        { url: 'img/FriendsPlus/Page12/E1/4.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/5.jpg', input: true, answer: "f"},
        { url: 'img/FriendsPlus/Page12/E1/6.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/7.jpg', input: true, answer: "l"},
        { url: 'img/FriendsPlus/Page12/E1/8.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/9.jpg', input: true, answer: "l"},
        { url: 'img/FriendsPlus/Page12/E1/10.jpg'},
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page12/E1/11.jpg'},
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page12/E1/12.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/13.jpg', input: true, answer: "2"},
        { url: 'img/FriendsPlus/Page12/E1/14.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/15.jpg', input: true, answer: "i"},
        { url: 'img/FriendsPlus/Page12/E1/16.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/17.jpg', input: true, answer: "n"},
        { url: 'img/FriendsPlus/Page12/E1/18.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/19.jpg', input: true, answer: "t"},
        { url: 'img/FriendsPlus/Page12/E1/20.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/21.jpg', input: true, answer: "e"},
        { url: 'img/FriendsPlus/Page12/E1/22.jpg'},
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page12/E1/23.jpg'},
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page12/E1/24.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/25.jpg', input: true, answer: "3"},
        { url: 'img/FriendsPlus/Page12/E1/26.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/27.jpg', input: true, answer: "s"},
        { url: 'img/FriendsPlus/Page12/E1/28.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/29.jpg', input: true, answer: "p"},
        { url: 'img/FriendsPlus/Page12/E1/30.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/31.jpg', input: true, answer: "i"},
        { url: 'img/FriendsPlus/Page12/E1/32.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/33.jpg', input: true, answer: "n"},
        { url: 'img/FriendsPlus/Page12/E1/34.jpg'},
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page12/E1/35.jpg'},
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page12/E1/36.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/37.jpg', input: true, answer: "4"},
        { url: 'img/FriendsPlus/Page12/E1/38.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/39.jpg', input: true, answer: "s"},
        { url: 'img/FriendsPlus/Page12/E1/40.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/41.jpg', input: true, answer: "u"},
        { url: 'img/FriendsPlus/Page12/E1/42.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/43.jpg', input: true, answer: "e"},
        { url: 'img/FriendsPlus/Page12/E1/44.jpg'},
        { url: 'img/FriendsPlus/Page12/E1/45.jpg', input: true, answer: "r"},
        { url: 'img/FriendsPlus/Page12/E1/46.jpg'},
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page12/E1/47.jpg'},
      ],      
    ]
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P12-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page12/E2/Key/answerKey.png",
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page12/E2/title.jpg",
    questionImage: [],
    questions: [
      {
        title: `
          <div>
            <div style='display: flex;'><div style='flex: 5;'>1&nbsp;&nbsp;There are two reasons in Ho Chi Minh City.</div><div style='color: gray;flex: 1;'><u>yes</u></div></div>
            <div style='display: flex;'><div style='flex: 5;'>2&nbsp;&nbsp;There are four seasons in Ha Noi.</div><div style='flex: 1;'>#</div></div>
            <div style='display: flex;'><div style='flex: 5;'>3&nbsp;&nbsp;Rain comes very slowly in Ho Chi Minh City.</div><div style='flex: 1;'>#</div></div>
            <div style='display: flex;'><div style='flex: 5;'>4&nbsp;&nbsp;It's alway dry in summer in Ha Noi.</div><div style='flex: 1;'>#</div></div>
            <div style='display: flex;'><div style='flex: 5;'>5&nbsp;&nbsp;The weather in Ho Chi Minh City is always hot.</div><div style='flex: 1;'>#</div></div>
            <div style='display: flex;'><div style='flex: 5;'>6&nbsp;&nbsp;The weather in Ha Noi is always cold.</div><div style='flex: 1;'>#</div></div>
          </div>
        `,
        answer: ["yes", "no", "no", "yes", "no"],
      },
    ],
  },
};

export default json;
