import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'WB4-U11-P79-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page79/E1/Key/answerKey.png',
    // checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page79/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page79/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page79/E1/3.jpg', input: true, answer: "food" },
        { url: 'img/FriendsPlus/Page79/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page79/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page79/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page79/E1/7.jpg', input: true, answer: "tree" },
        { url: 'img/FriendsPlus/Page79/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page79/E1/9.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'WB4-U11-P79-E2',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page79/E2/Key/answerKey.png',
    // checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page79/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page79/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page79/E2/3.jpg', input: true, answer: "was" },
        { url: 'img/FriendsPlus/Page79/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page79/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page79/E2/6.jpg', input: true, answer: "weren't" },
        { url: 'img/FriendsPlus/Page79/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page79/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page79/E2/9.jpg', input: true, answer: "were" },
        { url: 'img/FriendsPlus/Page79/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page79/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page79/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page79/E2/13.jpg', input: true, answer: "are" },
        { url: 'img/FriendsPlus/Page79/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page79/E2/15.jpg', input: true, answer: "is" },
        { url: 'img/FriendsPlus/Page79/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page79/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page79/E2/18.jpg', input: true, answer: "isn't" },
        { url: 'img/FriendsPlus/Page79/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page79/E2/20.jpg', input: true, answer: "aren't" },
        { url: 'img/FriendsPlus/Page79/E2/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page79/E2/22.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: "Unit 11",
    id: "WB5-V2-U11-P79-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page79/E3/Key/answerKey.png",
    // inputSize: 50,
    // titleQuestion: [{ color: 'black', num: '3', title: 'Read and complete with <i>ai</i> or <i>ay</i>.' }],
    // titleImage: "img/FriendsPlus/Page79/E2/1.jpg",
    questionImage: [],
    hideBtnFooter: true,
    textareaStyle: { width: '100%', resize: 'none' },
    questions: [
      {
        title: `
        <div style='position: relative;'>
          <div>
            <img src="img/FriendsPlus/Page79/E3/1.jpg">
            <img src="img/FriendsPlus/Page79/E3/2.jpg">
          </div>
          <div style=" position: absolute; top: 113px; left: 80px;width:855px;"> <textarea id="0" rows="3">Twenty years ago,</textarea> </div>
          <div style=" position: absolute; top: 234px; left: 80px;width:855px;"> <textarea id="1" rows="3">Now,</textarea> </div>
        </div>
        `,
        answer: [],
      },
    ]
  },
}
export default json;