import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: "Unit 6",
    id: "WB5-V2-U6-P45-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page45/E1/Key/answerKey.png",
    inputSize: 400,
    titleQuestion: [{ color: 'black', num: '1', title: 'Rewrite the sentence. Use capital letters and punctuation marks.<br />Remember the proper nouns.' }],
    titleImage: "",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
              <div style='display: flex;'>
                <div style='flex: 2;margin-right: 10px;'>
                  <div>1&ensp;my favorite movie is toy story</div>
                  <div>2&ensp;kim lives in ho chi minh city</div>
                  <div>3&ensp;mr khoa is their teacher</div>
                  <div>4&ensp;mai is huy’s cousin</div>
                </div>
                <div style='flex: 2;margin-left: 10px;'>
                  <div><u style='color: gray;'>&ensp;My favorite movie is Toy Story.&ensp;</u></div>
                  <div>#</div>
                  <div>#</div>
                  <div>#</div>
                </div>
              </div>
            `,
        answer: ["Alice lives in Ho Chi Minh City.", "Mr. Khoa is their teacher.", "Mai is Huy's cousin."],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'WB4-U6-P45-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 8,
    exerciseKey: 'img/FriendsPlus/Page45/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page45/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page45/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page45/E2/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page45/E2/4.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page45/E2/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page45/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page45/E2/7.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page45/E2/8.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page45/E2/9.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page45/E2/10.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page45/E2/11.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page45/E2/12.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page45/E2/13.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page45/E2/14.jpg', input: 11 },
        { url: 'img/FriendsPlus/Page45/E2/15.jpg', input: 12 },
        { url: 'img/FriendsPlus/Page45/E2/16.jpg', input: 13 },
        { url: 'img/FriendsPlus/Page45/E2/17.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page45/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page45/E2/19.jpg', input: 14 },
        { url: 'img/FriendsPlus/Page45/E2/20.jpg', input: 15 },
        { url: 'img/FriendsPlus/Page45/E2/21.jpg', input: 16 },
        { url: 'img/FriendsPlus/Page45/E2/22.jpg', input: 17 },
        { url: 'img/FriendsPlus/Page45/E2/23.jpg', input: 18, isCorrect: true },
        { url: 'img/FriendsPlus/Page45/E2/24.jpg', input: 19 },
        { url: 'img/FriendsPlus/Page45/E2/25.jpg', input: 20 },
        { url: 'img/FriendsPlus/Page45/E2/26.jpg', input: 21 },
        { url: 'img/FriendsPlus/Page45/E2/27.jpg', input: 22 },
        { url: 'img/FriendsPlus/Page45/E2/28.jpg', input: 23 },
        { url: 'img/FriendsPlus/Page45/E2/29.jpg', input: 24 },
        { url: 'img/FriendsPlus/Page45/E2/30.jpg', input: 25 },
        { url: 'img/FriendsPlus/Page45/E2/31.jpg', input: 26, isCorrect: true },
        { url: 'img/FriendsPlus/Page45/E2/32.jpg', input: 27 },
        { url: 'img/FriendsPlus/Page45/E2/33.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page45/E2/34.jpg' },
        { url: 'img/FriendsPlus/Page45/E2/35.jpg', input: 28 },
        { url: 'img/FriendsPlus/Page45/E2/36.jpg', input: 29 },
        { url: 'img/FriendsPlus/Page45/E2/37.jpg', input: 30 },
        { url: 'img/FriendsPlus/Page45/E2/38.jpg', input: 31 },
        { url: 'img/FriendsPlus/Page45/E2/39.jpg', input: 32, isCorrect: true },
        { url: 'img/FriendsPlus/Page45/E2/40.jpg', input: 33 },
        { url: 'img/FriendsPlus/Page45/E2/41.jpg', input: 34 },
        { url: 'img/FriendsPlus/Page45/E2/42.jpg', input: 35 },
        { url: 'img/FriendsPlus/Page45/E2/43.jpg', input: 36 },
        { url: 'img/FriendsPlus/Page45/E2/44.jpg', input: 37 },
        { url: 'img/FriendsPlus/Page45/E2/45.jpg', input: 38 },
        { url: 'img/FriendsPlus/Page45/E2/46.jpg', input: 39 },
        { url: 'img/FriendsPlus/Page45/E2/47.jpg', input: 40 },
        { url: 'img/FriendsPlus/Page45/E2/48.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page45/E2/49.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page45/E2/50.jpg' },
        { url: 'img/FriendsPlus/Page45/E2/51.jpg', input: 41 },
        { url: 'img/FriendsPlus/Page45/E2/52.jpg', input: 42 },
        { url: 'img/FriendsPlus/Page45/E2/53.jpg', input: 43 },
        { url: 'img/FriendsPlus/Page45/E2/54.jpg', input: 44 },
        { url: 'img/FriendsPlus/Page45/E2/55.jpg', input: 45, isCorrect: true },
        { url: 'img/FriendsPlus/Page45/E2/56.jpg', input: 46 },
        { url: 'img/FriendsPlus/Page45/E2/57.jpg', input: 47 },
        { url: 'img/FriendsPlus/Page45/E2/58.jpg', input: 48 },
        { url: 'img/FriendsPlus/Page45/E2/59.jpg', input: 49 },
        { url: 'img/FriendsPlus/Page45/E2/60.jpg', input: 50 },
        { url: 'img/FriendsPlus/Page45/E2/61.jpg', input: 51, isCorrect: true },
        { url: 'img/FriendsPlus/Page45/E2/62.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page45/E2/63.jpg' },
        { url: 'img/FriendsPlus/Page45/E2/64.jpg', input: 52 },
        { url: 'img/FriendsPlus/Page45/E2/65.jpg', input: 53 },
        { url: 'img/FriendsPlus/Page45/E2/66.jpg', input: 54 },
        { url: 'img/FriendsPlus/Page45/E2/67.jpg', input: 55 },
        { url: 'img/FriendsPlus/Page45/E2/68.jpg', input: 56, isCorrect: true },
        { url: 'img/FriendsPlus/Page45/E2/69.jpg', input: 57 },
        { url: 'img/FriendsPlus/Page45/E2/70.jpg', input: 58 },
        { url: 'img/FriendsPlus/Page45/E2/71.jpg', input: 59 },
        { url: 'img/FriendsPlus/Page45/E2/72.jpg', input: 60 },
        { url: 'img/FriendsPlus/Page45/E2/73.jpg', input: 61 },
        { url: 'img/FriendsPlus/Page45/E2/74.jpg', input: 62 },
        { url: 'img/FriendsPlus/Page45/E2/75.jpg', input: 63 },
        { url: 'img/FriendsPlus/Page45/E2/76.jpg', input: 64 },
        { url: 'img/FriendsPlus/Page45/E2/77.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page45/E2/78.jpg' },
        { url: 'img/FriendsPlus/Page45/E2/79.jpg', input: 65 },
        { url: 'img/FriendsPlus/Page45/E2/80.jpg', input: 66 },
        { url: 'img/FriendsPlus/Page45/E2/81.jpg', input: 67, isCorrect: true },
        { url: 'img/FriendsPlus/Page45/E2/82.jpg', input: 68 },
        { url: 'img/FriendsPlus/Page45/E2/83.jpg', input: 69 },
        { url: 'img/FriendsPlus/Page45/E2/84.jpg', input: 70 },
        { url: 'img/FriendsPlus/Page45/E2/85.jpg', input: 71 },
        { url: 'img/FriendsPlus/Page45/E2/86.jpg', input: 72, isCorrect: true },
        { url: 'img/FriendsPlus/Page45/E2/87.jpg', input: 73 },
        { url: 'img/FriendsPlus/Page45/E2/88.jpg', input: 74 },
        { url: 'img/FriendsPlus/Page45/E2/89.jpg', input: 75 },
        { url: 'img/FriendsPlus/Page45/E2/90.jpg', input: 76 },
        { url: 'img/FriendsPlus/Page45/E2/91.jpg', input: 77 },
        { url: 'img/FriendsPlus/Page45/E2/92.jpg', input: 78 },
        { url: 'img/FriendsPlus/Page45/E2/93.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page45/E2/94.jpg' },
        { url: 'img/FriendsPlus/Page45/E2/95.jpg', input: 79 },
        { url: 'img/FriendsPlus/Page45/E2/96.jpg', input: 80 },
        { url: 'img/FriendsPlus/Page45/E2/97.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page45/E2/98.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page45/E2/99.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'WB4-U6-P45-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: '',
    inputHeight: 215,
    hideBtnFooter: true,
    // checkUppercase: true,
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page45/E3/1.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page45/E3/2.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page45/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page45/E3/4.jpg', input: true, multiLine: true, rows: 6, answer: '' },
        { url: 'img/FriendsPlus/Page45/E3/5.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page45/E3/6.jpg' },
      ],
    ],
  },
}

export default json;