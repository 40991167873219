import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Grammar Time',
    id: 'WB4-GT-P89-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page89/E1/Key/answerKey.png',
    // checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page89/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page89/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/3.jpg', input: true, answer: "likes" },
        { url: 'img/FriendsPlus/Page89/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page89/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/6.jpg', input: true, answer: "like" },
        { url: 'img/FriendsPlus/Page89/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page89/E1/8.jpg', input: true, answer: "don't like" },
        { url: 'img/FriendsPlus/Page89/E1/9.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Grammar Time',
    id: 'WB4-GT-P89-E2',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page89/E2/Key/answerKey.png',
    checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page89/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page89/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page89/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page89/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/6.jpg', input: true, answer: "Do" },
        { url: 'img/FriendsPlus/Page89/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/8.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page89/E2/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page89/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/11.jpg', input: true, answer: "Does" },
        { url: 'img/FriendsPlus/Page89/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/13.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page89/E2/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page89/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/16.jpg', input: true, answer: "Do" },
        { url: 'img/FriendsPlus/Page89/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/18.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page89/E2/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page89/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/21.jpg', input: true, answer: "Do" },
        { url: 'img/FriendsPlus/Page89/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/23.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page89/E2/24.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page89/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/26.jpg', input: true, answer: "Do" },
        { url: 'img/FriendsPlus/Page89/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page89/E2/28.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page89/E2/29.jpg' },
      ],
    ]
  },
}
export default json;