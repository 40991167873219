import D1 from '../../components/ExcerciseTypes/Design/TypeIn'

const json = {
  1: {
    // Exercise num
    unit: 'Starter',
    id: 'WB4-S-P6-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page6/E1/Key/answerKey.png',
    isAllowSubmit: false,
    question: [],
    questionImage: [
      [{ url: 'img/FriendsPlus/Page6/E1/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page6/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/3.jpg', input: true, answer: 'ebruary' },
        { url: 'img/FriendsPlus/Page6/E1/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/6.jpg', input: true, answer: 'pril' },
        { url: 'img/FriendsPlus/Page6/E1/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/9.jpg', input: true, answer: 'ay' },
        { url: 'img/FriendsPlus/Page6/E1/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/12.jpg', input: true, answer: 'uly' },
        { url: 'img/FriendsPlus/Page6/E1/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/15.jpg', input: true, answer: 'eptember' },
        { url: 'img/FriendsPlus/Page6/E1/16.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/18.jpg', input: true, answer: 'ovember' },
        { url: 'img/FriendsPlus/Page6/E1/19.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/21.jpg', input: true, answer: 'ecember' },
        { url: 'img/FriendsPlus/Page6/E1/22.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page6/E1/23.jpg' }],
    ],
  },
  2: {
    // Exercise num
    unit: 'Starter',
    id: 'WB4-S-P6-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page6/E2/Key/answerKey.png',
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [{ url: 'img/FriendsPlus/Page6/E2/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page6/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/3.jpg', input: true, answer: 'November' },
        { url: 'img/FriendsPlus/Page6/E2/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/6.jpg', input: true, answer: 'July' },
        { url: 'img/FriendsPlus/Page6/E2/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/9.jpg', input: true, answer: 'February' },
        { url: 'img/FriendsPlus/Page6/E2/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/12.jpg', input: true, answer: 'September' },
        { url: 'img/FriendsPlus/Page6/E2/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/15.jpg', input: true, answer: 'April' },
        { url: 'img/FriendsPlus/Page6/E2/16.jpg' },
      ],
      [{ url: 'img/FriendsPlus/Page6/E2/17.jpg' }],
    ],
  },
  3: {
    // Exercise num
    unit: 'Starter',
    id: 'WB4-S-P6-E3',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: '',
    isAllowSubmit: false,
    hideBtnFooter: true,
    questionImage: [
      [{ url: 'img/FriendsPlus/Page6/E3/1.jpg' }],
      [
        { url: 'img/FriendsPlus/Page6/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/3.jpg', input: true, answer: '' },
        { url: 'img/FriendsPlus/Page6/E3/4.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/6.jpg', input: true, answer: '' },
        { url: 'img/FriendsPlus/Page6/E3/7.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/9.jpg', input: true, answer: '' },
        { url: 'img/FriendsPlus/Page6/E3/10.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/12.jpg', input: true, answer: '' },
        { url: 'img/FriendsPlus/Page6/E3/13.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page6/E3/14.jpg' },
      ],
    ],
  },
};

export default json;
