import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P95-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page95/E1/Key/answerKey.png",
    inputSize: 120,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page95/E1/1.jpg">
          <div style='margin-left:50px;'>
            <div><b>1</b> You <u style='color:gray;'>&ensp;must&ensp;</u> listen to the teacher.</div>
            <div><b>2</b> You # read comics at school.</div>
            <div><b>3</b> You # talk in class.</div>
            <div><b>4</b> You # ride your bike in the playground.</div>
            <div><b>5</b> You # do your homework.</div>
          </div>
        `,
        answer: ["mustn't", "mustn't", "mustn't", 'must'],
      },
    ]
  },
  2: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P95-E2",
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page95/E2/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Look and circle the correct word.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white', fontWeight: 'bold' },
        selectWordStyle: { borderColor: 'gray' },
        limitSelect: 1,
        listWords: [
          'wasn’t / weren’t', //0
          'was / were', //1
          'was / were', //2
          'was / were', //3
          'wasn’t / weren’t', //4
          'was / were', //5
          'wasn’t / weren’t', //6
        ],
        answers: ['3-0', '0-0', '4-4', '1-0', '5-0', '2-4', '6-0'],
        initialValue: [],
      },
      Layout: `
        <img src="img/FriendsPlus/Page95/E2/1.jpg">
        <div style='display: flex;margin-left:50px;'>
          <div style='margin-right:10px;'>
            <div><b>1</b> There <span style='font-weight: bold;'><span style='border: 2px solid gray; border-radius: 50%;'>were</span> / was</span> lots of flowers.</div>
            <div><b>3</b> There <input id='0' type='Circle' /> a fountain.</div>
            <div><b>5</b> There <input id='1' type='Circle' /> a playground.</div>
            <div><b>7</b> There <input id='2' type='Circle' /> lots of trees.</div>
          </div>
          <div style='margin-left:10px;'>
            <div><b>2</b> There <input id='3' type='Circle' /> a river.</div>
            <div><b>4</b> There <input id='4' type='Circle' /> any buses.</div>
            <div><b>6</b> There <input id='5' type='Circle' /> a park.</div>
            <div><b>8</b> There <input id='6' type='Circle' /> a museum.</div>
          </div>
        </div>
      `,
    },
  },
}
export default json;