import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P25-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page25/E1/Key/answerKey.png',
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page25/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page25/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/3.jpg', input: true, answer: "I like cooking, shopping, and fishing." },
        { url: 'img/FriendsPlus/Page25/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page25/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/6.jpg', input: true, answer: "Do you have a camera?" },
        { url: 'img/FriendsPlus/Page25/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page25/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page25/E1/9.jpg', input: true, answer: "I have a CD player, a camera, and a TV." },
        { url: 'img/FriendsPlus/Page25/E1/10.jpg' },
      ],
    ]
  },

  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P25-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page25/E2/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Circle the correct word. Then write a short answer.', color: "#5B5A5D" }],
    question: {
      Write: {
        checkUppercase: true,
        isHiddenCheck: true,
        inputStyle: { width: 30, color: 'black' },
        answers: ['.', ',', ',', ',', '.', '.', '.', '?'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { borderColor: 'transparent', borderRadius: '50%', backgroundColor: 'white' },
        selectWordStyle: { borderColor: 'gray', border: 'solid 2px' },
        // limitSelect: 1,
        listWords: [
          "have a collection of postcards",	//0
          "I h|ave 70 p|ostcards",	//1
          "T|hey a|re f|rom ",	//2
          "t|he U.|S.|A.",	//3
          "T|hailand",	//4
          "A|ustralia ",	//5
          "a|nd f|rom m|y c|ountry‚ t|oo",	//6
          "M|y f|avorite p|ostcard i|s f|rom A|ustralia ",	//7
          "I|t’s a k|angaroo",	//8
          "D|o y|ou h|ave ",	//9
          "a|ny p|ostcards",	//10
        ],
        answers: ['1-0', '2-0', '3-4', '3-6', '3-8', '4-0', '5-0', '7-0', '7-20', '8-0', '9-0'],
        initialValue: [],
      },
      Layout: `
        <div style='position:relative;'>
          <div>
            <img src='img/FriendsPlus/Page25/E2/background/1.jpg' />
          </div>
          <div style='position:absolute;top:125px;right:70px;font-size:20px;line-height:38px;'>
            <div><span style=" border: 2px solid gray; border-radius: 50%; background-color: white;">I</span> <input id='0' type='Circle' /> <sup>1</sup><u style='color:gray;'>&ensp;.&ensp;</u></div>
            <di><input id='1' type='Circle' /> <sup>2</sup> <input id='0' /> <input id='2' type='Circle' />
            <div><input id='3' type='Circle' /> <sup>3</sup> <input id='1' /> <input id='4' type='Circle' /> <sup>4</sup> <input id='2' /> <input id='5' type='Circle' /></div>
            <div><sup>5</sup> <input id='3' /> <input id='6' type='Circle' /> <sup>6</sup> <input id='4' /></div>
            <div><input id='7' type='Circle' /></div>
            <div><sup>7</sup> <input id='5' /> <input id='8' type='Circle' /> <sup>8</sup> <input id='6' /> <input id='9' type='Circle' /></div>
            <div><input id='10' type='Circle' /> <sup>9</sup> <input id='7' /></div>
          </div>
        </div>
      `,
    },
  },
  // 2: { // Exercise num
  //   unit: 'Unit 3',
  //   id: 'WB4-U3-P25-E2',
  //   audio: '',
  //   video: '',
  //   component: D1,
  //   // fontSize: 25,
  //   textAlign: 'center',
  //   maxLength: 1,
  //   padding: 0,
  //   exerciseKey: 'img/FriendsPlus/Page25/E2/Key/answerKey.png',
  //   isAllowSubmit: true,
  //   question: [],
  //   questionImage: [ // Row
  //     [
  //       // Column1
  //       { url: 'img/FriendsPlus/Page25/E2/1.jpg' },
  //     ],
  //     [
  //       // Column2
  //       { url: 'img/FriendsPlus/Page25/E2/2.jpg' },
  //       { url: 'img/FriendsPlus/Page25/E2/3.jpg', input: true, answer: "." },
  //       { url: 'img/FriendsPlus/Page25/E2/4.jpg' },
  //     ],
  //     [
  //       // Column3
  //       { url: 'img/FriendsPlus/Page25/E2/5.jpg' },
  //     ],
  //     [
  //       // Column4
  //       { url: 'img/FriendsPlus/Page25/E2/6.jpg' },
  //       { url: 'img/FriendsPlus/Page25/E2/7.jpg', input: true, answer: "," },
  //       { url: 'img/FriendsPlus/Page25/E2/8.jpg' },
  //       { url: 'img/FriendsPlus/Page25/E2/9.jpg', input: true, answer: "," },
  //       { url: 'img/FriendsPlus/Page25/E2/10.jpg' },
  //     ],
  //     [
  //       // Column5
  //       { url: 'img/FriendsPlus/Page25/E2/11.jpg' },
  //     ],
  //     [
  //       // Column6
  //       { url: 'img/FriendsPlus/Page25/E2/12.jpg' },
  //       { url: 'img/FriendsPlus/Page25/E2/13.jpg', input: true, answer: "," },
  //       { url: 'img/FriendsPlus/Page25/E2/14.jpg' },
  //       { url: 'img/FriendsPlus/Page25/E2/15.jpg', input: true, answer: "." },
  //       { url: 'img/FriendsPlus/Page25/E2/16.jpg' },
  //     ],
  //     [
  //       // Column7
  //       { url: 'img/FriendsPlus/Page25/E2/17.jpg' },
  //     ],
  //     [
  //       // Column8
  //       { url: 'img/FriendsPlus/Page25/E2/18.jpg' },
  //       { url: 'img/FriendsPlus/Page25/E2/19.jpg', input: true, answer: "." },
  //       { url: 'img/FriendsPlus/Page25/E2/20.jpg' },
  //       { url: 'img/FriendsPlus/Page25/E2/21.jpg', input: true, answer: "." },
  //       { url: 'img/FriendsPlus/Page25/E2/22.jpg' },
  //     ],
  //     [
  //       // Column9
  //       { url: 'img/FriendsPlus/Page25/E2/23.jpg' },
  //     ],
  //     [
  //       // Column10
  //       { url: 'img/FriendsPlus/Page25/E2/24.jpg' },
  //       { url: 'img/FriendsPlus/Page25/E2/25.jpg', input: true, answer: "?" },
  //       { url: 'img/FriendsPlus/Page25/E2/26.jpg' },
  //     ],
  //     [
  //       // Column11
  //       { url: 'img/FriendsPlus/Page25/E2/27.jpg' },
  //     ],
  //     [
  //       // Column12
  //       { url: 'img/FriendsPlus/Page25/E2/28.jpg' },
  //     ],
  //   ],
  // },

  3: { // Exercise num
    unit: "Unit 3",
    id: "WB5-V2-U3-P25-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    inputSize: '100%',
    // titleQuestion: [{ color: 'black', num: '3', title: 'Read and complete with <i>ai</i> or <i>ay</i>.' }],
    // titleImage: "img/FriendsPlus/Page79/E2/1.jpg",
    stylesTextInput: { background: 'transparent', border: 'none' },
    questionImage: [],
    hideBtnFooter: true,
    textareaStyle: { width: '100%', resize: 'none', lineHeight: '1.9em', background: 'transparent' },
    questions: [
      {
        title: `
          <div style='position: relative;'>
            <div>
              <img src="img/FriendsPlus/Page25/E3/1.jpg">
            </div>
            <div style=" position: absolute; top: 153px; left: 145px;width:380px;"> <textarea id="0" rows="1"></textarea> </div>
            <div style=" position: absolute; top: 199px; left: 146px;width:312px;"> <textarea id="1" rows="1"></textarea> </div>
            <div style=" position: absolute; top: 245px; left: 70px;width:450px;"> <textarea id="2" rows="2"></textarea> </div>
            <div style=" position: absolute; top: 337px; left: 70px;width:450px;"> <textarea id="3" rows="2" textindent="120"></textarea> </div>
          </div>
        `,
        answer: ['','','',''],
      },
    ]
  },
}

export default json;