import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P18-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page18/E1/Key/answerKey.png",
    isAllowSubmit: false,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page18/E1/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page18/E1/2.jpg" },
        { url: "img/FriendsPlus/Page18/E1/3.jpg", input: true, answer: "shop" },
        { url: "img/FriendsPlus/Page18/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page18/E1/5.jpg",
          input: true,
          answer: "visit family",
        },
        { url: "img/FriendsPlus/Page18/E1/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E1/7.jpg" }],
      [
        // Column4
        { url: "img/FriendsPlus/Page18/E1/8.jpg" },
        {
          url: "img/FriendsPlus/Page18/E1/9.jpg",
          input: true,
          answer: "play the guitar",
        },
        { url: "img/FriendsPlus/Page18/E1/10.jpg" },
        {
          url: "img/FriendsPlus/Page18/E1/11.jpg",
          input: true,
          answer: "play the piano",
        },
        { url: "img/FriendsPlus/Page18/E1/12.jpg" },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "WB5-V2-U2-P18-E2",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: "img/FriendsPlus/Page18/E2/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      [{ url: "img/FriendsPlus/Page18/E2/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page18/E2/2.jpg" },
        { url: "img/FriendsPlus/Page18/E2/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page18/E2/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page18/E2/5.jpg" },
        { url: "img/FriendsPlus/Page18/E2/6.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E2/7.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page18/E2/8.jpg" },
        { url: "img/FriendsPlus/Page18/E2/9.jpg", input: 1 },
        { url: "img/FriendsPlus/Page18/E2/10.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E2/11.jpg" }],
      [
        // Column6
        { url: "img/FriendsPlus/Page18/E2/12.jpg" },
        { url: "img/FriendsPlus/Page18/E2/13.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E2/14.jpg" },
        { url: "img/FriendsPlus/Page18/E2/15.jpg", input: 3 },
        { url: "img/FriendsPlus/Page18/E2/16.jpg" },
      ],
      [
        // Column7
        { url: "img/FriendsPlus/Page18/E2/17.jpg" },
        { url: "img/FriendsPlus/Page18/E2/18.jpg", input: 2 },
        { url: "img/FriendsPlus/Page18/E2/19.jpg" },
        { url: "img/FriendsPlus/Page18/E2/20.jpg", input: 3 },
        { url: "img/FriendsPlus/Page18/E2/21.jpg" },
      ],
      [
        // Column8
        { url: "img/FriendsPlus/Page18/E2/22.jpg" },
        { url: "img/FriendsPlus/Page18/E2/23.jpg", input: 2 },
        { url: "img/FriendsPlus/Page18/E2/24.jpg" },
        { url: "img/FriendsPlus/Page18/E2/25.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E2/26.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page18/E2/27.jpg" }],
      [
        // Column10
        { url: "img/FriendsPlus/Page18/E2/28.jpg" },
        { url: "img/FriendsPlus/Page18/E2/29.jpg", input: 4 },
        { url: "img/FriendsPlus/Page18/E2/30.jpg" },
        { url: "img/FriendsPlus/Page18/E2/31.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E2/32.jpg" },
      ],
      [
        // Column11
        { url: "img/FriendsPlus/Page18/E2/33.jpg" },
        { url: "img/FriendsPlus/Page18/E2/34.jpg", input: 4 },
        { url: "img/FriendsPlus/Page18/E2/35.jpg" },
        { url: "img/FriendsPlus/Page18/E2/36.jpg", input: 5 },
        { url: "img/FriendsPlus/Page18/E2/37.jpg" },
      ],
      [
        // Column12
        { url: "img/FriendsPlus/Page18/E2/38.jpg" },
        { url: "img/FriendsPlus/Page18/E2/39.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page18/E2/40.jpg" },
        { url: "img/FriendsPlus/Page18/E2/41.jpg", input: 5 },
        { url: "img/FriendsPlus/Page18/E2/42.jpg" },
      ],
    ],
  },
};

export default json;
