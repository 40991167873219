import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P20-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page20/E1/Key/answerKey.png',
    isAllowSubmit: false,
    maxLength: 1,
    textAlign: 'center',
    padding: 0,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page20/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page20/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/3.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page20/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page20/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page20/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/7.jpg', input: true, answer: "6" },
        { url: 'img/FriendsPlus/Page20/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page20/E1/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page20/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/11.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page20/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page20/E1/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page20/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/15.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page20/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page20/E1/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page20/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/19.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page20/E1/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page20/E1/21.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page20/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page20/E1/23.jpg', input: true, answer: "7" },
        { url: 'img/FriendsPlus/Page20/E1/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page20/E1/25.jpg' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB3-U3-P20-E2',
    audio: 'img/FriendsPlus/Page20/Audio/audio-e2-p20.mp3',
    video: '',
    component: D1,
    exerciseKey: "img/FriendsPlus/Page20/E2/Key/answerKey.png",
    isAllowSubmit: true,
    checkUppercase: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page20/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/2.jpg', audioUrl: "img/FriendsPlus/Page20/Audio/tieude-e2-p20.mp3", },
        { url: 'img/FriendsPlus/Page20/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page20/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page20/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/6.jpg', input: true, answer: "CD player" },
        { url: 'img/FriendsPlus/Page20/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page20/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/9.jpg', input: true, answer: "camera" },
        { url: 'img/FriendsPlus/Page20/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page20/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/12.jpg', input: true, answer: "MP3 player" },
        { url: 'img/FriendsPlus/Page20/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page20/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/15.jpg', input: true, answer: "DVD player" },
        { url: 'img/FriendsPlus/Page20/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page20/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page20/E2/18.jpg', input: true, answer: "Turn off" },
        { url: 'img/FriendsPlus/Page20/E2/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page20/E2/20.jpg' },
      ],
    ]
  },
}

export default json;