import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'WB4-U12-P84-E1',
    audio: 'img/FriendsPlus/Page84/Audio/audio-e1-p84.mp3',
    video: '',
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page84/E1/Key/answerKey.png",
    titleQuestion: [{ num: '1', title: 'Listen and draw lines. <headphone name="27" src="img/FriendsPlus/Page84/Audio/tieude-e1-p84.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          { boxMatchStyle: { position: 'absolute', top: '28px', left: '328px', width: 90, height: 40, border: '1px dashed black' }, }, //0
          { boxMatchStyle: { position: 'absolute', top: '25px', left: '489px', width: 90, height: 40, border: '1px dashed black' }, }, //1
          { boxMatchStyle: { position: 'absolute', top: '29px', left: '656px', width: 90, height: 40, border: '1px dashed black' }, }, //2
          { boxMatchStyle: { position: 'absolute', top: '389px', left: '596px', width: 90, height: 40, border: '1px dashed black' }, }, //3

          { boxMatchStyle: { position: 'absolute', top: '141px', left: '386px', width: 44, height: 98, border: '1px dashed black' }, }, //4
          { boxMatchStyle: { position: 'absolute', top: '144px', left: '676px', width: 36, height: 74, border: '1px dashed black' }, }, //5
          { boxMatchStyle: { position: 'absolute', top: '264px', left: '315px', width: 86, height: 136, border: '1px dashed black' }, }, //6
          { boxMatchStyle: { position: 'absolute', top: '252px', left: '429px', width: 37, height: 155, border: '1px dashed black' }, }, //7
        ],
        answers: ['0-5', '1-6', '2-4', '3-7'],
        initialValue: [],
      },
      Layout: `
        <img src='img/FriendsPlus/Page84/E1/1.jpg' />
        <input id='0' type= 'boxMatch' />
        <input id='1' type= 'boxMatch' />

        <input id='2' type= 'boxMatch' />
        <input id='3' type= 'boxMatch' />

        <input id='4' type= 'boxMatch' />
        <input id='5' type= 'boxMatch' />

        <input id='6' type= 'boxMatch' />
        <input id='7' type= 'boxMatch' />
      `,
    },
  },
  2: { // Exercise num
    unit: "Unit 12",
    id: "WB5-V2-U12-P84-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page84/E2/Key/answerKey.png",
    inputSize: 50,
    maxLength: 1,
    titleQuestion: [{ color: 'black', num: '2', title: 'Read the text in the Student Book. Write T (true) or F (false).' }],
    // titleImage: "img/FriendsPlus/Page84/E2/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;align-items: center;'>
            <div>
              <img src="img/FriendsPlus/Page84/E2/1.jpg">
            </div>
            <div style='margin: 0 20px;flex:1;'>
              <div><b>1</b> When my grandma was ten, her eyes were green.</div>
              <div><b>2</b> When my grandma was ten, she had short, brown hair.</div>
              <div><b>3</b> When my grandma was ten, she was pretty.</div>
            </div>
            <div>
              <div>&nbsp;<u style='color: gray;'>&ensp;F&ensp;</u></div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
          <div style='display: flex;align-items: center;margin: 20px 0;'>
            <div>
              <img src="img/FriendsPlus/Page84/E2/2.jpg">
            </div>
            <div style='margin: 0 20px;flex:1;'>
              <div><b>4</b> When my grandma was twenty-two, she had her wedding day.</div>
              <div><b>5</b> When my grandma was twenty-two, she was very pretty.</div>
              <div><b>6</b> When my grandma was twenty-two, she had a baby girl.</div>
            </div>
            <div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
          <div style='display: flex;align-items: center;'>
            <div>
              <img src="img/FriendsPlus/Page84/E2/3.jpg">
            </div>
            <div style='margin: 0 20px;flex:1;'>
              <div><b>7</b> Now my grandma’s hair is long and white.</div>
              <div><b>8</b> Now my grandma’s very worried all the time.</div>
              <div><b>9</b> Now my grandma’s always nice and generous.</div>
            </div>
            <div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
        `,
        answer: ['F', 'T', 'T', 'T', 'F', 'F', 'F', 'T'],
      },
    ]
  },
}

export default json;