import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: "Unit 8",
    id: "WB5-V2-U8-P58-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page58/E1/Key/answerKey.png",
    inputSize: 200,
    titleQuestion: [{ color: 'black', num: '1', title: 'Circle the words and write.' }],
    // titleImage: "img/FriendsPlus/Page58/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page58/E1/1.jpg" />
          <div style='display: flex; margin-top: 20px;'>
            <div style='line-height: 45px; margin-right: 20px;'>
              <div><b>1</b>&ensp;<u style='color: gray;'>&ensp;pastry&ensp;</u></div>
              <div><b>2</b> #</div>
              <div><b>3</b> #</div>
              <div><b>4</b> #</div>
              <div><b>5</b> #</div>
            </div>
            <div>
              <img src="img/FriendsPlus/Page58/E1/2.jpg" />
            </div>
          </div>
        `,
        answer: ['bean sprouts', 'garlic', 'sauce', 'mushrooms'],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB4-U8-P58-E2',
    audio: 'img/FriendsPlus/Page58/Audio/audio-e2-p58.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page58/E2/Key/answerKey.png",
    titleImage: '',
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page58/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page58/Audio/tieude-e2-p58.mp3' },
        { url: 'img/FriendsPlus/Page58/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page58/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page58/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page58/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page58/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page58/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/10.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page58/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page58/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page58/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/14.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page58/E2/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page58/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page58/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/18.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page58/E2/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page58/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page58/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/22.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page58/E2/23.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page58/E2/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page58/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/26.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page58/E2/27.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page58/E2/28.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page58/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/30.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page58/E2/31.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page58/E2/32.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page58/E2/33.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/34.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page58/E2/35.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page58/E2/36.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page58/E2/37.jpg' },
        { url: 'img/FriendsPlus/Page58/E2/38.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page58/E2/39.jpg' },
      ],
      [
        // Column20
        { url: 'img/FriendsPlus/Page58/E2/40.jpg' },
      ],
    ]
  },
}
export default json;