import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P8-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page8/E1/Key/answerKey.png",
    isAllowSubmit: false,
    maxLength: 1,
    textAlign: 'center',
    padding: 0,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page8/E1/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page8/E1/2.jpg" },
        { url: "img/FriendsPlus/Page8/E1/3.jpg", input: true, answer: "6" },
        { url: "img/FriendsPlus/Page8/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/5.jpg" }],
      [
        // Column4
        { url: "img/FriendsPlus/Page8/E1/6.jpg" },
        { url: "img/FriendsPlus/Page8/E1/7.jpg", input: true, answer: "7" },
        { url: "img/FriendsPlus/Page8/E1/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/9.jpg" }],
      [
        // Column6
        { url: "img/FriendsPlus/Page8/E1/10.jpg" },
        { url: "img/FriendsPlus/Page8/E1/11.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page8/E1/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/13.jpg" }],
      [
        // Column8
        { url: "img/FriendsPlus/Page8/E1/14.jpg" },
        { url: "img/FriendsPlus/Page8/E1/15.jpg", input: true, answer: "5" },
        { url: "img/FriendsPlus/Page8/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/17.jpg" }],
      [
        // Column10
        { url: "img/FriendsPlus/Page8/E1/18.jpg" },
        { url: "img/FriendsPlus/Page8/E1/19.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page8/E1/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/21.jpg" }],
      [
        // Column12
        { url: "img/FriendsPlus/Page8/E1/22.jpg" },
        { url: "img/FriendsPlus/Page8/E1/23.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page8/E1/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page8/E1/25.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "WB5-V2-U1-P8-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "",
    isAllowSubmit: false,
    hideBtnFooter: true,
    questionImage: [
      [{ url: "img/FriendsPlus/Page8/E2/1.jpg" }],
      [
        // Column2
        { url: "img/FriendsPlus/Page8/E2/2.jpg" },
        { url: "img/FriendsPlus/Page8/E2/3.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page8/E2/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page8/E2/5.jpg" },
        { url: "img/FriendsPlus/Page8/E2/6.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page8/E2/7.jpg" },
      ],
    ],
  },
};

export default json;
