import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P93-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page93/E1/Key/answerKey.png",
    inputSize: 500,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page93/E1/1.jpg">
          <div style='margin-left:50px;'>
            <div><b>1</b> have / family. / I / with / breakfast / my <u style='color: gray;'>&ensp;I always have breakfast with my family.&ensp;</u></div>
            <div><b>2</b> homework / in / I / my / do / the morning. #</div>
            <div><b>3</b> at / brush / I / my / teeth / night. #</div>
            <div><b>4</b> play / I / school. / soccer / after #</div>
            <div><b>5</b> to school / walk / I / the morning. / in #</div>
          </div>
        `,
        answer: [
          'I always do my homework in the morning./I sometimes do my homework in the morning./I never do my homework in the morning.',
          'I always brush my teeth at night./I sometimes brush my teeth at night./I never brush my teeth at night.',
          'I always play soccer after school./I sometimes play soccer after school./I never play soccer after school.',
          'I always walk to school in the morning./I sometimes walk to school in the morning./I never walk to school in the morning.',
        ],
      },
    ]
  },
  2: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P93-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page93/E2/Key/answerKey.png",
    inputSize: 200,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page93/E2/1.jpg">
          <div style='display:flex;'>
            <div style='margin-left:50px;'>
              <div><b>1</b> Do you go to school <u style='color: gray;'>&ensp;in&ensp;</u> February?</div>
              <div><b>2</b> Does school start # nine o’clock?</div>
              <div><b>3</b> Do you get up late # Saturdays?</div>
              <div><b>4</b> Is your birthday # November?</div>
              <div><b>5</b> Do you watch TV # the morning?</div>
            </div>
            <div>
              <div><u style='color:gray;'>&ensp;Yes, I do.&ensp;</u></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
        `,
        answer: ['at', 'on', 'in', 'in', '', '', '', ''],
      },
    ]
  },
  3: { // Exercise num
    unit: "Grammar Time",
    id: "WB5-V2-GT-P93-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page93/E3/Key/answerKey.png",
    inputSize: 200,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Complete.' }],
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <img src="img/FriendsPlus/Page93/E3/1.jpg">
          <img src="img/FriendsPlus/Page93/E3/2.jpg">
          <div style='margin-left:50px;'>
            <div><b>1</b> Do you #?</div>
            <div><b>2</b> #</div>
            <div><b>3</b> #</div>
            <div><b>4</b> #</div>
          </div>
        `,
        answer: ['', '', '', ''],
      },
    ]
  },
}
export default json;