import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/UnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB4-U4-P30-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page30/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page30/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page30/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page30/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page30/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page30/E1/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page30/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/11.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page30/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page30/E1/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page30/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/15.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page30/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page30/E1/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page30/E1/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page30/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/21.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page30/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/23.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page30/E1/24.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page30/E1/25.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page30/E1/26.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/27.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page30/E1/28.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/29.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page30/E1/30.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page30/E1/31.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page30/E1/32.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/33.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page30/E1/34.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page30/E1/35.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page30/E1/36.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/37.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page30/E1/38.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page30/E1/39.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page30/E1/40.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/41.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page30/E1/42.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page30/E1/43.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB4-U4-P30-E2',
    audio: 'img/FriendsPlus/Page30/Audio/audio-e2-p30.mp3',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page30/E2/Key/answerKey.png',
    isAllowSubmit: false,
    textAlign: 'center',
    maxLength: 2,
    padding: 0,
    question: [],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/2.jpg', audioUrl: "img/FriendsPlus/Page30/Audio/tieude-e2-p30.mp3", },
        { url: 'img/FriendsPlus/Page30/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page30/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/6.jpg', input: true, answer: "11" },
        { url: 'img/FriendsPlus/Page30/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page30/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page30/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/10.jpg', input: true, answer: "8" },
        { url: 'img/FriendsPlus/Page30/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page30/E2/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page30/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/14.jpg', input: true, answer: "9" },
        { url: 'img/FriendsPlus/Page30/E2/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page30/E2/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page30/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/18.jpg', input: true, answer: "10" },
        { url: 'img/FriendsPlus/Page30/E2/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page30/E2/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page30/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/22.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page30/E2/23.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page30/E2/24.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page30/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/26.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page30/E2/27.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page30/E2/28.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page30/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/30.jpg', input: true, answer: "7" },
        { url: 'img/FriendsPlus/Page30/E2/31.jpg' },
      ],
      [
        // Column16
        { url: 'img/FriendsPlus/Page30/E2/32.jpg' },
      ],
      [
        // Column17
        { url: 'img/FriendsPlus/Page30/E2/33.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/34.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page30/E2/35.jpg' },
      ],
      [
        // Column18
        { url: 'img/FriendsPlus/Page30/E2/36.jpg' },
      ],
      [
        // Column19
        { url: 'img/FriendsPlus/Page30/E2/37.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/38.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page30/E2/39.jpg' },
      ],
      [
        // Column20
        { url: 'img/FriendsPlus/Page30/E2/40.jpg' },
      ],
      [
        // Column21
        { url: 'img/FriendsPlus/Page30/E2/41.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/42.jpg', input: true, answer: "6" },
        { url: 'img/FriendsPlus/Page30/E2/43.jpg' },
      ],
      [
        // Column22
        { url: 'img/FriendsPlus/Page30/E2/44.jpg' },
      ],
      [
        // Column23
        { url: 'img/FriendsPlus/Page30/E2/45.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/46.jpg', input: true, answer: "12" },
        { url: 'img/FriendsPlus/Page30/E2/47.jpg' },
      ],
      [
        // Column24
        { url: 'img/FriendsPlus/Page30/E2/48.jpg' },
      ],
    ],
  },
}

export default json;