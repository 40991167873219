import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P60-E1',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page60/E1/Key/answerKey.png',
    checkUppercase: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page60/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page60/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page60/E1/3.jpg', input: true, answer: "waterfalls" },
        { url: 'img/FriendsPlus/Page60/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page60/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page60/E1/6.jpg', input: true, answer: "oceans" },
        { url: 'img/FriendsPlus/Page60/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page60/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page60/E1/9.jpg', input: true, answer: "lakes" },
        { url: 'img/FriendsPlus/Page60/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page60/E1/11.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: "Unit 9",
    id: "WB5-V2-U9-P60-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page60/E2/Key/answerKey.png",
    inputSize: 300,
    // titleQuestion: [{ color: 'black', num: '1', title: 'Circle the words and write.' }],
    titleImage: "img/FriendsPlus/Page60/E2/1.jpg",
    // checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='margin-right: 10px;'>
              <div style='margin-bottom: 10px;'>
                <img src="img/FriendsPlus/Page60/E2/2.jpg" />
                <div>This is a <u style='color: gray;'>&ensp;high waterfall&ensp;</u>.</div>
              </div>
              <div style='margin-top: 10px;'>
                <img src="img/FriendsPlus/Page60/E2/4.jpg" />
                <div>This is a #.</div>
              </div>
            </div>
            <div style='margin-left: 10px;'>
              <div style='margin-bottom: 10px;'>
                <img src="img/FriendsPlus/Page60/E2/3.jpg" />
                <div>This is a #.</div>
              </div>
              <div style='margin-top: 10px;'>
                <img src="img/FriendsPlus/Page60/E2/5.jpg" />
                <div>This is a #.</div>
              </div>
            </div>
          </div>
        `,
        answer: ['high mountain', 'wide lake', 'deep ocean'],
      },
    ]
  },
}
export default json;