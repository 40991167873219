import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    // Exercise num
    unit: "Starter",
    id: "WB5-V2-S-P4-E1",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page4/E1/Key/answerKey.png",
    isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page4/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page4/E1/2.jpg" },
        { url: "img/FriendsPlus/Page4/E1/3.jpg", input: true, answer: "mom/dad/grandma/grandpa/sister/brother/aunt/uncle/cousin" },
        { url: "img/FriendsPlus/Page4/E1/4.jpg" },
        { url: "img/FriendsPlus/Page4/E1/5.jpg", input: true, answer: "one/two/three/four/five/six/seven/eight/nine" },
        { url: "img/FriendsPlus/Page4/E1/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E1/7.jpg" },
        { url: "img/FriendsPlus/Page4/E1/8.jpg", input: true, answer: "mom/dad/grandma/grandpa/sister/brother/aunt/uncle/cousin" },
        { url: "img/FriendsPlus/Page4/E1/9.jpg" },
        { url: "img/FriendsPlus/Page4/E1/10.jpg", input: true, answer: "one/two/three/four/five/six/seven/eight/nine" },
        { url: "img/FriendsPlus/Page4/E1/11.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E1/12.jpg" },
        { url: "img/FriendsPlus/Page4/E1/13.jpg", input: true, answer: "mom/dad/grandma/grandpa/sister/brother/aunt/uncle/cousin" },
        { url: "img/FriendsPlus/Page4/E1/14.jpg" },
        { url: "img/FriendsPlus/Page4/E1/15.jpg", input: true, answer: "one/two/three/four/five/six/seven/eight/nine" },
        { url: "img/FriendsPlus/Page4/E1/16.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E1/17.jpg" },
        { url: "img/FriendsPlus/Page4/E1/18.jpg", input: true, answer: "mom/dad/grandma/grandpa/sister/brother/aunt/uncle/cousin" },
        { url: "img/FriendsPlus/Page4/E1/19.jpg" },
        { url: "img/FriendsPlus/Page4/E1/20.jpg", input: true, answer: "one/two/three/four/five/six/seven/eight/nine" },
        { url: "img/FriendsPlus/Page4/E1/21.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E1/22.jpg" },
        { url: "img/FriendsPlus/Page4/E1/23.jpg", input: true, answer: "mom/dad/grandma/grandpa/sister/brother/aunt/uncle/cousin" },
        { url: "img/FriendsPlus/Page4/E1/24.jpg" },
        { url: "img/FriendsPlus/Page4/E1/25.jpg", input: true, answer: "one/two/three/four/five/six/seven/eight/nine" },
        { url: "img/FriendsPlus/Page4/E1/26.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E1/27.jpg" },
        { url: "img/FriendsPlus/Page4/E1/28.jpg", input: true, answer: "mom/dad/grandma/grandpa/sister/brother/aunt/uncle/cousin" },
        { url: "img/FriendsPlus/Page4/E1/29.jpg" },
        { url: "img/FriendsPlus/Page4/E1/30.jpg", input: true, answer: "one/two/three/four/five/six/seven/eight/nine" },
        { url: "img/FriendsPlus/Page4/E1/31.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E1/32.jpg" },
        { url: "img/FriendsPlus/Page4/E1/33.jpg", input: true, answer: "mom/dad/grandma/grandpa/sister/brother/aunt/uncle/cousin" },
        { url: "img/FriendsPlus/Page4/E1/34.jpg" },
        { url: "img/FriendsPlus/Page4/E1/35.jpg", input: true, answer: "one/two/three/four/five/six/seven/eight/nine" },
        { url: "img/FriendsPlus/Page4/E1/36.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E1/37.jpg" },
        { url: "img/FriendsPlus/Page4/E1/38.jpg", input: true, answer: "mom/dad/grandma/grandpa/sister/brother/aunt/uncle/cousin" },
        { url: "img/FriendsPlus/Page4/E1/39.jpg" },
        { url: "img/FriendsPlus/Page4/E1/40.jpg", input: true, answer: "one/two/three/four/five/six/seven/eight/nine" },
        { url: "img/FriendsPlus/Page4/E1/41.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page4/E1/42.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "WB5-V2-S-P4-E2",
    audio: "",
    video: "",
    component: D1,
    exerciseKey: "img/FriendsPlus/Page4/E2/Key/answerKey.png",
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [{ url: "img/FriendsPlus/Page4/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page4/E2/2.jpg" },
        { url: "img/FriendsPlus/Page4/E2/3.jpg", input: true, answer: "This is my cousin." }, // Câu 6
        { url: "img/FriendsPlus/Page4/E2/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E2/5.jpg" },
        { url: "img/FriendsPlus/Page4/E2/6.jpg", input: true, answer: "This is my cousin." }, // Câu 2
        { url: "img/FriendsPlus/Page4/E2/7.jpg" },
        { url: "img/FriendsPlus/Page4/E2/8.jpg", input: true, answer: "This is my cousin." }, // Câu 7
        { url: "img/FriendsPlus/Page4/E2/9.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E2/10.jpg" },
        { url: "img/FriendsPlus/Page4/E2/11.jpg", input: true, answer: "This is my grandma." }, // Câu 3
        { url: "img/FriendsPlus/Page4/E2/12.jpg" },
        { url: "img/FriendsPlus/Page4/E2/13.jpg", input: true, answer: "This is my uncle." }, // Câu 8
        { url: "img/FriendsPlus/Page4/E2/14.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E2/15.jpg" },
        { url: "img/FriendsPlus/Page4/E2/16.jpg", input: true, answer: "This is my mom." }, // Câu 4
        { url: "img/FriendsPlus/Page4/E2/17.jpg" },
        { url: "img/FriendsPlus/Page4/E2/18.jpg", input: true, answer: "This is my aunt." }, // Câu 9
        { url: "img/FriendsPlus/Page4/E2/19.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page4/E2/20.jpg" },
        { url: "img/FriendsPlus/Page4/E2/21.jpg", input: true, answer: "This is my dad." }, // Câu 5
        { url: "img/FriendsPlus/Page4/E2/22.jpg" },
        { url: "img/FriendsPlus/Page4/E2/23.jpg", input: true, answer: "This is my grandpa." }, // Câu 10
        { url: "img/FriendsPlus/Page4/E2/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page4/E2/25.jpg" }],
    ],
  },
};
export default json;
