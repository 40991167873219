import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: { // Exercise num
    unit: "Unit 3",
    id: "WB5-V2-U3-P21-E1",
    audio: "",
    video: "",
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: "img/FriendsPlus/Page21/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page21/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page21/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page21/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/5.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page21/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page21/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page21/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/9.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page21/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page21/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/13.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page21/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page21/E1/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page21/E1/17.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB4-U3-P21-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page21/E2/Key/answerKey.png',
    isAllowSubmit: false,
    checkUppercase: true,
    question: [],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page21/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page21/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/3.jpg', input: true, answer: "watch TV?" },
        { url: 'img/FriendsPlus/Page21/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page21/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page21/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/7.jpg', input: true, answer: "you can" },
        { url: 'img/FriendsPlus/Page21/E2/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page21/E2/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page21/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/11.jpg', input: true, answer: "Can I listen to music?/Can I listen to the CD player?" },
        { url: 'img/FriendsPlus/Page21/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/13.jpg', input: true, answer: "Can I read your book?" },
        { url: 'img/FriendsPlus/Page21/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page21/E2/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page21/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/17.jpg', input: true, answer: "you can" },
        { url: 'img/FriendsPlus/Page21/E2/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page21/E2/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page21/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/21.jpg', input: true, answer: "you can't" },
        { url: 'img/FriendsPlus/Page21/E2/22.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page21/E2/23.jpg' },
      ],
    ]
  },


}

export default json;