import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
  1: { // Exercise num
    unit: "Unit 10",
    id: "WB5-V2-U10-P73-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page73/E1/Key/answerKey.png",
    inputSize: 70,
    titleQuestion: [{ color: 'black', num: '1', title: 'Write <i>and</i> or <i>or</i>.' }],
    // titleImage: "img/FriendsPlus/Page73/E2/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    maxLength: 3,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='margin-right: 10px;'>
              <div><b>1</b>&ensp;She doesn’t like math <u style='color: gray;'>&ensp;or&ensp;</u> English.</div>
              <div><b>3</b>&ensp;I don’t live in a town # a city.</div>
              <div><b>5</b>&ensp;I like eating noodles # tomatoes.</div>
            </div>
            <div style='margin-left: 10px;'>
              <div><b>2</b>&ensp;He plays soccer # basketball.</div>
              <div><b>4</b>&ensp;We like math # science.</div>
              <div><b>6</b>&ensp;They don’t surf # sail.</div>
            </div>
          </div>
        `,
        answer: ['or', 'and', 'and', 'and', 'or'],
      },
    ]
  },
  2: {
    unit: 'Unit 10',
    id: 'WB4-U10-P73-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page73/E2/Key/answerKey.png',
    // titleQuestion: [{ num: '4', title: 'Check (✓) the two pictures that start with the same sound.<br />Write the letters.', color: "#5B5A5D" }],
    question: {
      Write: {
        checkUppercase: true,
        underlineStyle: { border: 'none' },
        inputStyle: { width: 155, marginLeft: 10, color: 'black' },
        answers: ['food', 'homework', 'MP3 player', 'quiet'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: '0 5px', color: 'white' },
        selectWordStyle: { color: 'black' },
        listWords: [
          '✓', //0
          '✓', //1
          '✓', //2
          '✓', //3
          '✓', //4
        ],
        answers: [],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative;'>
          <div>
            <img src="img/FriendsPlus/Page73/E2/1.jpg" style="width: 100%">
            <img src="img/FriendsPlus/Page73/E2/2.jpg" style="width: 100%">
          </div>
          <div>
            <div style='display:flex;justify-content: space-between; position: absolute; top: 299px; left: 952px;'><input id='0' type='Circle' /></div>
            <div style='display:flex;justify-content: space-between; width: 630px; position: absolute; top: 352px; left: 350px;'><input id='0' /><input id='1' type='Circle' /></div>
            <div style='display:flex;justify-content: space-between; width: 610px; position: absolute; top: 405px; left: 370px;'><input id='1' /><input id='2' type='Circle' /></div>
            <div style='display:flex;justify-content: space-between; width: 513px; position: absolute; top: 457px; left: 467px;'><input id='2' /><input id='3' type='Circle' /></div>
            <div style='display:flex;justify-content: space-between; width: 670px; position: absolute; top: 510px; left: 310px;'><input id='3' /><input id='4' type='Circle' /></div>
          </div>
        </div>
      `,
    },
  },
  3: { // Exercise num
    unit: "Unit 10",
    id: "WB5-V2-U10-P73-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page73/E3/Key/answerKey.png",
    inputSize: 50,
    // titleQuestion: [{ color: 'black', num: '3', title: 'Read and complete with <i>ai</i> or <i>ay</i>.' }],
    // titleImage: "img/FriendsPlus/Page73/E2/1.jpg",
    questionImage: [],
    hideBtnFooter: true,
    textareaStyle: { width: 763 },
    questions: [
      {
        title: `
        <div style='position: relative;'>
          <div>
            <img src="img/FriendsPlus/Page73/E3/1.jpg">
            <img src="img/FriendsPlus/Page73/E3/2.jpg">
          </div>
          <div style=" position: absolute; top: 210px; left: 123px;"> <textarea id="0" rows="5"></textarea> </div>
        </div>
        `,
        answer: [],
      },
    ]
  },
}
export default json;