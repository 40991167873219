import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {
  3: { // Exercise num
    unit: "Unit 9",
    id: "WB5-V2-U9-P67-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page67/E3/Key/answerKey.png",
    inputSize: 200,
    titleQuestion: [{ color: 'black', num: '3', title: 'Complete the word. Then write the answer.' }],
    // titleImage: "img/FriendsPlus/Page67/E2/1.jpg",
    checkUppercase: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div style='margin-right: 10px;'>
              <div><b>1</b>&ensp;Are cars slow<u style='color: gray;'>er&ensp;</u> than trains?</div>
              <div><b>2</b>&ensp;Is Australia big # than the U.S.A.?</div>
              <div><b>3</b>&ensp;Is the cheetah the fast # animal in the world?</div>
              <div><b>4</b>&ensp;Is Fansipan mountain the short # in Viet Nam?</div>
            </div>
            <div style='margin-left: 10px;'>
              <div><u style='color: gray;'>&ensp;Yes, they are.&ensp;</u></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
        `,
        answer: ['ger', 'est', 'est', "No, it isn't.", 'Yes, it is.', "No, they aren't."],
      },
    ]
  },
  4: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P67-E4',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page67/E4/Key/answerKey.png',
    checkUppercase: true,
    padding: 0,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page67/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/3.jpg', input: true, answer: "none" },
        { url: 'img/FriendsPlus/Page67/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page67/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/6.jpg', input: true, answer: "s" },
        { url: 'img/FriendsPlus/Page67/E4/7.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/8.jpg', input: true, answer: "No, thanks." },
        { url: 'img/FriendsPlus/Page67/E4/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page67/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/11.jpg', input: true, answer: "none" },
        { url: 'img/FriendsPlus/Page67/E4/12.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/13.jpg', input: true, answer: "Yes, please." },
        { url: 'img/FriendsPlus/Page67/E4/14.jpg' },
      ],
    ]
  },
  5: { // Exercise num
    unit: "Unit 9",
    id: "WB5-V2-U9-P67-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page67/E5/Key/answerKey.png",
    inputSize: 50,
    titleQuestion: [{ color: 'black', num: '5', title: 'Read and complete with <i>ai</i> or <i>ay</i>.' }],
    // titleImage: "img/FriendsPlus/Page67/E2/1.jpg",
    // checkUppercase: true,
    textAlign: 'center',
    maxLength: 2,
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex;'>
            <div>
              <img src="img/FriendsPlus/Page67/E5/1.jpg" />
              <div><b>1</b>&ensp;They’re pl<u style='color: gray;'>&ensp;ay&ensp;</u>ing.</div>
            </div>
            <div style='margin: 0 10px 0 20px;'>
              <img src="img/FriendsPlus/Page67/E5/2.jpg" />
              <div><b>1</b>&ensp;There is a tr#.</div>
            </div>
            <div style='margin: 0 20px 0 10px;'>
              <img src="img/FriendsPlus/Page67/E5/3.jpg" />
              <div><b>1</b>&ensp;The tr#n is big.</div>
            </div>
            <div>
              <img src="img/FriendsPlus/Page67/E5/4.jpg" />
              <div><b>1</b>&ensp;It’s Mond#.</div>
            </div>
          </div>
        `,
        answer: ['ay', 'ai', 'ay'],
      },
    ]
  },
  6: { // Exercise num
    unit: 'Unit 9',
    id: 'WB4-U9-P67-E6',
    audio: '',
    video: '',
    component: D1,
    isAllowSubmit: false,
    exerciseKey: 'img/FriendsPlus/Page67/E6/Key/answerKey.png',
    hideBtnFooter: true,
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E6/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page67/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page67/E6/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page67/E6/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page67/E6/5.jpg' },
        { url: 'img/FriendsPlus/Page67/E6/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page67/E6/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page67/E6/8.jpg' },
        { url: 'img/FriendsPlus/Page67/E6/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page67/E6/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page67/E6/11.jpg' },
        { url: 'img/FriendsPlus/Page67/E6/12.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page67/E6/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page67/E6/14.jpg' },
      ],
    ]
  },
}
export default json;